import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import OnboardingControlSection from "./OnboardingControlSection";

export default function OnboardingIntroForm(props) {
  if (!props.onNext) {
    throw new Error("OnboardingIntroForm requires onNext prop");
  }
  if (!props.onPrevious) {
    throw new Error("OnboardingIntroForm requires onPrevious prop");
  }

  const profile = props.profile;
  if (!profile) {
    throw new Error("OnboardingIntroForm requires profile prop");
  }
  const [intro, setIntro] = useState(profile.intro_text || "");
  const [instagram, setInstagram] = useState(profile.instagram || "");
  const [referrer, setReferrer] = useState(props.referrer);
  const [referralChoice, setReferralChoice] = useState(
    props.referrer ? "referral" : "",
  );
  const [referralCustom, setReferralCustom] = useState("");
  const referralChoices = getReferralChoices();
  const [loading, setLoading] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    props.onNext({
      intro_text: intro,
      instagram: instagram,
      referral_reason: getReferralReason(referralChoice, referralCustom),
      referrer: referrer,
    });
  };

  return (
    <Container style={{ backgroundColor: "white" }} width="100%">
      <Form noValidate onSubmit={onSubmit}>
        <Row className="pt-4">
          <h3>Almost there!</h3>
        </Row>
        <Row className="mx-1 mt-3">
          <div className="mb-3 text-start">
            <label htmlFor="" className="form-label">
              Introduction
            </label>
            <textarea
              className="form-control"
              id="description-input"
              aria-describedby="descriptionHelp"
              rows="6"
              value={intro}
              onChange={(e) => setIntro(e.target.value)}
              placeholder="Using your own words, write an introduction for yourself and your dogs. A fun and personable introduction will distinguish your profile and attract more attention from potential hosts."
            />
          </div>
          <div className="mb-3 text-start">
            <label htmlFor="" className="form-label">
              How did you hear about us?
            </label>
            <select
              className="form-select"
              aria-label="Floating label select example"
              style={{
                color: referralChoice ? "black" : "gray",
              }}
              value={referralChoice}
              onChange={(e) => {
                setReferralChoice(e.target.value);
              }}
            >
              <option value=""> -- Select an option -- </option>
              {referralChoices.map((choice) => (
                <option key={choice} value={choice.toLowerCase()}>
                  {choice}
                </option>
              ))}
            </select>
            {referralChoice === "referral" && (
              <>
                <input
                  className="form-control mt-2"
                  value={referrer}
                  onChange={(e) => {
                    setReferrer(e.target.value);
                  }}
                  placeholder="Enter your referral code..."
                />
                <div className="form-text">
                  You and your referrer will each receive $20 in credit when you
                  upgrade to Woofy+!
                </div>
              </>
            )}
            {referralChoice === "local business" && (
              <>
                <input
                  className="form-control mt-2"
                  value={referralCustom}
                  onChange={(e) => {
                    setReferralCustom(e.target.value);
                  }}
                  placeholder="Enter business name..."
                />
                <div className="form-text">
                  We might send them a referral gift!
                </div>
              </>
            )}
            {referralChoice === "other" && (
              <input
                className="form-control mt-2"
                value={referralCustom}
                onChange={(e) => {
                  setReferralCustom(e.target.value);
                }}
                placeholder="Please specify..."
              />
            )}
          </div>
          <div className="mb-3 text-start">
            <label htmlFor="" className="form-label">
              Instagram profile
            </label>
            <input
              className="form-control"
              value={instagram}
              onChange={(e) => {
                setInstagram(e.target.value);
              }}
              placeholder="(Optional) @noodlethedoodle"
            />
            <div className="form-text">
              Either your personal or your dog's Instagram profile. We would
              love to collaborate on IG!
            </div>
          </div>
          <div
            className="d-block d-md-none"
            style={{ marginBottom: "200px" }}
          />
        </Row>
        <OnboardingControlSection
          onFinish={onSubmit}
          onPrevious={props.onPrevious}
          loading={loading}
        />
      </Form>
    </Container>
  );
}

function getReferralChoices() {
  let referralChoices = shuffleArray([
    "Facebook",
    "Instagram",
    "Google",
    "Friends/Family",
    "NextDoor",
    "Local business",
    "Tiktok",
  ]);
  referralChoices = ["Referral", ...referralChoices, "Other"];
  return referralChoices;
}

/* Randomize array in-place using Durstenfeld shuffle algorithm */
function shuffleArray(array) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
}

function getReferralReason(choice, custom) {
  if ((choice === "local business" || choice === "other") && custom) {
    return custom;
  } else {
    return choice;
  }
}
