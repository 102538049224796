import React, { useEffect, useState } from "react";
import { ValidateEmail } from "../../libs/email";
import { CurrentUser, SignUpWithEmailPassword } from "../../libs/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { Mixpanel } from "libs/mixpanel";
import * as Sentry from "@sentry/react";
import { Form } from "react-bootstrap";
import { PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL } from "libs/constants";

export default function SignupForm(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [secondPassword, setSecondPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const { search } = useLocation();
  const navigate = useNavigate();

  const onEmailChange = (event) => {
    setEmail(event.target.value);
    setErrorMessage("");
  };
  const onPasswordChange = (event) => {
    setPassword(event.target.value);
    setErrorMessage("");
  };
  const onSecondPasswordChange = (event) => {
    setSecondPassword(event.target.value);
    setErrorMessage("");
  };
  const onSignInClicked = (event) => {
    event.preventDefault();
    props.signInHandler();
  };
  const onFormSubmit = (event) => {
    event.preventDefault();
    setErrorMessage("");

    if (email.length === 0) {
      setErrorMessage("Please fill out your email address.");
      return;
    }
    if (!ValidateEmail(email)) {
      setErrorMessage("Please fill out a valid email address.");
      return;
    }
    if (password.length < 6) {
      setErrorMessage("Password must have at least 6 characters.");
      return;
    }
    if (password !== secondPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    if (!agreedToTerms) {
      setSubmitDisabled(true);
      return;
    }

    setSubmitDisabled(true);
    SignUpWithEmailPassword(email, password)
      .then(
        (userCredential) => {
          // Signed in
          console.log("Register user successfully");
          const user = userCredential.user;
          Mixpanel.track("sign_up", {
            success: true,
          });
          window.gtag_report_conversion && window.gtag_report_conversion();
          console.log(user);
          navigate("/" + search);
        },
        (error) => {
          console.log("Register user unsuccessfully");
          const errorCode = error.code;
          if (errorCode === "auth/email-already-in-use") {
            setErrorMessage("This email address is already being used");
          } else {
            Sentry.captureException(error);
            setErrorMessage("Server error");
            Mixpanel.track("sign_up", {
              success: false,
              error: error.message,
            });
          }
          console.log(error.code + " " + error.message);
        },
      )
      .finally(() => {
        setSubmitDisabled(false);
      });
  };

  useEffect(() => {
    if (CurrentUser()) {
      navigate("/" + search);
      return;
    }
  }, []);

  return (
    <form onSubmit={onFormSubmit}>
      <h3 className="mb-4">Create Your Account</h3>
      <div className="mb-3">
        <input
          type="email"
          value={email}
          onChange={onEmailChange}
          className="form-control"
          placeholder="Email"
          disabled={submitDisabled}
        />
      </div>
      <div className="mb-3">
        <input
          type="password"
          value={password}
          onChange={onPasswordChange}
          className="form-control"
          placeholder="Password"
          disabled={submitDisabled}
        />
      </div>
      <div className="mb-3">
        <input
          type="password"
          value={secondPassword}
          onChange={onSecondPasswordChange}
          className="form-control"
          placeholder="Confirm password"
          disabled={submitDisabled}
        />
      </div>
      <Form.Group className="mb-3" controlId="termsCheckbox">
        <Form.Check
          type="checkbox"
          value={agreedToTerms}
          onChange={(e) => {
            setAgreedToTerms(e?.target?.checked);
          }}
          label={
            <>
              <div
                style={{
                  fontSize: "0.9rem",
                }}
              >
                I agree to the{" "}
                <a
                  className="link-secondary text-decoration-none"
                  target="_blank noopener noreferrer"
                  href={TERMS_OF_SERVICE_URL}
                >
                  Terms of Service
                </a>{" "}
                and{" "}
                <a
                  className="link-secondary text-decoration-none"
                  target="_blank noopener noreferrer"
                  href={PRIVACY_POLICY_URL}
                >
                  Privacy Policy
                </a>
                .
              </div>
            </>
          }
        />
      </Form.Group>
      <div
        style={{
          fontSize: 14,
          color: "red",
          marginBottom: 2,
          marginTop: 0,
          paddingTop: 0,
          paddingBottom: errorMessage ? 0 : 21,
        }}
      >
        {errorMessage}
      </div>
      <div className="d-grid gap-2">
        <button
          type="submit"
          disabled={submitDisabled || !agreedToTerms}
          className="btn btn-primary border-0 red-background"
        >
          Sign up
        </button>
      </div>
      <p
        className="forgot-password text-right"
        style={{
          fontSize: "0.9rem",
        }}
      >
        Already registered{" "}
        <a
          href="/sign-in"
          onClick={onSignInClicked}
          style={{
            fontSize: "0.9rem",
            color: "black",
          }}
        >
          sign in?
        </a>
      </p>
    </form>
  );
}
