import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import DogProfile from "./DogProfile";
import ProfilePic from "../common/ProfilePic";
import ProfileCompletionCard from "../common/ProfileCompletionCard";
import { usePlacesWidget } from "react-google-autocomplete";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { DEFAULT_PROFILE_PIC_URL } from "../../libs/constants";
import { Mixpanel } from "libs/mixpanel";
import MainNavColumn from "../MainNavColumn";
import { CreateDisplayName } from "libs/profile";
import WCButton from "components/atom/WCButton";

export default function ProfilePage(props) {
  const firestore = getFirestore();
  const currentUser = props.user;

  const [profile, setProfile] = useState({});
  const [validated, setValidated] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [tempAddress, setTempAddress] = useState(
    profile.address?.formatted_address,
  );
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    if (!currentUser) return;

    Mixpanel.track("view_edit_profile");
    const userRef = doc(firestore, "users", currentUser.uid);
    getDoc(userRef).then(
      (snap) => {
        if (snap.exists()) {
          const profileData = snap.data();
          console.log(profileData);
          setProfile(profileData);
          setSelectedAddress(profileData?.address);
          setTempAddress(profileData?.address?.formatted_address);
          setDogs(profileData?.dogs);
        }
      },
      (err) => {
        console.log(err);
      },
    );
  }, [currentUser]);

  const onProfileChange = (newInfo) => {
    console.log("onProfileChange");
    setErrorMessage(null);
    setSuccessMessage(null);
    const newProfile = { ...profile, ...newInfo };
    console.log(newProfile);
    setProfile(newProfile);
    setShowDialog(true);
  };

  let initialDogs = [{}];
  if (profile.dogs && profile.dogs.length > 0) {
    initialDogs = profile.dogs;
  }
  const [dogs, setDogs] = useState(initialDogs);
  const onDogProfileChange = (dogProfile, index) => {
    let newDogs = [...dogs];
    newDogs[index] = dogProfile;
    setDogs(newDogs);
    setShowDialog(true);
  };
  const onAddDog = () => {
    setDogs([...dogs, {}]);
    setShowDialog(true);
  };
  const removeDogProfile = (index) => {
    let newDogs = [...dogs];
    newDogs.splice(index, 1);
    console.log(newDogs);
    setDogs(newDogs);
    const userRef = doc(firestore, "users", currentUser.uid);
    setDoc(userRef, { dogs: newDogs }, { merge: true }).then(
      () => console.log("Profile saved successfully"),
      (err) => console.log(err),
    );
  };

  const placeTypes = ["address"];
  const { ref } = usePlacesWidget({
    // apiKey: "AIzaSyBNBHkYQUFyYuZq0f_wzFeXiyV6TBtv_S4",
    apiKey: "AIzaSyCxTq1Gj3uNeEakx_4gFPykgL3VPk06wMo",
    onPlaceSelected: (place) => {
      console.log(place);
      const address = getAddressFromGooglePlace(place);
      console.log(address);
      if (address) {
        setSelectedAddress(address);
        setTempAddress(address.formatted_address);
      }
    },
    options: {
      types: placeTypes,
    },
  });

  const onSubmit = async (e) => {
    const form = e.currentTarget;
    e.preventDefault();

    setSuccessMessage(null);
    setErrorMessage(null);
    setValidated(true);

    if (!selectedAddress || selectedAddress.formatted_address !== tempAddress) {
      setTempAddress(null);
      setErrorMessage(
        "Address must be chosen from the list of suggested addresses",
      );
      return;
    }

    for (let i = 0; i < dogs.length; i++) {
      if (!dogs[i].breed) {
        setErrorMessage("Dog's breed must be filled out");
        return;
      }
      if (dogs[i].name) {
        dogs[i].name = dogs[i].name.trim();
      }
    }

    try {
      const isProfileUsernameValid = await isUsernameValid(
        profile.username,
        currentUser,
      );
      if (!isProfileUsernameValid) {
        setErrorMessage("username is not valid or has been taken");
        return;
      }

      if (form.checkValidity()) {
        const userRef = doc(firestore, "users", currentUser.uid);
        const createdAt =
          profile.created_at != null ? profile.created_at : Date.now();
        const firstName = profile.first_name.trim();
        const lastName = profile.last_name.trim();
        const newProfile = {
          ...profile,
          ...{
            first_name: firstName,
            last_name: lastName,
            name: CreateDisplayName(firstName, lastName),
            dogs: dogs,
            address: selectedAddress,
            created_at: createdAt,
            updated_at: Date.now(),
          },
        };
        console.log(newProfile);
        setProfile(newProfile);
        setSuccessMessage("Saving...");
        await setDoc(userRef, newProfile);
        setSuccessMessage(
          "Profile Saved! Checkout your public link at the top of this page!",
        );
        setTimeout(() => setSuccessMessage(null), 2500);
        Mixpanel.track("profile_saved_successfully");
        setShowDialog(false);
      } else {
        Mixpanel.track("profile_saved_unsuccessfully", {
          error_message: "Some required fields are missing",
        });
        setErrorMessage("Some required fields are missing");
      }
    } catch (err) {
      setSuccessMessage(null);
      setErrorMessage("Server error");
      console.log(err);
      Mixpanel.track("profile_saved_unsuccessfully", {
        error_message: err.message,
      });
    }
  };

  const publicProfileLink = "https://woofyclub.com/profile/" + profile.username;

  if (!currentUser) {
    return null;
  }

  let publicUrlRender;
  if (profile.username) {
    publicUrlRender = (
      <a href={"/profile/" + profile.username} target="_blank" rel="noreferrer">
        {"https://woofyclub.com/profile/" + profile.username}
      </a>
    );
  } else {
    publicUrlRender = (
      <p>
        Your profile is <b>not active</b> yet and other members will not be able
        to find you. Once all the required fields below are filled out, your
        profile will be active and you will receive a public profile link that
        you can share with anyone. Check back here once your profile below has
        been filled out!
      </p>
    );
  }

  return (
    // HTML + JSX goes here
    <Container className="py-3">
      <Form noValidate validated={validated} onSubmit={onSubmit}>
        <Row className="justify-content-md-center">
          <Col md="3" className="order-md-0 order-sm-0">
            <MainNavColumn profile={profile} />
          </Col>
          <Col md="3" className="order-md-2 order-sm-1">
            <div className="sticky-top">
              <div className="card mb-2">
                <div className="card-body">
                  <h3 className="p-2">Profile Link</h3>
                  {publicUrlRender}
                </div>
              </div>
              <div className="card mb-2 pb-2">
                <div className="card-body">
                  <h3 className="p-2">Profile Completion</h3>
                  <ProfileCompletionCard user={profile} />
                </div>
              </div>
            </div>
          </Col>
          <Col md="6" className="order-md-1 order-sm-2">
            <div className="card">
              <div className="card-body">
                <h3 className="p-2">About You</h3>
                <hr />
                <Row className="my-3">
                  <Col md="3">
                    <ProfilePic
                      url={profile.profile_pic_url || DEFAULT_PROFILE_PIC_URL}
                      canChange={true}
                      onChange={(url) =>
                        onProfileChange({ profile_pic_url: url })
                      }
                      size="100"
                    />
                  </Col>
                  <Col md="9">
                    <Row>
                      <Col md="12">
                        <h4 className="p-2">Personal</h4>
                        <div className="mb-3 text-start">
                          <label htmlFor="" className="form-label">
                            Tell us about yourself, your dog and your hosting
                            preferences
                          </label>
                          <textarea
                            className="form-control"
                            id="description-input"
                            aria-describedby="descriptionHelp"
                            rows="10"
                            value={profile.intro_text}
                            onChange={(e) =>
                              onProfileChange({ intro_text: e.target.value })
                            }
                            placeholder="Write something descriptive and genuine as others will see this first about your profile"
                            required
                          />
                          <div className="form-text">
                            Imagine writing a Facebook post to introduce
                            yourself and your dog!
                          </div>
                        </div>
                        <div className="mb-3 text-start">
                          <label htmlFor="" className="form-label">
                            First Name
                          </label>
                          <input
                            type="name"
                            className="form-control"
                            id="first-name-input"
                            aria-describedby="nameHelp"
                            value={profile.first_name}
                            onChange={(e) =>
                              onProfileChange({ first_name: e.target.value })
                            }
                            placeholder="First Name"
                            required
                          />
                        </div>
                        <div className="mb-3 text-start">
                          <label htmlFor="" className="form-label">
                            Last Name
                          </label>
                          <input
                            type="name"
                            className="form-control"
                            id="last-name-input"
                            aria-describedby="nameHelp"
                            value={profile.last_name}
                            onChange={(e) =>
                              onProfileChange({ last_name: e.target.value })
                            }
                            placeholder="Last Name"
                            required
                          />
                        </div>
                        <div className="mb-3 text-start">
                          <label htmlFor="" className="form-label">
                            Email
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="email-input"
                            aria-describedby="emailHelp"
                            placeholder={currentUser.email}
                            disabled
                          />
                        </div>
                        <div className="mb-3 text-start">
                          <label htmlFor="" className="form-label">
                            Address
                          </label>
                          <input
                            ref={ref}
                            type="address"
                            className="form-control"
                            id="address-input"
                            aria-describedby="addressHelp"
                            placeholder="Search for your home address"
                            value={tempAddress}
                            onChange={(e) => setTempAddress(e.target.value)}
                            required
                          />
                          <div className="form-text">
                            Your address is private and will never be shared
                            with anyone without your consent. Only your city is
                            publicly visible.
                          </div>
                        </div>
                        <div className="mb-3 text-start">
                          <label htmlFor="" className="form-label">
                            Username
                          </label>
                          <div className="input-group">
                            <div className="input-group-text">
                              https://woofyclub.com/profile/
                            </div>
                            <input
                              type="username"
                              className="form-control"
                              id="username-input"
                              aria-describedby="usernameHelp"
                              value={profile.username}
                              onChange={(e) =>
                                onProfileChange({
                                  username: e.target.value.toLowerCase(),
                                })
                              }
                              placeholder="username"
                              required
                            />
                            <div className="form-text">
                              You will be given a unique url that you can share
                              with neighbors or on Facebook posts
                            </div>
                          </div>
                        </div>
                        <div className="mb-3 text-start">
                          <label htmlFor="" className="form-label">
                            Phone Number
                          </label>
                          <input
                            type="phone"
                            className="form-control"
                            id="phone-input"
                            aria-describedby="phoneHelp"
                            value={profile.phone_number}
                            onChange={(e) =>
                              onProfileChange({ phone_number: e.target.value })
                            }
                            placeholder="415-612-7835"
                          />
                          <div className="form-text">
                            Your phone number is private and not shared with
                            anyone. As WoofyClub develops, you will have the
                            option to share your contact details with other
                            members privately with your consent.
                          </div>
                        </div>

                        <h4 className="p-2">Your Home</h4>
                        <div className="mb-3 text-start">
                          <label htmlFor="" className="form-label">
                            What type of home do you live in?
                          </label>
                          <br />
                          <ButtonGroup required>
                            <ToggleButton
                              type="radio"
                              variant="outline-secondary"
                              id={"tgb-btn-house-type-1"}
                              value="house"
                              checked={profile.space_type === "house"}
                              onChange={(e) =>
                                onProfileChange({ space_type: e.target.value })
                              }
                            >
                              House
                            </ToggleButton>
                            <ToggleButton
                              type="radio"
                              variant="outline-secondary"
                              id={"tgb-btn-house-type-2"}
                              value="apartment"
                              checked={profile.space_type === "apartment"}
                              onChange={(e) =>
                                onProfileChange({ space_type: e.target.value })
                              }
                            >
                              Apartment
                            </ToggleButton>
                            <ToggleButton
                              type="radio"
                              variant="outline-secondary"
                              id={"tgb-btn-house-type-3"}
                              value="farm"
                              checked={profile.space_type === "farm"}
                              onChange={(e) =>
                                onProfileChange({ space_type: e.target.value })
                              }
                            >
                              Farm
                            </ToggleButton>
                          </ButtonGroup>
                        </div>
                        <div className="mb-3 text-start">
                          <label htmlFor="" className="form-label">
                            What type of yard do you have?
                          </label>
                          <br />
                          <ButtonGroup required>
                            <ToggleButton
                              type="radio"
                              variant="outline-secondary"
                              id={"tgb-btn-yard-type-1"}
                              value="fenced"
                              checked={profile.yard_type === "fenced"}
                              onChange={(e) =>
                                onProfileChange({ yard_type: e.target.value })
                              }
                            >
                              Fenced
                            </ToggleButton>
                            <ToggleButton
                              type="radio"
                              variant="outline-secondary"
                              id={"tgb-btn-yard-type-2"}
                              value="unfenced"
                              checked={profile.yard_type === "unfenced"}
                              onChange={(e) =>
                                onProfileChange({ yard_type: e.target.value })
                              }
                            >
                              Unfenced
                            </ToggleButton>
                            <ToggleButton
                              type="radio"
                              variant="outline-secondary"
                              id={"tgb-btn-yard-type-3"}
                              value="no_yard"
                              checked={profile.yard_type === "no_yard"}
                              onChange={(e) =>
                                onProfileChange({ yard_type: e.target.value })
                              }
                            >
                              No Yard
                            </ToggleButton>
                          </ButtonGroup>
                        </div>
                        <div className="mb-3 text-start">
                          <label htmlFor="" className="form-label">
                            What can dog owners expect when you host at your
                            home?
                          </label>
                          <br />
                          <ButtonGroup required vertical="true">
                            <ToggleButton
                              type="checkbox"
                              variant="outline-secondary"
                              id={"tgb-btn-smoking_inside_home"}
                              value=""
                              checked={profile.smoking_inside_home}
                              onChange={(e) =>
                                onProfileChange({
                                  smoking_inside_home: e.target.checked,
                                })
                              }
                            >
                              Smoking inside home
                            </ToggleButton>
                            <ToggleButton
                              type="checkbox"
                              variant="outline-secondary"
                              id={"tgb-btn-children_0_5"}
                              value=""
                              checked={profile.children_0_5}
                              onChange={(e) =>
                                onProfileChange({
                                  children_0_5: e.target.checked,
                                })
                              }
                            >
                              Children age 0-5
                            </ToggleButton>
                            <ToggleButton
                              type="checkbox"
                              variant="outline-secondary"
                              id={"tgb-btn-children_6_12"}
                              value=""
                              checked={profile.children_6_12}
                              onChange={(e) =>
                                onProfileChange({
                                  children_6_12: e.target.checked,
                                })
                              }
                            >
                              Children age 6-12
                            </ToggleButton>
                            <ToggleButton
                              type="checkbox"
                              variant="outline-secondary"
                              id={"tgb-btn-cats_in_home"}
                              value=""
                              checked={profile.cats_in_home}
                              onChange={(e) =>
                                onProfileChange({
                                  cats_in_home: e.target.checked,
                                })
                              }
                            >
                              Cats in home
                            </ToggleButton>
                            <ToggleButton
                              type="checkbox"
                              variant="outline-secondary"
                              id={"tgb-btn-dogs_allowed_on_bed"}
                              value=""
                              checked={profile.dogs_allowed_on_bed}
                              onChange={(e) =>
                                onProfileChange({
                                  dogs_allowed_on_bed: e.target.checked,
                                })
                              }
                            >
                              Dogs are allowed on bed
                            </ToggleButton>
                            <ToggleButton
                              type="checkbox"
                              variant="outline-secondary"
                              id={"tgb-btn-dogs_allowed_on_furniture"}
                              value=""
                              checked={profile.dogs_allowed_on_furniture}
                              onChange={(e) =>
                                onProfileChange({
                                  dogs_allowed_on_furniture: e.target.checked,
                                })
                              }
                            >
                              Dogs are allowed on furniture
                            </ToggleButton>
                            <ToggleButton
                              type="checkbox"
                              variant="outline-secondary"
                              id={"tgb-btn-dogs_might_visit_dog_park"}
                              value=""
                              checked={profile.dogs_might_visit_dog_park}
                              onChange={(e) =>
                                onProfileChange({
                                  dogs_might_visit_dog_park: e.target.checked,
                                })
                              }
                            >
                              Dogs might visit a dog park close by
                            </ToggleButton>
                            <ToggleButton
                              type="checkbox"
                              variant="outline-secondary"
                              id={
                                "tgb-btn-dogs_might_meet_other_dogs_in_building"
                              }
                              value=""
                              checked={
                                profile.dogs_might_meet_other_dogs_in_building
                              }
                              onChange={(e) =>
                                onProfileChange({
                                  dogs_might_meet_other_dogs_in_building:
                                    e.target.checked,
                                })
                              }
                            >
                              Dogs might meet other dogs in the building
                            </ToggleButton>
                          </ButtonGroup>
                        </div>

                        <h4 className="p-2">Hosting Preferences</h4>
                        <div className="mb-3 text-start">
                          <label htmlFor="" className="form-label">
                            What's the maximum dog size you're comfortable with?
                          </label>
                          <select
                            className="form-select"
                            aria-label="Maximum pet size comfortable hosting"
                            value={profile.pref_pet_size}
                            onChange={(e) =>
                              onProfileChange({ pref_pet_size: e.target.value })
                            }
                          >
                            <option disabled selected value>
                              {" "}
                              -- Select an option --{" "}
                            </option>
                            <option value="15">0-15</option>
                            <option value="40">16-40</option>
                            <option value="100">41-100</option>
                            <option value="110">101+</option>
                          </select>
                        </div>
                        <div className="mb-3 text-start">
                          <label htmlFor="" className="form-label">
                            Shedding type you prefer hosting
                          </label>
                          <br />
                          <ButtonGroup required>
                            <ToggleButton
                              type="radio"
                              variant="outline-secondary"
                              id={"tgb-btn-pref-shed-type-1"}
                              value="none"
                              checked={profile.pref_shedding_type === "none"}
                              onChange={(e) =>
                                onProfileChange({
                                  pref_shedding_type: e.target.value,
                                })
                              }
                            >
                              None
                            </ToggleButton>
                            <ToggleButton
                              type="radio"
                              variant="outline-secondary"
                              id={"tgb-btn-pref-shed-type-2"}
                              value="minimal"
                              checked={profile.pref_shedding_type === "minimal"}
                              onChange={(e) =>
                                onProfileChange({
                                  pref_shedding_type: e.target.value,
                                })
                              }
                            >
                              Minimal
                            </ToggleButton>
                            <ToggleButton
                              type="radio"
                              variant="outline-secondary"
                              id={"tgb-btn-pref-shed-type-3"}
                              value="medium"
                              checked={profile.pref_shedding_type === "medium"}
                              onChange={(e) =>
                                onProfileChange({
                                  pref_shedding_type: e.target.value,
                                })
                              }
                            >
                              Medium
                            </ToggleButton>
                            <ToggleButton
                              type="radio"
                              variant="outline-secondary"
                              id={"tgb-btn-pref-shed-type-3"}
                              value="heavy"
                              checked={profile.pref_shedding_type === "heavy"}
                              onChange={(e) =>
                                onProfileChange({
                                  pref_shedding_type: e.target.value,
                                })
                              }
                            >
                              Heavy
                            </ToggleButton>
                          </ButtonGroup>
                        </div>
                        <div className="mb-3 text-start">
                          <label htmlFor="" className="form-label">
                            Number of dogs you'd be willing to host? Some
                            families have multiple dogs
                          </label>
                          <br />
                          <ButtonGroup required>
                            <ToggleButton
                              type="radio"
                              variant="outline-secondary"
                              id={"tgb-btn-pref-num-dogs-1"}
                              value="1"
                              checked={
                                Number(profile.pref_number_of_dogs) === 1
                              }
                              onChange={(e) =>
                                onProfileChange({
                                  pref_number_of_dogs: e.target.value,
                                })
                              }
                            >
                              1
                            </ToggleButton>
                            <ToggleButton
                              type="radio"
                              variant="outline-secondary"
                              id={"tgb-btn-pref-num-dogs-2"}
                              value="2"
                              checked={
                                Number(profile.pref_number_of_dogs) === 2
                              }
                              onChange={(e) =>
                                onProfileChange({
                                  pref_number_of_dogs: e.target.value,
                                })
                              }
                            >
                              2
                            </ToggleButton>
                            <ToggleButton
                              type="radio"
                              variant="outline-secondary"
                              id={"tgb-btn-pref-num-dogs-3"}
                              value="3"
                              checked={
                                Number(profile.pref_number_of_dogs) === 3
                              }
                              onChange={(e) =>
                                onProfileChange({
                                  pref_number_of_dogs: e.target.value,
                                })
                              }
                            >
                              3
                            </ToggleButton>
                            <ToggleButton
                              type="radio"
                              variant="outline-secondary"
                              id={"tgb-btn-pref-num-dogs-4"}
                              value="4"
                              checked={
                                Number(profile.pref_number_of_dogs) === 4
                              }
                              onChange={(e) =>
                                onProfileChange({
                                  pref_number_of_dogs: e.target.value,
                                })
                              }
                            >
                              4+
                            </ToggleButton>
                          </ButtonGroup>
                          <div className="form-text">
                            It is assumed you are only taking care of one other
                            family's dogs at a time
                          </div>
                        </div>
                        <div className="mb-3 text-start">
                          <label htmlFor="" className="form-label">
                            How far are you willing to travel to drop off your
                            dog?
                          </label>
                          <br />
                          <ButtonGroup required>
                            <ToggleButton
                              type="radio"
                              variant="outline-secondary"
                              id={"tgb-btn-pref-travel-1"}
                              value="2"
                              checked={profile.pref_travel_distance === "2"}
                              onChange={(e) =>
                                onProfileChange({
                                  pref_travel_distance: e.target.value,
                                })
                              }
                            >
                              2 miles
                            </ToggleButton>
                            <ToggleButton
                              type="radio"
                              variant="outline-secondary"
                              id={"tgb-btn-pref-travel-2"}
                              value="5"
                              checked={profile.pref_travel_distance === "5"}
                              onChange={(e) =>
                                onProfileChange({
                                  pref_travel_distance: e.target.value,
                                })
                              }
                            >
                              5 miles
                            </ToggleButton>
                            <ToggleButton
                              type="radio"
                              variant="outline-secondary"
                              id={"tgb-btn-pref-travel-3"}
                              value="10"
                              checked={profile.pref_travel_distance === "10"}
                              onChange={(e) =>
                                onProfileChange({
                                  pref_travel_distance: e.target.value,
                                })
                              }
                            >
                              10 miles
                            </ToggleButton>
                            <ToggleButton
                              type="radio"
                              variant="outline-secondary"
                              id={"tgb-btn-pref-travel-4"}
                              value="30"
                              checked={profile.pref_travel_distance === "30"}
                              onChange={(e) =>
                                onProfileChange({
                                  pref_travel_distance: e.target.value,
                                })
                              }
                            >
                              30 miles
                            </ToggleButton>
                            <ToggleButton
                              type="radio"
                              variant="outline-secondary"
                              id={"tgb-btn-pref-travel-5"}
                              value="50"
                              checked={profile.pref_travel_distance === "50"}
                              onChange={(e) =>
                                onProfileChange({
                                  pref_travel_distance: e.target.value,
                                })
                              }
                            >
                              50+ miles
                            </ToggleButton>
                          </ButtonGroup>
                        </div>
                        <div className="mb-3 text-start">
                          <label htmlFor="" className="form-label">
                            How does dog care fit into your schedule?
                          </label>
                          <textarea
                            className="form-control"
                            id="description-input"
                            aria-describedby="petCareHelp"
                            rows="5"
                            value={profile.pref_pet_schedule}
                            onChange={(e) =>
                              onProfileChange({
                                pref_pet_schedule: e.target.value,
                              })
                            }
                            placeholder="I usually play with my dog for a few minutes in the morning. After work, I will go on a walk or occassionally drive to a dog park. Throughout the day, I'll give my dog a few cuddles :D "
                          />
                        </div>

                        <h4 className="p-2">Additional Information</h4>
                        <div className="mb-3 text-start">
                          <label htmlFor="" className="form-label">
                            Anything else we should know about you and your
                            hosting preferences?
                          </label>
                          <textarea
                            className="form-control"
                            id="comments-input"
                            aria-describedby="commentsHelp"
                            rows="5"
                            placeholder="Comments"
                            value={profile.pref_additional}
                            onChange={(e) =>
                              onProfileChange({
                                pref_additional: e.target.value,
                              })
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
            <br />
            {dogs.map((dog, index) => (
              <DogProfile
                profile={dog}
                index={index}
                onProfileChange={(p) => onDogProfileChange(p, index)}
                canRemoveProfile={dogs.length > 1}
                removeProfile={() => removeDogProfile(index)}
              />
            ))}
            <br />
            <div className="d-grid gap-2" style={{ marginBottom: 40 }}>
              <WCButton variant="outline-dark" onClick={onAddDog}>
                Add a dog
              </WCButton>
            </div>
          </Col>
        </Row>
        <Row
          className="fixed-bottom justify-content-md-center"
          style={{ marginBottom: "52px" }}
        >
          <Col xs="12" md="6">
            <div className="d-grid gap-2">
              <WCButton variant="primary" type="submit" size="lg">
                Submit
              </WCButton>
              {successMessage && (
                <Alert variant="success">{successMessage}</Alert>
              )}
              {errorMessage && <Alert variant={"danger"}>{errorMessage}</Alert>}
            </div>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}

function getAddressFromGooglePlace(place) {
  if (!place || !place.formatted_address) return null;
  let address_components = {};
  place.address_components.forEach((address_component) => {
    const placeType = address_component.types[0];
    const shortName = address_component.short_name;
    const longName = address_component.long_name;
    address_components[placeType] = {
      short_name: shortName,
      long_name: longName,
    };
  });

  return {
    address_components: address_components,
    formatted_address: place.formatted_address,
    latitude: place.geometry.location.lat(),
    longitude: place.geometry.location.lng(),
    place_id: place.place_id,
  };
}

async function isUsernameValid(username, currentUser) {
  if (!username || username.length === 0) {
    return false;
  }

  const firestore = getFirestore();
  const q = query(
    collection(firestore, "users"),
    where("username", "==", username),
  );
  const querySnapshot = await getDocs(q);
  let isUsernameTaken = false;
  querySnapshot.forEach((doc) => {
    if (doc.id !== currentUser.uid) {
      isUsernameTaken = true;
      return;
    }
  });

  return !isUsernameTaken;
}
