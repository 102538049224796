import React, { useState } from "react";
import useStorage from "../../hooks/useStorage";
import { v4 as uuidv4 } from "uuid";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import ProgressBar from "react-bootstrap/ProgressBar";
import { DEFAULT_PROFILE_PIC_URL } from "../../libs/constants";
import WCButton from "components/atom/WCButton";

export default function ProfilePicV2(props) {
  const [file, setFile] = useState(null);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(null);
  const [profilePicUrl, setProfilePicUrl] = useState(props.url || null);
  const {} = useStorage(
    file,
    (image) => {
      if (image?.uri && props.onChange) {
        setProfilePicUrl(image.uri);
        props.onChange(image.uri);
      }
      setShowProgressBar(false);
    },
    (progress) => {
      setUploadProgress(progress);
    },
  );
  const uploadFormId = uuidv4();
  const size = props.size ? props.size : "150";
  const profilePicUrlOrDefault = profilePicUrl || DEFAULT_PROFILE_PIC_URL;
  const imageStyle = {
    backgroundImage: "url(" + profilePicUrlOrDefault + ")",
    width: size + "px",
    height: size + "px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "50%",
    backgroundClip: "padding-box",
    margin: "0 0 0 0",
    float: "left",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    cursor: props.canChange ? "pointer" : "default",
  };

  const onInputChange = (e) => {
    if (e?.target?.files?.length === 0) {
      throw new Error("ProfilePicV2: No file selected");
    }
    const file = e.target.files[0];
    if (file) {
      setFile(file);
      setShowProgressBar(true);
    }
  };

  const removePhoto = (e) => {
    e.preventDefault();
    setProfilePicUrl(null);
    if (props.onChange) {
      props.onChange(null);
    }
  };

  return props.canChange ? (
    <form style={{ flexDirection: "row" }}>
      <input
        id={uploadFormId}
        type="file"
        accept=".HEIC, .jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
        onChange={onInputChange}
        style={{ display: "none" }}
      />
      <label htmlFor={uploadFormId}>
        <div style={imageStyle}></div>
      </label>
      <br />
      <Row>
        <Button variant="outline-link">
          <label
            style={{ textDecoration: "underline", cursor: "pointer" }}
            htmlFor={uploadFormId}
          >
            Upload photo
          </label>
        </Button>
        {profilePicUrl && (
          <WCButton variant="outline-link" onClick={removePhoto}>
            Remove
          </WCButton>
        )}
      </Row>
      {showProgressBar && uploadProgress != null && (
        <div className="mt-2 px-5">
          <ProgressBar now={uploadProgress} variant="dark" />
        </div>
      )}
    </form>
  ) : (
    <div style={imageStyle}></div>
  );
}
