import {
  createUserWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { Mixpanel } from "./mixpanel";

export async function SignUpWithEmailPassword(email, password) {
  const auth = getAuth();
  return createUserWithEmailAndPassword(auth, email, password);
}

export async function LogInWithEmailPassword(email, password) {
  const auth = getAuth();
  return signInWithEmailAndPassword(auth, email, password);
}

export function CurrentUser() {
  const auth = getAuth();
  return auth.currentUser;
}

export function Logout() {
  const auth = getAuth();
  signOut(auth).then(
    () => {
      console.log("Logging out successfully");
      Mixpanel.reset();
    },
    (err) => {
      console.log("Logging out unsuccessfully");
      console.log(err);
    },
  );
}

export async function ResetPasswordWithEmail(email) {
  const auth = getAuth();
  return sendPasswordResetEmail(auth, email);
}
