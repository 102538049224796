import React from "react";
import WCButton from "components/atom/WCButton";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";

export default function OnboardingControlBar(props) {
  return (
    <Container>
      <Row className="my-0 py-0">
        {props.errorMessage && (
          <div
            style={{
              fontSize: 14,
              color: "red",
              marginBottom: 2,
              marginTop: 0,
              paddingTop: 0,
            }}
          >
            {props.errorMessage}
          </div>
        )}
      </Row>
      <Row>
        {props.onNext && (
          <WCButton
            type="submit"
            variant="outline-secondary"
            onClick={props.onNext}
            disabled={props.loading}
          >
            Continue
          </WCButton>
        )}
      </Row>
      <Row>
        {props.onFinish && (
          <WCButton
            type="submit"
            variant="outline-secondary"
            disabled={props.loading}
            onClick={props.onFinish}
          >
            {props.loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Complete"
            )}
          </WCButton>
        )}
      </Row>
      {props.onPrevious && (
        <WCButton
          variant="outline-link"
          disabled={props.loading}
          onClick={props.onPrevious}
        >
          Back
        </WCButton>
      )}
    </Container>
  );
}
