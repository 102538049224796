import { Icon } from "@iconify/react";
import { getProfilePictureUrl } from "libs/images";
import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CurrentUser } from "../libs/auth";
import WCImage from "./atom/WCImage";

const iconStyle = { fontSize: "30px", color: "grey" };

export default function MainNavColumn({ profile, sortBy, setSortBy }) {
  const currentUser = CurrentUser();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const goToAdmin = () => {
    navigate("/admin/");
  };

  const goToInbox = () => {
    navigate("/inbox");
  };

  return (
    <>
      {/*only for desktop*/}
      <div className="sticky-top m-1 d-none d-lg-block">
        {/* Logged in user with a profile */}
        {currentUser != null && Object.keys(profile).length !== 0 && (
          <>
            <div
              className="btn-group-vertical border-0 justify-content-start text-start align-start d-flex"
              role="group"
              aria-label="Vertical button group"
            >
              <Link to={"/profile/".concat(profile.username).concat("/")}>
                <Button className="btn-light text-start border-0 p-2 transparent-background">
                  <WCImage
                    alt="My Profile"
                    src={getProfilePictureUrl(profile.profile_pic_url)}
                    className="rounded"
                    height={80}
                    width={80}
                    style={{ height: 40 }}
                  />
                  &nbsp;{profile.name}
                </Button>
              </Link>
              <Link to={"/newsfeed/"}>
                <Button className="btn-light text-start border-0 p-2 transparent-background">
                  <Icon icon="ri:home-2-line" style={iconStyle} />
                  &nbsp;Home
                </Button>
              </Link>
              <Link to={"/search/hosts/"} target={"_blank"}>
                <Button className="btn-light text-start border-0 p-2 transparent-background">
                  <Icon icon="ic:baseline-search" style={iconStyle} />
                  &nbsp;Map
                </Button>
              </Link>
              <Link to={"/newest-profiles"} target={"_blank"}>
                <Button className="btn-light text-start border-0 p-2 transparent-background">
                  <Icon icon="ph:users" style={iconStyle} />
                  &nbsp;New Users
                </Button>
              </Link>
              <Link to={"/inbox"} target={"_blank"}>
                <Button className="btn-light text-start border-0 p-2 transparent-background">
                  <Icon icon="material-symbols:inbox" style={iconStyle} />
                  &nbsp;Inbox
                </Button>
              </Link>
              <Link to={"/admin"} target={"_blank"}>
                <Button className="btn-light text-start border-0 p-2 transparent-background">
                  <Icon icon="ic:baseline-settings" style={iconStyle} />
                  &nbsp;Admin
                </Button>
              </Link>
            </div>
            {pathname.includes("/newsfeed") && sortBy != null && (
              <div className="mb-4 p-4 text-start justify-content-md-center m-0 py-3 border rounded bg-white">
                <h5>Sort by</h5>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="recent-activity"
                    name="sort_by"
                    onChange={(e) => {
                      setSortBy(e.target.value);
                    }}
                    value="recent_activity"
                    checked={sortBy === "recent_activity"}
                  />
                  <label className="form-check-label" htmlFor="recent-activity">
                    Recent activity
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="sort_by"
                    id="created_at"
                    onChange={(e) => {
                      setSortBy(e.target.value);
                    }}
                    value="created_at"
                    checked={sortBy === "created_at"}
                  />
                  <label className="form-check-label" htmlFor="created_at">
                    Created at
                  </label>
                </div>
              </div>
            )}
          </>
        )}
      </div>

      {/*only for mobile*/}
      <div className="sticky-top m-1 d-xs-block d-lg-none">
        {/* Logged in user with a profile */}
        {currentUser != null && Object.keys(profile).length !== 0 && (
          <div className="fixed-bottom">
            <div className="container bg-none navbar navbar-light border-top border-2 py-0 justify-content-center">
              <ButtonGroup
                size="sm"
                aria-label="Post Actions"
                className="m-0 p-0 w-100"
              >
                <Button
                  onClick={() => navigate("/newsfeed/")}
                  className="btn-light transparent-background p-2"
                >
                  <Icon icon="ri:home-2-line" style={iconStyle} />
                  &nbsp;
                </Button>
                <Button
                  onClick={() => navigate("/search/hosts/")}
                  className="btn-light transparent-background p-2"
                >
                  <Icon icon="ic:baseline-search" style={iconStyle} />
                  &nbsp;
                </Button>
                <Button
                  onClick={goToInbox}
                  className="btn-light transparent-background p-2"
                >
                  <Icon icon="material-symbols:inbox" style={iconStyle} />
                  &nbsp;
                </Button>
                <Button
                  onClick={goToAdmin}
                  className="btn-light transparent-background p-2"
                >
                  <Icon icon="ic:baseline-settings" style={iconStyle} />
                  &nbsp;
                </Button>
                <Button
                  onClick={() =>
                    navigate("/profile/".concat(profile.username).concat("/"))
                  }
                  className="btn-light transparent-background p-2"
                >
                  <img
                    alt="My Profile"
                    src={getProfilePictureUrl(profile.profile_pic_url)}
                    className="rounded"
                    height="30px"
                  />
                </Button>
              </ButtonGroup>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
