import React from "react";
import { ButtonGroup } from "react-bootstrap";
import ToggleButton from "react-bootstrap/ToggleButton";
import { WC_BORDER_COLOR } from "./WCConsts";

export default function WCButtonGroup(props) {
  const options = props.options;
  if (!options) {
    throw new Error("WCButtonGroup requires options prop");
  }
  options.forEach((option) => {
    if (!option.value || !option.label) {
      throw new Error("WCButtonGroup requires options to have value and label");
    }
  });
  if (!props.onValueChange) {
    throw new Error("WCButtonGroup requires onValueChange prop");
  }

  return (
    <ButtonGroup required>
      {options.map((option, index) => (
        <ToggleButton
          type="radio"
          variant="outline-dark"
          style={{
            borderColor: WC_BORDER_COLOR,
          }}
          id={option.value}
          key={option.value}
          value={option.value}
          checked={props.value === option.value}
          onClick={() => {
            props.onValueChange(option.value);
          }}
        >
          {option.label}
        </ToggleButton>
      ))}
    </ButtonGroup>
  );
}
