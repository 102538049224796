import WCButton from "components/atom/WCButton";
import WCModal from "components/atom/WCModal";
import { APP_STORE_URL, GOOGLE_PLAY_URL } from "libs/constants";
import { Mixpanel } from "libs/mixpanel";

const AppUpsellModal = ({ onModalClose, customHeaderText, customBodyText }) => {
  const handleAppStoreDownload = () => {
    Mixpanel.track("download_app_button_clicked");
    window.open(APP_STORE_URL, "_blank", "noopener,noreferrer");
  };

  const handleGooglePlayDownload = () => {
    Mixpanel.track("download_app_button_clicked");
    window.open(GOOGLE_PLAY_URL, "_blank", "noopener,noreferrer");
  };

  return (
    <WCModal
      onHide={onModalClose}
      closable={true}
      headerText={customHeaderText || "Important Notice!"}
      body={
        <>
          <p>
            {customBodyText || (
              <>
                The WoofyClub web app will be discontinued by March 10, 2024.
                Don't worry! WoofyClub is still here and growing stronger than
                ever. We are now focusing more on developing the mobile apps.{" "}
                <br />
                Download the mobile app today!
              </>
            )}
          </p>
          <div className="d-flex justify-content-center align-items-center mobile-store-download-grid">
            <div className="d-flex justify-content-center align-items-center flex-column">
              <img
                src="/ios-download-qr.svg"
                alt="WoofyClub on App Store QR Code"
                className="d-none d-md-block mb-4"
                style={{
                  width: 150,
                  height: 150,
                }}
              />
              <div
                onClick={handleAppStoreDownload}
                role="button"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt="WoofyClub on App Store"
                  src="/app-store-download.svg"
                  style={{ height: 40 }}
                />
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center flex-column">
              <img
                src="/android-download-qr.svg"
                alt="WoofyClub on Google Play QR Code"
                className="d-none d-md-block mb-4"
                style={{
                  width: 150,
                  height: 150,
                }}
              />
              <div
                onClick={handleGooglePlayDownload}
                role="button"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt="WoofyClub on Google Play"
                  src="/google-play-download.svg"
                  style={{ height: 40 }}
                />
              </div>
            </div>
          </div>
        </>
      }
      footer={
        <>
          <WCButton variant="outline-dark" onClick={onModalClose}>
            Close
          </WCButton>
        </>
      }
    />
  );
};

export default AppUpsellModal;
