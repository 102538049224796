import React, { useState } from "react";
import useStorage from "../../hooks/useStorage";
import { v4 as uuidv4 } from "uuid";
import Button from "react-bootstrap/Button";
import WCImage from "../atom/WCImage";

export default function ProfilePic(props) {
  const [file, setFile] = useState(null);
  const {} = useStorage(file, (image) => {
    if (image?.uri && props.onChange) {
      props.onChange(image.uri);
    }
  });
  const uploadFormId = uuidv4();
  const size = props.size ? props.size : 150;
  const imageStyle = {
    width: size,
    height: size,
    borderStyle: "solid",
    borderWidth: "0px",
    borderRadius: "75px",
    margin: "0 0 0 0",
    float: "left",
    objectFit: "cover",
    objectPosition: "center",
  };

  const onInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
    }
  };

  return props.canChange ? (
    <form>
      <input
        id={uploadFormId}
        type="file"
        accept=".HEIC, .jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
        onChange={onInputChange}
        style={{ display: "none" }}
      />
      <label htmlFor={uploadFormId}>
        <WCImage
          src={props.url}
          width={size}
          height={size}
          style={imageStyle}
        />
      </label>
      <br />
      <Button variant="outline-secondary">
        <label htmlFor={uploadFormId}>Change Picture</label>
      </Button>
    </form>
  ) : (
    <div className="d-flex justify-content-center">
      <WCImage src={props.url} width={size} height={size} style={imageStyle} />
    </div>
  );
}
