import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import ProfilePicV2 from "components/common/ProfilePicV2";
import OnboardingControlSection from "./OnboardingControlSection";

export default function OnboardingProfilePicForm(props) {
  const profile = props.profile;
  if (!profile) {
    throw new Error("OnboardingProfilePicForm requires profile prop");
  }
  if (!props.onNext) {
    throw new Error("OnboardingProfilePicForm requires onNext prop");
  }
  if (!props.onPrevious) {
    throw new Error("OnboardingProfilePicForm requires onPrevious prop");
  }

  const [profilePicUrl, setProfilePicUrl] = useState(
    profile.profile_pic_url || null,
  );
  const [errorMessage, setErrorMessage] = useState("");

  const onNext = () => {
    if (!profilePicUrl) {
      setErrorMessage("Please upload a profile picture.");
      return;
    }

    props.onNext({
      profile_pic_url: profilePicUrl,
    });
  };

  return (
    <Container style={{ backgroundColor: "white" }}>
      <Row className="pt-4">
        <h3>Upload your profile picture</h3>
        <div className="form-text">Show us how cute you are. Don't be shy!</div>
      </Row>
      <Row className="mt-5">
        <ProfilePicV2
          url={profilePicUrl}
          canChange={true}
          size="200"
          onChange={(url) => {
            setProfilePicUrl(url);
            setErrorMessage("");
          }}
        />
        <div className="d-block d-md-none" style={{ paddingBottom: "200px" }} />
      </Row>
      <OnboardingControlSection
        onNext={onNext}
        onPrevious={props.onPrevious}
        errorMessage={errorMessage}
      />
    </Container>
  );
}
