import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { ProfileCompletion } from "../../libs/profileCompletion";
import ContactButton from "../common/ContactButton";

export default function ProfileCompletionCard(props) {
  const user = props.user;
  let profileProgress = "";
  let profileNextSteps = "";
  let profileCategories = null;
  let keys = null;

  if (Object.keys(user).length > 0) {
    let profileCompletionDetail = ProfileCompletion(user);
    profileProgress = "" + profileCompletionDetail.progress + "%";
    profileNextSteps = profileCompletionDetail.next_steps;
    profileCategories = profileCompletionDetail.category_breakdown;
    keys = Object.keys(profileCategories);
  } else {
    return null;
  }

  return (
    <div style={{ background: "transparent" }}>
      <div
        className="accordion accordion-flush"
        id="accordionFlushExample"
        style={{ background: "transparent" }}
      >
        <div className="accordion-item" style={{ background: "transparent" }}>
          <h2
            className="accordion-header"
            id="flush-headingOne"
            style={{ background: "transparent" }}
          >
            <button
              className="accordion-button collapsed"
              style={{ background: "transparent", margin: 0, padding: 0 }}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne"
              aria-expanded="false"
              aria-controls="flush-collapseOne"
            >
              <div
                className="progress m-2"
                style={{ height: "35px", width: "100%" }}
              >
                <div
                  className="progress-bar bg-success"
                  role="progressbar"
                  style={{ width: profileProgress }}
                  aria-valuenow="50"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {profileProgress}
                </div>
              </div>
            </button>
            <p className="fw-light" style={{ fontSize: "10px" }}>
              {profileNextSteps}
            </p>
          </h2>
          <div
            id="flush-collapseOne"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingOne"
            data-bs-parent="#accordionFlushExample"
          >
            <div
              className="accordion-body"
              style={{ background: "transparent", margin: 0, padding: 0 }}
            >
              <ul className="list-group">
                {keys.map((key, index) => (
                  <li
                    key={index}
                    className="list-group-item d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">{key}</div>
                    <span className="badge bg-primary rounded-pill">
                      {profileCategories[key]}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
