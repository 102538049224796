import { Icon } from "@iconify/react";
import { Timestamp } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Platform } from "libs/constants";
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { CurrentUser } from "../../libs/auth";
import WCButton from "../atom/WCButton";

export default function ContactButton(props) {
  const currentUser = CurrentUser();
  const currentUserProfile = props.currentProfile;
  const modalMode = props.modalMode;
  const profile = props.profile;
  const profileUid = props.profileUid;
  const buttonType = props.buttonType ?? "default";
  const [isClicked, setIsClicked] = useState(false);
  const profileName = profile.first_name ?? profile.name;
  const [customMessage, setCustomMessage] = useState(
    "Hi ".concat(profileName, ", \n")
  );
  const [showMessageModal, setShowMessageModal] = useState(
    props.messageModal ? props.messageModal : false
  );
  const functions = getFunctions();
  // connectFunctionsEmulator(functions, "localhost", 5001);
  const sendMessage = httpsCallable(functions, "sendChatMessage");
  const senderMembershipTier = currentUserProfile.membership_tier || 0;
  const recipientMembershipTier = profile.membership_tier || 0; // 0 is free, 1 is standard, 2 is premium

  const showActionModal = () => {
    setShowMessageModal(true);
  };

  const onClick = async () => {
    if (!currentUser || !currentUser.uid || !customMessage || !profileUid) {
      throw new Error("Invalid arguments");
    }

    setIsClicked(true);

    const createdAt = new Date();
    const message = {
      created_at: Timestamp.fromDate(createdAt),
      sender_id: currentUser.uid,
      sender_name: currentUserProfile.first_name ?? currentUserProfile.name,
      sender_profile_pic_url: currentUserProfile.profile_pic_url,
      text: customMessage,
    };

    if (!currentUser.uid || !profileUid) {
      throw new Error("Current User ID and Profile ID must be non nil");
    }

    // Send message
    await sendMessage({
      sender_id: currentUser.uid,
      recipient_id: profileUid,
      message: message,
      created_at_ms: createdAt.getTime(),
      platform: Platform.WEB,
    });

    setCustomMessage("");
    setIsClicked(false);
  };

  const contactBody = (
    <>
      <h6>Message</h6>
      <Form noValidate validated={false} onSubmit={onClick}>
        <div className="text-start">
          <textarea
            className="form-control"
            id="description-input"
            aria-describedby="sendMessageModal"
            rows="8"
            disabled={isClicked}
            value={customMessage}
            onChange={(e) => {
              setCustomMessage(e.target.value);
            }}
          />
        </div>
      </Form>
      <p className="mt-2 mb-2" style={{ fontSize: "11px" }}>
        Your email will be shared with {profile.name} to contact you directly
      </p>
    </>
  );

  const contactFooter = (
    <div className="text-end">
      {modalMode ? (
        <WCButton
          variant="outline-primary"
          onClick={() => setShowMessageModal(false)}
          className="mx-2"
        >
          Close
        </WCButton>
      ) : (
        <></>
      )}
      <WCButton
        variant={!isClicked ? "primary" : "secondary"}
        onClick={onClick}
        disabled={isClicked}
      >
        {!isClicked ? "Message" : "Message Sent!"}
      </WCButton>
    </div>
  );

  if (
    !profile ||
    !currentUser ||
    !currentUserProfile ||
    profile.username === currentUserProfile.username
  ) {
    return null;
  }

  return (
    <>
      {modalMode ? (
        buttonType === "postAction" ? (
          <>
            <WCButton
              className="action-button"
              variant={!isClicked ? "outline-light" : "secondary"}
              onClick={showActionModal}
            >
              <Icon icon="tabler:message-circle" style={{ fontSize: "24px" }} />
              &nbsp;{!isClicked ? "Message" : "Message Sent!"}
            </WCButton>
          </>
        ) : (
          <>
            <WCButton
              variant={!isClicked ? "primary" : "secondary"}
              onClick={showActionModal}
            >
              {!isClicked ? "Message" : "Message Sent!"}
            </WCButton>
          </>
        )
      ) : (
        <div className="text-start">
          <h4 className="mb-4">Contact {profile.name}</h4>
          {contactBody}
          <div className="text-end">{contactFooter}</div>
        </div>
      )}

      <Modal
        show={showMessageModal}
        fullscreen="sm-down"
        onHide={() => setShowMessageModal(false)}
        style={{ zIndex: "11000" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Contact {profile.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-start">{contactBody}</div>
        </Modal.Body>
        <Modal.Footer>{contactFooter}</Modal.Footer>
      </Modal>
    </>
  );
}
