import React, { useEffect } from "react";
import { CurrentUser } from "../libs/auth";
import { useNavigate } from "react-router-dom";

export default function LoginWall(props) {
  const currentUser = CurrentUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) {
      navigate(
        "/login?redirect_url=" +
          window.location.pathname +
          window.location.search,
      );
      return;
    }
  }, []);

  return currentUser ? props.children : null;
}
