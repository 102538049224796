import { collection, getDocs, limit, orderBy, query } from "firebase/firestore";
import { firestore } from "libs/firebase";
import { Mixpanel } from "libs/mixpanel";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import InfiniteScroll from "react-infinite-scroll-component";
import MainNavColumn from "../MainNavColumn";
import ProfileCard from "../common/ProfileCard";

export const NewestProfilePage = (props) => {
  const [users, setUsers] = useState([]);
  const [hasMoreDataToScroll, setHasMoreDataToScroll] = useState(true);
  const [usersInfiniteScroll, setUsersInfiniteScroll] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    Mixpanel.track("visit_search_page");
  }, []);

  useEffect(() => {
    Mixpanel.track("visit_search_page");

    const q = query(
      collection(firestore, "users"),
      orderBy("created_at", "desc"),
      limit(100)
    );
    getDocs(q).then((querySnapshot) => {
      const profiles = [];
      querySnapshot.forEach((doc) => {
        let userData = doc.data();
        userData.uid = doc.id;
        if (userData) {
          profiles.push(userData);
        }
      });
      setUsers(profiles);
      setUsersInfiniteScroll(profiles.slice(0, 20));
      setIsLoading(false);
      return profiles;
    });
  }, []);

  function fetchData() {
    const currentDataLength = usersInfiniteScroll.length;
    const totalDataLength = users.length;
    if (currentDataLength < totalDataLength) {
      const newLength = Math.min(currentDataLength + 20, totalDataLength);
      const newProfiles = users.slice(0, newLength);
      setUsersInfiniteScroll(newProfiles);
      if (newLength === totalDataLength) {
        setHasMoreDataToScroll(false);
      }
    } else {
      setHasMoreDataToScroll(false);
    }
  }

  const groupProfilesByDate = (profiles) => {
    const grouped = {};
    profiles.forEach((profile) => {
      const date = moment(profile.created_at)
        .tz("America/Los_Angeles")
        .format("MMMM D, YYYY");
      if (!grouped[date]) {
        grouped[date] = { profiles: [], count: 0 };
      }
      grouped[date].profiles.push(profile);
      grouped[date].count++;
    });
    return grouped;
  };

  const groupedProfiles = groupProfilesByDate(usersInfiniteScroll);

  return (
    // HTML + JSX goes here
    <div>
      {!isLoading ? (
        <Container className="d-md-block my-2">
          <Row className="justify-content-md-center">
            <Col md="3" className="order-md-0 order-lg-0">
              <MainNavColumn profile={props.currentProfile} />
            </Col>
            <Col md="3" className="order-md-1 order-lg-2">
              <div className="sticky-top my-1"></div>
            </Col>
            <Col md="6" className="order-md-2 order-lg-1">
              <InfiniteScroll
                dataLength={usersInfiniteScroll.length} //This is important field to render the next data
                next={fetchData}
                hasMore={hasMoreDataToScroll}
                loader={<h4>Loading...</h4>}
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                }
              >
                {Object.entries(groupedProfiles).map(
                  ([date, { profiles, count }]) => (
                    <React.Fragment key={date}>
                      <h3 className="mt-4 mb-3">
                        {date} ({count} profile{count !== 1 ? "s" : ""})
                      </h3>
                      {profiles.map((userData) => (
                        <React.Fragment key={userData.uid}>
                          <div className="mb-3" />
                          <ProfileCard
                            user={userData}
                            profileUid={userData.uid}
                          />
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  )
                )}
              </InfiniteScroll>
            </Col>
          </Row>
        </Container>
      ) : (
        <Spinner animation="border" role="status" size="lg">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
    </div>
  );
};

export default NewestProfilePage;
