import React, { useCallback, useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

export default function TextExpand(props) {
  const [introText, setIntroText] = useState(props.text);
  const [introTextShort, setIntroTextShort] = useState(props.text);
  const [readMoreButton, setReadMoreButton] = useState(true);

  useEffect(() => {
    setIntroTextShort(
      props.text.length >= 300
        ? props.text.substring(0, 300).concat("...")
        : props.text.substring(0, 300),
    );
    setReadMoreButton(props.text.length >= 300 ? true : false);
  }, []);

  function expandText() {
    setIntroTextShort(introText);
    setReadMoreButton(false);
  }

  return (
    <div style={{ whiteSpace: "pre-wrap" }}>
      {/*<p>{introTextShort}{readMoreButton && <a href="javascript:void(0)" onClick={expandText}>Read more</a>}</p>*/}
      {props.text}
    </div>
  );
}
