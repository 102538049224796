import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getDogBreedLabelFromValue } from "../../libs/dogBreeds";
import { Icon } from "@iconify/react";
import { Helmet } from "react-helmet";

export default function DogGuestProfile({ dog }) {
  const [shownAllDetails, setShownAllDetails] = useState(false);

  if (!dog) {
    return null;
  }
  const iconSize = { fontSize: "24px" };

  let breedDesc;
  if (dog.breed) {
    if (dog.breed === "other" && dog.breed_custom) {
      breedDesc = dog.breed_custom;
    } else {
      breedDesc = getDogBreedLabelFromValue(dog.breed);
    }
  }

  let sheddingDesc;
  if (dog.shedding_type === "none") {
    sheddingDesc = "None";
  } else if (dog.shedding_type === "minimal") {
    sheddingDesc = "Minimal";
  } else if (dog.shedding_type === "medium") {
    sheddingDesc = "Medium";
  } else if (dog.shedding_type === "heavy") {
    sheddingDesc = "Heavy";
  }

  let genderDesc;
  let genderIcon;
  if (dog.gender === "male") {
    genderDesc = "Male";
    genderIcon = <Icon icon="bi:gender-male" style={iconSize} />;
  } else if (dog.gender === "female") {
    genderDesc = "Female";
    genderIcon = <Icon icon="bi:gender-male" style={iconSize} />;
  }

  let ageDesc;
  if (dog.birthdate) {
    const birthdate = new Date(dog.birthdate);
    const now = new Date();
    const age = Math.floor(
      (now.getTime() - birthdate.getTime()) / (1000 * 60 * 60 * 24 * 365),
    );
    if (age < 1) {
      ageDesc = "Puppy (less than 1 year old)";
    } else if (age === 1) {
      ageDesc = "1 year old";
    } else if (age > 1) {
      ageDesc = age + " years old";
    }
  }

  let neuteredDesc;
  if (dog.neutered === "yes") {
    neuteredDesc = "Neutered";
  } else if (dog.neutered === "no") {
    neuteredDesc = "Not neutered";
  }

  let vaxDesc;
  if (dog.vaxed === "yes") {
    vaxDesc = "Up-to-date";
  } else if (dog.vaxed === "no") {
    vaxDesc = "Not up-to-date";
  }

  let microchippedDesc;
  if (dog.microchipped === "yes") {
    microchippedDesc = "Yes";
  } else if (dog.microchipped === "no") {
    microchippedDesc = "No";
  }

  let houseTrainedDesc;
  if (dog.house_trained === "yes") {
    houseTrainedDesc = "Yes";
  } else if (dog.house_trained === "no") {
    houseTrainedDesc = "No";
  }

  let crateTrainedDesc;
  if (dog.crate_trained === "yes") {
    crateTrainedDesc = "Yes";
  } else if (dog.crate_trained === "no") {
    crateTrainedDesc = "No";
  }

  let catFriendlyDesc;
  if (dog.cat_friendly === "yes") {
    catFriendlyDesc = "Friendly with cats";
  } else if (dog.cat_friendly === "no") {
    catFriendlyDesc = "Not friendly with cats";
  }

  let childrenFriendlyDesc;
  if (dog.children_friendly === "yes") {
    childrenFriendlyDesc = "Yes";
  } else if (dog.children_friendly === "no") {
    childrenFriendlyDesc = "No";
  } else if (dog.children_friendly === "unsure") {
    childrenFriendlyDesc = "Not sure";
  }

  let energyLevelDesc;
  if (dog.energy_level === "low") {
    energyLevelDesc = "Chill and relaxed";
  } else if (dog.energy_level === "medium") {
    energyLevelDesc = "Medium";
  } else if (dog.energy_level === "high") {
    energyLevelDesc = "High";
  }

  let pottyScheduleDesc;
  if (dog.potty_schedule === "every_1_hour") {
    pottyScheduleDesc = "taken out every hour";
  } else if (dog.potty_schedule === "every_2_hour") {
    pottyScheduleDesc = "taken out every 2 hours";
  } else if (dog.potty_schedule === "every_4_hour") {
    pottyScheduleDesc = "taken out every 4 hours";
  } else if (dog.potty_schedule === "every_8_hour") {
    pottyScheduleDesc = "taken out every 8 hours";
  }

  let feedingScheduleDesc;
  if (
    dog.feeding_schedule_morning |
    dog.feeding_schedule_afternoon |
    dog.feeding_schedule_evening
  ) {
    feedingScheduleDesc = "";
    if (dog.feeding_schedule_morning) {
      feedingScheduleDesc += "morning,";
    }
    if (dog.feeding_schedule_afternoon) {
      feedingScheduleDesc += " afternoon,";
    }
    if (dog.feeding_schedule_evening) {
      feedingScheduleDesc += " evening,";
    }
    feedingScheduleDesc = feedingScheduleDesc.slice(0, -1);
  }

  let leftAloneDesc;
  if (dog.left_alone === "1_hour") {
    leftAloneDesc = "Can be left alone for less than 1 hour";
  } else if (dog.left_alone === "1_4_hour") {
    leftAloneDesc = "Can be left alone from 1-4 hours";
  } else if (dog.left_alone === "4_8_hour") {
    leftAloneDesc = "Can be left alone from 4-8 hours";
  } else if (dog.left_alone === "8+_hour") {
    leftAloneDesc = "Can be left alone for more than 8 hours";
  }

  const dogName = dog.name ?? "Unname";
  const metaTitle = dogName.concat("'s WoofyClub Profile");

  const showAllDetailsButton = (
    <button
      className="btn btn-outline-dark"
      type="button"
      onClick={() => setShownAllDetails(!shownAllDetails)}
    >
      {!shownAllDetails ? <b>Show all details</b> : <b>Show less details</b>}
    </button>
  );

  return (
    <>
      <Helmet>
        <title>{dogName}&apos;s WoofyClub Profile</title>
        <meta name="og:title" content={metaTitle} />
      </Helmet>
      <Row className="p-0">
        <Col md="12">
          <Row>
            <Col>
              <h4 className="mb-3">About {dogName}</h4>
              <div className="mb-3 text-start">
                {breedDesc && (
                  <p>
                    <b>
                      <Icon icon="ph-dog" style={iconSize} />
                      &nbsp; Breed:{" "}
                    </b>
                    {breedDesc}
                  </p>
                )}
                {ageDesc && (
                  <p>
                    <b>
                      <Icon icon="bi:clock" style={iconSize} />
                      &nbsp; Age:{" "}
                    </b>
                    {ageDesc}
                  </p>
                )}
                {genderDesc && (
                  <p>
                    <b>{genderIcon}&nbsp; Gender: </b>
                    {genderDesc}
                  </p>
                )}
                {dog.weight && (
                  <p>
                    <b>
                      <Icon icon="ph:scales-light" style={iconSize} />
                      &nbsp; Weight:{" "}
                    </b>
                    {dog.weight} lbs
                  </p>
                )}
                {dog.favorite_thing && (
                  <p>
                    <b>
                      <Icon icon="carbon:favorite" style={iconSize} />
                      &nbsp; Favorite thing:{" "}
                    </b>
                    {dog.favorite_thing}
                  </p>
                )}
                {sheddingDesc && (
                  <p>
                    <b>
                      <Icon icon="icon-park:hair-brush" style={iconSize} />
                      &nbsp; Shedding:{" "}
                    </b>
                    {sheddingDesc}
                  </p>
                )}
                {houseTrainedDesc && (
                  <p>
                    <b>
                      <Icon icon="bx:home-alt-2" style={iconSize} />
                      &nbsp; House trained:{" "}
                    </b>
                    {houseTrainedDesc}
                  </p>
                )}
                {crateTrainedDesc && (
                  <p>
                    <b>
                      <Icon icon="bx:bed" style={iconSize} />
                      &nbsp; Crate trained:{" "}
                    </b>
                    {crateTrainedDesc}
                  </p>
                )}
                {shownAllDetails && (
                  <div>
                    {neuteredDesc && (
                      <p>
                        <b>
                          <Icon
                            icon="ph:gender-neuter-light"
                            style={iconSize}
                          />
                          &nbsp; Neutered:{" "}
                        </b>
                        {neuteredDesc}
                      </p>
                    )}
                    {vaxDesc && (
                      <p>
                        <b>
                          <Icon
                            icon="icon-park-outline:needle"
                            style={iconSize}
                          />
                          &nbsp; Vaccination:{" "}
                        </b>
                        {vaxDesc}
                      </p>
                    )}
                    {microchippedDesc && (
                      <p>
                        <b>
                          <Icon
                            icon="icon-park-outline:chip"
                            style={iconSize}
                          />
                          &nbsp; Microchipped:{" "}
                        </b>
                        {microchippedDesc}
                      </p>
                    )}
                    {catFriendlyDesc && (
                      <p>
                        <b>
                          <Icon
                            icon="fluent:animal-cat-16-regular"
                            style={iconSize}
                          />
                          &nbsp; Cat-friendly:{" "}
                        </b>
                        {catFriendlyDesc}
                      </p>
                    )}
                    {childrenFriendlyDesc && (
                      <p>
                        <b>
                          <Icon icon="cil:baby" style={iconSize} />
                          &nbsp; Kid-friendly:{" "}
                        </b>
                        {childrenFriendlyDesc}
                      </p>
                    )}
                    {energyLevelDesc && (
                      <p>
                        <b>
                          <Icon
                            icon="simple-line-icons:energy"
                            style={iconSize}
                          />
                          &nbsp; Energy level:{" "}
                        </b>
                        {energyLevelDesc}
                      </p>
                    )}
                    {pottyScheduleDesc && (
                      <p>
                        <b>
                          <Icon icon="la:poop" style={iconSize} />
                          &nbsp; Potty schedule:{" "}
                        </b>
                        {pottyScheduleDesc}
                      </p>
                    )}
                    {feedingScheduleDesc && (
                      <p>
                        <b>
                          <Icon
                            icon="fluent:food-16-regular"
                            style={iconSize}
                          />
                          &nbsp; Food schedule:{" "}
                        </b>
                        {feedingScheduleDesc}
                      </p>
                    )}
                    {dog.feeding_schedule_custom && (
                      <p>
                        <b>
                          <Icon
                            icon="fluent:food-16-regular"
                            style={iconSize}
                          />
                          &nbsp; Additional food details:{" "}
                        </b>
                        {dog.feeding_schedule_custom}
                      </p>
                    )}
                    {leftAloneDesc && (
                      <p>
                        <b>
                          <Icon
                            icon="material-symbols:location-away"
                            style={iconSize}
                          />
                          &nbsp; Time apart:{" "}
                        </b>
                        {leftAloneDesc}
                      </p>
                    )}
                    {dog.travel_ok_with_car_rides && (
                      <p>
                        <b>
                          <Icon icon="clarity:car-line" style={iconSize} />
                          &nbsp; Travel:{" "}
                        </b>{" "}
                        Dog can travel in car
                      </p>
                    )}
                    {dog.travel_ok_in_small_group && (
                      <p>
                        <b>
                          <Icon icon="carbon:group" style={iconSize} />
                          &nbsp; Travel:{" "}
                        </b>{" "}
                        Dog can travel with small groups (max 4 people)
                      </p>
                    )}
                    {dog.travel_ok_in_large_group && (
                      <p>
                        <b>
                          <Icon icon="carbon:group" style={iconSize} />
                          &nbsp; Travel:{" "}
                        </b>{" "}
                        Dog can travel with large groups (4+ people)
                      </p>
                    )}
                  </div>
                )}
                <div className="d-grid d-lg-none">{showAllDetailsButton}</div>
                <div className="d-none d-lg-block">{showAllDetailsButton}</div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
