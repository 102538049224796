import { ModalProvider } from "contexts/ModalContext";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import { isEmailInAdminList } from "libs/admin";
import { firestore } from "libs/firebase";
import LogRocket from "logrocket";
import { React, useEffect, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import AdminPage from "./components/AdminPage";
import ModalContainer from "./components/common/modals/ModalContainer";
import ScrollToTop from "./components/common/ScrollToTop";
import Footer from "./components/Footer";
import LoginPage from "./components/login_page/LoginPage";
import LoginWall from "./components/LoginWall";
import Navbar from "./components/Navbar";
import OnboardingPage from "./components/onboarding_page/OnboardingPage";
import GuestProfilePage from "./components/profile_page/GuestProfilePage";
import ProfilePage from "./components/profile_page/ProfilePage";
import ListProfilePage from "./components/search_host_page/ListProfilePage";
import NewsFeed from "./components/search_host_page/NewsFeed";
import { Mixpanel } from "./libs/mixpanel";

import Inbox from "components/inbox_page";
import { NewestProfilePage } from "components/search_host_page/NewestProfilePage";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [isOnboarding, setIsOnboarding] = useState(false);
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState({});
  const navigate = useNavigate();
  const auth = getAuth();
  const updateProfile = (profileData) => {
    Mixpanel.people.set({
      first_name: profileData.first_name,
      last_name: profileData.last_name,
      username: profileData.username,
      $name: profileData.name,
    });
    setProfile(profileData);
  };

  const authStateDidChanged = (currentUser) => {
    console.log("In authStateDidChanged, currentUser = ");
    if (currentUser && !isEmailInAdminList(currentUser.email)) {
      signOut(auth).then(() => {
        navigate("/login/");
      });
      return;
    }

    setUser(currentUser);
    setProfile({});

    if (currentUser) {
      trackLoggedInUser(currentUser);

      onSnapshot(
        doc(firestore, "users", currentUser.uid),
        (snap) => {
          if (snap.exists()) {
            updateProfile(snap.data());
            setIsOnboarding(false);
          } else {
            setIsOnboarding(true);
          }
          setIsLoading(false);
        },
        (err) => {
          console.log(err);
          setIsLoading(false);
        }
      );
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    LogRocket.init("zemvce/woofyclub");
    onAuthStateChanged(getAuth(), authStateDidChanged);
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <ModalProvider>
      <div className="App">
        <Navbar profile={profile} />
        <Routes>
          <Route
            exact
            path="/"
            element={
              !user ? (
                <LoginPage signup={false} />
              ) : Object.keys(profile).length != 0 ? (
                <Navigate to="newsfeed/" />
              ) : (
                <OnboardingPage user={user} />
              )
            }
          />
          <Route path="login/" element={<LoginPage signup={false} />} />
          <Route
            path="newsfeed/"
            element={
              <LoginWall>
                <NewsFeed user={user} currentProfile={profile} />
              </LoginWall>
            }
          />
          <Route
            path="inbox/"
            element={
              <LoginWall>
                <Inbox user={user} currentProfile={profile} />
              </LoginWall>
            }
          />
          <Route
            path="newest-profiles"
            element={
              <LoginWall>
                <NewestProfilePage user={user} currentProfile={profile} />
              </LoginWall>
            }
          />
          <Route
            path="search/hosts/"
            element={
              <LoginWall>
                <ListProfilePage user={user} currentProfile={profile} />
              </LoginWall>
            }
          />
          <Route
            path="profile/:username"
            element={
              <LoginWall>
                <GuestProfilePage currentUserProfile={profile} />
              </LoginWall>
            }
          />
          <Route
            path="profile/:username/message/*"
            element={
              <LoginWall>
                <GuestProfilePage currentUserProfile={profile} />
              </LoginWall>
            }
          />
          <Route path="profile/" element={<ProfilePage user={user} />} />
          <Route path="admin/" element={<AdminPage user={user} />} />
        </Routes>
        <Footer />
        <ModalContainer />
        <ScrollToTop />
      </div>
    </ModalProvider>
  );
}

function trackLoggedInUser(currentUser) {
  if (!currentUser) {
    return;
  }

  LogRocket.identify(currentUser.uid, {
    email: currentUser.email,
  });

  Mixpanel.track("user_logged_in");
  Mixpanel.identify(currentUser.uid);
  Mixpanel.people.set({ $email: currentUser.email });
}

export default App;
