import React, { useState } from "react";
import { MuiFbPhotoGrid } from "mui-fb-photo-grid";
import { getCdnUrl } from "libs/images";

export default function PhotoBrowser(props) {
  var IMAGES = [];
  for (var i = 0; i < props.image_urls.length; i++) {
    IMAGES.push({
      title: "",
      img: getCdnUrl(props.image_urls[i], 800, 800), // require
      imgThumbnail: getCdnUrl(props.image_urls[i], 500, 500), // require
    });
  }

  const imagesToShow = useState(IMAGES);

  return (
    <>
      <MuiFbPhotoGrid
        images={IMAGES} // require
        reactModalStyle={{ overlay: { zIndex: 2000 } }} // optional (https://github.com/reactjs/react-modal#styles)
      />
    </>
  );
}
