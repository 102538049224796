// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyBOzWIymz490-VLvf0GAQrMnKnfynRgSoM",
  authDomain: "vacation-dog-swap.firebaseapp.com",
  projectId: "vacation-dog-swap",
  storageBucket: "vacation-dog-swap.appspot.com",
  messagingSenderId: "845801415612",
  appId: "1:845801415612:web:474d4457e1aba6d37881b9",
  measurementId: "G-M2WKDBYGSD",
};

// Initialize Firebase

const fbApp = initializeApp(firebaseConfig);
const fbAnalytics = getAnalytics(fbApp);
const fbStorage = getStorage(fbApp);
const firestore = getFirestore(fbApp);
// To run locally: connectFirestoreEmulator(firestore, "127.0.0.1", 8080);
const fbAuth = getAuth(fbApp);

export { fbApp, fbAnalytics, fbStorage, fbAuth, firestore };
