// Input: 2 lat, lng objects
// Output: Floating point distance in miles between userA and userB (straight line)

export function DistanceBetween(latitude1, longitude1, latitude2, longitude2) {
  var R = 6371; // km
  var dLat = toRad(latitude2 - latitude1);
  var dLon = toRad(longitude2 - longitude1);
  var lat1 = toRad(latitude1);
  var lat2 = toRad(latitude2);

  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  var m = d / 1.60934; //convert km to miles
  return Math.round(m);
}

// Converts numeric degrees to radians
function toRad(Value) {
  return (Value * Math.PI) / 180;
}
