import { ModalKey, useModal } from "contexts/ModalContext";
import AppUpsellModal from "./AppUpsellModal";

const ModalMap = {
  [ModalKey.APP_UPSELL]: AppUpsellModal,
};

export default function ModalContainer() {
  const { currentModal, showModal } = useModal();

  const handleModalClose = () => {
    showModal(null);
  };

  if (!currentModal) return null;
  const Renderer = ModalMap[currentModal?.modalKey];
  const props = currentModal?.props || {};
  if (!Renderer) return null;

  return (
    <>
      <Renderer
        {...props}
        onModalClose={(data) => {
          handleModalClose();
          if (props?.onModalClose) {
            props?.onModalClose(data);
          }
        }}
      />
    </>
  );
}
