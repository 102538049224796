import { useModal } from "contexts/ModalContext";
import { Link, useLocation } from "react-router-dom";
import { CurrentUser, Logout } from "../libs/auth";

function CurrentRoute() {
  const location = useLocation();
  return location.pathname;
}

export default function Navbar(props) {
  const { showModal } = useModal();
  const currentUser = CurrentUser();
  const profile = props.profile;

  const onLogout = (event) => {
    event.preventDefault();
    Logout();
  };

  return (
    // Logged in user with a profile
    currentUser != null && Object.keys(profile).length != 0 ? (
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container">
          <CompanyLogo />
        </div>
      </nav>
    ) : (
      <nav className="navbar navbar-expand-lg navbar-light ">
        <div className="container">
          <CompanyLogo />
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* Anonymous user on homepage */}
          {currentUser == null && CurrentRoute() == "/" && (
            <div
              className="collapse navbar-collapse d-md-flex justify-content-md-end"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <Link
                    type="button"
                    className="btn btn-light mx-3 my-3"
                    to={"/blog/"}
                  >
                    <b>Blog</b>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    type="button"
                    className="btn btn-outline-dark mx-3 my-3"
                    to={"/login/"}
                  >
                    <b>Login</b>
                  </Link>
                </li>
              </ul>
            </div>
          )}

          {/* Anonymous user not on homepage */}
          {currentUser == null && CurrentRoute() != "/" && (
            <div
              className="collapse navbar-collapse d-md-flex justify-content-md-end"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <Link
                    type="button"
                    className="btn btn-outline-dark mx-3 my-3"
                    to={"/login/"}
                  >
                    <b>Login</b>
                  </Link>
                </li>
              </ul>
            </div>
          )}

          {/* Logged in user without a profile */}
          {currentUser != null && Object.keys(profile).length == 0 && (
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <Link className="nav-link" to={"/"} onClick={onLogout}>
                    Sign out
                  </Link>
                </li>
              </ul>
            </div>
          )}
        </div>
      </nav>
    )
  );
}

function CompanyLogo() {
  const currentUser = CurrentUser();
  return currentUser ? (
    <Link className="navbar-brand" to={"/"}>
      <img src={require("../photos/logo.png")} height="30" />
      &nbsp;WoofyClub
    </Link>
  ) : (
    <a className="navbar-brand" href="https://www.woofyclub.com">
      <img src={require("../photos/logo.png")} height="30" />
      &nbsp;WoofyClub
    </a>
  );
}
