import { getCdnUrl } from "libs/images";

const WCImage = ({ src, alt, width, height, quality, className, style }) => {
  return (
    <img
      className={className}
      style={style}
      src={getCdnUrl(src, width, height, quality)}
      alt={alt}
      loading="lazy"
    />
  );
};
export default WCImage;
