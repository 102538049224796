import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import AddressPickerModal from "components/common/AddressPickerModal";
import OnboardingControlSection from "./OnboardingControlSection";

export default function OnboardingAboutYouForm(props) {
  if (!props.onNext) {
    throw new Error("OnboardingAboutYouForm requires onNext prop");
  }

  const profile = props.profile;
  if (!profile) {
    throw new Error("OnboardingAboutYouForm requires profile prop");
  }

  const [firstName, setFirstName] = useState(profile.first_name || "");
  const [lastName, setLastName] = useState(profile.last_name || "");
  const [address, setAddress] = useState(profile.address || null);
  const [formattedAddress, setFormattedAddress] = useState(
    profile.address?.formatted_address || "",
  );
  const [showAddressPickerModal, setShowAddressPickerModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    setErrorMessage("");
    if (!firstName || !lastName || !address) {
      setErrorMessage("Please fill out all fields");
      return;
    }

    props.onNext({
      first_name: firstName.trim(),
      last_name: lastName.trim(),
      address: address,
    });
  };

  return (
    <Container>
      <Form noValidate onSubmit={onSubmit}>
        <Row className="pt-4">
          <h3>Tell us a bit about you</h3>
        </Row>
        <Row className="mx-1 mt-4 text-start">
          <label htmlFor="" className="form-label">
            Your name
          </label>
          <Col xs={6}>
            <input
              type="name"
              className="form-control"
              id="first-name-input"
              aria-describedby="nameHelp"
              value={firstName}
              placeholder="First name"
              onChange={(e) => {
                setErrorMessage("");
                setFirstName(e.target.value);
              }}
              required
            />
          </Col>
          <Col xs={6}>
            <input
              type="name"
              className="form-control"
              id="last-name-input"
              aria-describedby="nameHelp"
              value={lastName}
              placeholder="Last name"
              onChange={(e) => {
                setErrorMessage("");
                setLastName(e.target.value);
              }}
              required
            />
          </Col>
        </Row>
        <Row className="mx-1 mt-4 text-start">
          <Col>
            <label htmlFor="" className="form-label">
              Home address
            </label>
            <input
              className="form-control"
              id="address-input"
              autoComplete="one-time-code"
              placeholder="Search for your home address..."
              style={{ cursor: "pointer", textOverflow: "ellipsis" }}
              value={formattedAddress}
              onChange={(e) => {
                setErrorMessage("");
                setShowAddressPickerModal(true);
              }}
              onClick={() => {
                setErrorMessage("");
                setShowAddressPickerModal(true);
              }}
              required
            />
          </Col>
        </Row>
        <Row>
          <div
            className="d-block d-md-none"
            style={{ paddingBottom: "200px" }}
          />
        </Row>
        <OnboardingControlSection
          onNext={onSubmit}
          errorMessage={errorMessage}
        />
      </Form>
      <AddressPickerModal
        show={showAddressPickerModal}
        onHide={() => setShowAddressPickerModal(false)}
        onSuccess={(address) => {
          console.log("Address chosen: ", address);
          if (address) {
            setFormattedAddress(address.formatted_address);
            setAddress(address);
          } else {
            setFormattedAddress("");
            setAddress(null);
          }
          setShowAddressPickerModal(false);
        }}
      />
    </Container>
  );
}
