import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";

function getIconSizeFromSize(size) {
  if (size === "lg") return 16;
  if (size === "md") return 12;
  if (size === "sm") return 8;
  return 16;
}

export default function MembershipBadge({ size = "sm" }) {
  return (
    <Link to="/premium">
      <div
        style={{
          borderRadius: "50%",
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 5,
          backgroundColor: "#de6449",
          color: "#ffffff",
        }}
      >
        <Icon
          icon="fa-solid:check"
          style={{ fontSize: getIconSizeFromSize(size) }}
        />
      </div>
    </Link>
  );
}
