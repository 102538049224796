import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import OnboardingControlSection from "./OnboardingControlSection";
import { getDogBreeds, getDogBreedTypeFromValue } from "../../libs/dogBreeds";
import WCButtonGroup from "../atom/WCButtonGroup";

export default function OnboardingAboutYourDogForm(props) {
  const [dogBreeds, setDogBreeds] = useState([]);
  useEffect(() => {
    async function fetchDogBreeds() {
      const data = await getDogBreeds();
      setDogBreeds(data);
    }
    fetchDogBreeds();
  }, []);

  if (!props.onNext) {
    throw new Error("OnboardingAboutYourDogForm requires onNext prop");
  }
  if (!props.onPrevious) {
    throw new Error("OnboardingAboutYourDogForm requires onPrevious prop");
  }
  const profile = props.profile;
  if (!profile) {
    throw new Error("OnboardingAboutYourDogForm requires profile prop");
  }
  const dogProfile = profile.dogs?.length > 0 ? profile.dogs[0] : {};

  const [errorMessage, setErrorMessage] = useState("");
  const [name, setName] = useState(dogProfile?.name || "");
  const [breedType, setBreedType] = useState(
    getDogBreedTypeFromValue(dogProfile?.breed),
  );
  const [breedCustom, setBreedCustom] = useState(
    dogProfile?.breed_custom || "",
  );
  const [sheddingType, setSheddingType] = useState(
    dogProfile?.shedding_type || null,
  );
  const [gender, setGender] = useState(dogProfile?.gender || null);
  const [birthdate, setBirthdate] = useState(dogProfile?.birthdate || "");
  const [neutered, setNeutered] = useState(dogProfile?.neutered || null);
  const [weight, setWeight] = useState(
    dogProfile?.weight ? dogProfile.weight.toString() : "",
  );

  const onNext = (e) => {
    e.preventDefault();
    setErrorMessage("");

    if (!name || name.length === 0) {
      setErrorMessage("Please enter your pup's name");
      return;
    }
    if (!breedType || (breedType.value === "other" && !breedCustom)) {
      setErrorMessage("Please enter the breed");
      return;
    }
    if (!birthdate || birthdate.length === 0) {
      setErrorMessage("Please enter your pup's birthday. Approximate is fine.");
      return;
    }
    if (!weight) {
      setErrorMessage("Please enter your pup's weight");
      return;
    }
    if (!sheddingType) {
      setErrorMessage("Please select how much your pup sheds");
      return;
    }
    if (!gender) {
      setErrorMessage("Please enter your pup's gender");
      return;
    }
    if (!neutered) {
      setErrorMessage("Please specify if your pup is neutered or spayed");
      return;
    }

    let newDogProfile = {
      ...dogProfile,
      name: name.trim(),
      breed: breedType.value,
      breed_custom: breedCustom,
      shedding_type: sheddingType,
      gender: gender,
      birthdate: birthdate,
      neutered: neutered,
      weight: Number(weight),
    };
    props.onNext({
      dogs: [newDogProfile],
    });
  };

  return (
    <Container style={{ backgroundColor: "white" }}>
      <Form noValidate onSubmit={onNext}>
        <Row className="pt-4 px-4">
          <h3>Tell us about your pup</h3>
          <div className="form-text">
            If you have more than one dog, you can add them later in Edit
            Profile.
          </div>
        </Row>
        <Row className="mx-1">
          <div className="mt-1 text-start">
            <label htmlFor="" className="form-label">
              Dog's name
            </label>
            <input
              className="form-control"
              id="name-input"
              autoComplete="one-time-code"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setErrorMessage("");
              }}
              placeholder=""
              required
            />
          </div>
          <div className="mt-2 text-start">
            <label htmlFor="" className="form-label">
              Breed
            </label>
            <Select
              value={breedType}
              options={dogBreeds}
              onChange={(selectedBreed) => {
                setBreedType(selectedBreed);
                setBreedCustom(null);
                setErrorMessage("");
              }}
            />
            {breedType?.value === "other" ? (
              <input
                type="custom-breed"
                className="mt-2 form-control"
                id="custom-breed"
                aria-describedby="breedHelp"
                value={breedCustom}
                onChange={(e) => {
                  setBreedCustom(e.target.value);
                  setErrorMessage("");
                }}
                placeholder="What is the breed?"
                required
              />
            ) : (
              <div className="form-text">
                Select "Other" if your dog's breed is not listed.
              </div>
            )}
          </div>
          <div className="mt-2 text-start">
            <label htmlFor="" className="form-label">
              Birthdate
            </label>
            <input
              type="date"
              className="form-control"
              style={{
                color: birthdate ? "black" : "gray",
              }}
              id="birthdate-input"
              aria-describedby="birthdateHelp"
              value={birthdate}
              onChange={(e) => {
                setBirthdate(e.target.value);
                setErrorMessage("");
              }}
              required
            />
            <div className="form-text">
              If unsure of the exact date, input an approximate date.
            </div>
          </div>
          <div className="mt-2 text-start">
            <WeightSelector
              weight={weight}
              onWeightChange={(weight) => {
                setWeight(weight);
                setErrorMessage("");
              }}
            />
          </div>
          <div className="mt-2 text-start">
            <label htmlFor="" className="form-label">
              How much does your dog shed?
            </label>
            <br />
            <WCButtonGroup
              options={[
                { value: "none", label: "None" },
                { value: "minimal", label: "Minimal" },
                { value: "medium", label: "Medium" },
                { value: "heavy", label: "Heavy" },
              ]}
              value={sheddingType}
              onValueChange={(value) => {
                setSheddingType(value);
                setErrorMessage("");
              }}
            />
          </div>
          <div className="mt-2 text-start">
            <label htmlFor="" className="form-label">
              Sex
            </label>
            <br />
            <WCButtonGroup
              options={[
                { value: "male", label: "Male" },
                { value: "female", label: "Female" },
              ]}
              value={gender}
              onValueChange={(value) => {
                setGender(value);
                setErrorMessage("");
              }}
            />
          </div>
          <div className="mt-2 text-start">
            <label htmlFor="" className="form-label">
              Spayed or neutered?
            </label>
            <br />
            <WCButtonGroup
              options={[
                { value: "yes", label: "Yes" },
                { value: "no", label: "No" },
                { value: "unsure", label: "Unsure" },
              ]}
              value={neutered}
              onValueChange={(value) => {
                setNeutered(value);
                setErrorMessage("");
              }}
            />
          </div>
          <div
            className="d-block d-md-none"
            style={{ paddingBottom: "200px" }}
          />
        </Row>
        <OnboardingControlSection
          onNext={onNext}
          onPrevious={props.onPrevious}
          errorMessage={errorMessage}
        />
      </Form>
    </Container>
  );
}

function WeightSelector(props) {
  return (
    <>
      <label htmlFor="" className="form-label">
        Weight
      </label>
      <select
        className="form-select"
        aria-label="Floating label select example"
        style={{
          color: props.weight ? "black" : "gray",
        }}
        value={props.weight}
        onChange={(e) => {
          props.onWeightChange && props.onWeightChange(e.target.value);
        }}
        required
      >
        <option value=""> -- Select an option -- </option>
        <option value="5">5 lbs</option>
        <option value="10">10 lbs</option>
        <option value="15">15 lbs</option>
        <option value="20">20 lbs</option>
        <option value="25">25 lbs</option>
        <option value="30">30 lbs</option>
        <option value="35">35 lbs</option>
        <option value="40">40 lbs</option>
        <option value="45">45 lbs</option>
        <option value="50">50 lbs</option>
        <option value="55">55 lbs</option>
        <option value="60">60 lbs</option>
        <option value="70">70 lbs</option>
        <option value="80">80 lbs</option>
        <option value="90">90 lbs</option>
        <option value="100">100 lbs</option>
        <option value="110">110 lbs</option>
        <option value="120">120 lbs</option>
        <option value="130">130 lbs</option>
        <option value="140">140 lbs</option>
        <option value="150">150 lbs</option>
        <option value="160">160 lbs</option>
        <option value="170">170 lbs</option>
        <option value="180">180 lbs</option>
        <option value="190">190 lbs</option>
        <option value="200">200 lbs</option>
        <option value="210">210 lbs</option>
        <option value="220">220 lbs</option>
        <option value="230">230 lbs</option>
        <option value="240">240 lbs</option>
        <option value="250">250 lbs</option>
      </select>
    </>
  );
}
