import React from "react";
import Button from "react-bootstrap/Button";
import { WC_PRIMARY_COLOR } from "./WCConsts";

export default function WCButton(props) {
  let style;
  let variant = props.variant || "primary";
  if (variant === "primary") {
    style = {
      backgroundColor: WC_PRIMARY_COLOR,
      borderColor: WC_PRIMARY_COLOR,
      borderRadius: "4px",
    };
  } else if (variant === "outline-secondary") {
    variant = "outline-dark";
    style = {
      borderRadius: "4px",
    };
    if (props.disabled) {
      style["borderColor"] = "#ccc";
    }
  } else if (variant === "outline-link") {
    style = {
      textDecoration: "underline",
      borderWidth: "0px",
    };
  }

  return (
    <Button
      type={props.type}
      style={{ ...style, ...props.style }}
      variant={variant}
      size={props.size}
      className={props.className}
      onClick={props.onClick}
      disabled={props.disabled}
      href={props.href}
      target={props.target}
    >
      {props.children}
    </Button>
  );
}
