import React from "react";
import Col from "react-bootstrap/Col";
import { Icon } from "@iconify/react";

export default function PostCardDogDetail({ dog }) {
  if (!dog) {
    return null;
  }
  const iconSize = { fontSize: "24px" };

  let sheddingDesc;
  if (dog.shedding_type === "none") {
    sheddingDesc = "None";
  } else if (dog.shedding_type === "minimal") {
    sheddingDesc = "Minimal";
  } else if (dog.shedding_type === "medium") {
    sheddingDesc = "Medium";
  } else if (dog.shedding_type === "heavy") {
    sheddingDesc = "Heavy";
  }

  let genderIcon;
  if (dog.gender === "Male") {
    genderIcon = <Icon icon="bi:gender-male" style={iconSize} />;
  } else if (dog.gender === "Female") {
    genderIcon = <Icon icon="bi:gender-female" style={iconSize} />;
  }

  const dogName = dog.name ?? "Unname";

  return (
    <>
      <h5 className="mb-3 mx-0 px-0">About {dogName}</h5>
      <Col xs="6">
        <div className="mb-3 text-start">
          {dog.breed_desc && (
            <p>
              <b>
                <Icon icon="ph-dog" style={iconSize} />
                &nbsp; {dog.breed_desc}
              </b>
            </p>
          )}
          {dog.age && (
            <p>
              <b>
                <Icon icon="bi:clock" style={iconSize} />
                &nbsp; Age:{" "}
              </b>
              {dog.age}
            </p>
          )}
        </div>
      </Col>
      <Col xs="6">
        <div className="mb-3 text-start">
          {dog.gender && (
            <p>
              <b>{genderIcon}&nbsp; Gender: </b>
              {dog.gender}
            </p>
          )}
          {dog.weight && (
            <p>
              <b>
                <Icon icon="ph:scales-light" style={iconSize} />
                &nbsp; Weight:{" "}
              </b>
              {dog.weight} lbs
            </p>
          )}
        </div>
      </Col>
    </>
  );
}
