import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import axios from "axios";
import { CurrentUser, GetUserEmailFromUID } from "../../libs/auth";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import CloseButton from "react-bootstrap/CloseButton";
import ImageUploadForm from "../ImageUploadForm";
import { Plus } from "react-bootstrap-icons";
import { Icon } from "@iconify/react";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import {
  EmailShareButton,
  FacebookMessengerShareButton,
  FacebookShareButton,
  LineShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

const iconSize = { fontSize: "24px" };

export default function ShareButton(props) {
  const currentUser = CurrentUser();
  const firestore = getFirestore();
  const [show, setShow] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [copyLinkText, setCopyLinkText] = useState("Copy Link");
  const [successMessage, setSuccessMessage] = useState(null);
  const [shownAllDetails, setShownAllDetails] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const urlToShare = props.profileToShare
    ? "https://www.woofyclub.com/profile/".concat(props.profileToShare.username)
    : "https://www.woofyclub.com/request/".concat(props.listingToShare);
  const titleToShare = props.profileToShare
    ? "Checkout "
        .concat(props.profileToShare.name)
        .concat("'s WoofyClub Profile")
    : "Checkout this WoofyClub Request";

  const copyOnClick = () => {
    navigator.clipboard.writeText(urlToShare);
    setCopyLinkText("Copied!");
    setTimeout(() => {
      setCopyLinkText("Copy Link");
    }, 5000);
  };

  return (
    <>
      <Button
        variant="link-secondary"
        onClick={handleShow}
        disabled={isClicked}
        className="mx-0 px-0"
      >
        <Icon icon="icon-park-outline:share-two" style={iconSize} />
        &nbsp;
        {props.showText ? "Share" : ""}
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        style={{ zIndex: 10001 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Share with your friends and groups</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <div className="d-grid gap-3">
                <Button
                  variant="outline-secondary"
                  size="lg"
                  onClick={copyOnClick}
                >
                  <Icon icon="akar-icons:copy" style={iconSize} />
                  &nbsp;{copyLinkText}
                </Button>
                <EmailShareButton
                  url={urlToShare}
                  subject={titleToShare}
                  body={"Hi, Checkout my WoofyClub Profile."}
                  resetButtonStyle={false}
                  className="btn btn-lg btn-outline-secondary"
                >
                  <Icon icon="carbon:email" style={iconSize} />
                  &nbsp;Email
                </EmailShareButton>
                <FacebookShareButton
                  url={urlToShare}
                  quote={"Vacation Dog Swap"}
                  hashtag="#vacationdogswap"
                  resetButtonStyle={false}
                  className="btn btn-lg btn-outline-secondary"
                >
                  <Icon icon="akar-icons:facebook-fill" style={iconSize} />
                  &nbsp;Facebook
                </FacebookShareButton>
                <FacebookMessengerShareButton
                  appId={504189324368396}
                  url={urlToShare}
                  resetButtonStyle={false}
                  className="btn btn-lg btn-outline-secondary"
                >
                  <Icon icon="bi:messenger" style={iconSize} />
                  &nbsp;Messenger
                </FacebookMessengerShareButton>
                {shownAllDetails && (
                  <>
                    <TwitterShareButton
                      url={urlToShare}
                      title={titleToShare}
                      hashtag="#vacationdogswap"
                      resetButtonStyle={false}
                      className="btn btn-lg btn-outline-secondary"
                    >
                      <Icon icon="akar-icons:twitter-fill" style={iconSize} />
                      &nbsp;Twitter
                    </TwitterShareButton>
                    <WhatsappShareButton
                      url={urlToShare}
                      title={titleToShare}
                      resetButtonStyle={false}
                      className="btn btn-lg btn-outline-secondary"
                    >
                      <Icon icon="akar-icons:whatsapp-fill" style={iconSize} />
                      &nbsp;Whatsapp
                    </WhatsappShareButton>
                    <RedditShareButton
                      url={urlToShare}
                      title={titleToShare}
                      resetButtonStyle={false}
                      className="btn btn-lg btn-outline-secondary"
                    >
                      <Icon
                        icon="ant-design:reddit-outlined"
                        style={iconSize}
                      />
                      &nbsp;Reddit
                    </RedditShareButton>
                    <LineShareButton
                      url={urlToShare}
                      title={titleToShare}
                      resetButtonStyle={false}
                      className="btn btn-lg btn-outline-secondary"
                    >
                      <Icon icon="bi:line" style={iconSize} />
                      &nbsp;Line
                    </LineShareButton>
                    <TelegramShareButton
                      url={urlToShare}
                      title={titleToShare}
                      resetButtonStyle={false}
                      className="btn btn-lg btn-outline-secondary"
                    >
                      <Icon icon="bi:telegram" style={iconSize} />
                      &nbsp;Telegram
                    </TelegramShareButton>
                  </>
                )}
                <button
                  className="btn btn-link-dark"
                  type="button"
                  onClick={() => setShownAllDetails(!shownAllDetails)}
                >
                  {!shownAllDetails ? (
                    <b>Show more options</b>
                  ) : (
                    <b>Show less options</b>
                  )}
                </button>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
