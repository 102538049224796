import React from "react";
import { Link } from "react-router-dom";
import WCImage from "../atom/WCImage";
import { getProfilePictureUrl } from "libs/images";
import moment from "moment/moment";

export default function CommentDetail(props) {
  const data = props.commentData;
  const profile = data.author;
  if (!profile || !profile.username) {
    throw new Error("CommentDetail: profile cannot be null");
  }
  const profileUrl = "/profile/".concat(profile.username).concat("/");
  const createdAt = new Date(data.created_at);

  return (
    <>
      <div style={{ maxWidth: "100% !important" }}>
        <Link to={profileUrl}>
          <WCImage
            src={getProfilePictureUrl(profile.profile_pic_url)}
            width={80}
            height={80}
            style={{
              width: "30px",
              height: "30px",
              borderStyle: "solid",
              borderWidth: "0px",
              borderRadius: "75px",
              margin: "0 0 0 0",
              float: "left",
              display: "inline",
              objectFit: "cover",
            }}
          />
        </Link>
        <div
          className="my-1 px-2"
          style={{
            borderRadius: "10px",
            backgroundColor: "#e9ecef",
            display: "inline-block",
            width: "calc(100% - 40px)",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Link
              className="p-0 m-0"
              style={{
                fontSize: "12px",
                fontWeight: "700",
                color: "black",
                textDecoration: "none",
              }}
              to={profileUrl}
            >
              {profile.name}
            </Link>
            <div
              className="text-muted"
              style={{
                fontSize: "12px",
                marginLeft: "5px",
              }}
            >
              {moment(createdAt).fromNow()}
            </div>
          </div>
          <p
            className="p-0 m-0"
            style={{
              fontSize: "14px",
              fontWeight: "100 !important",
              wordBreak: "break-word",
            }}
          >
            {data.text}
          </p>
        </div>
      </div>
    </>
  );
}
