import React, { useState } from "react";
import { ResetPasswordWithEmail } from "../libs/auth";
import { ValidateEmail } from "../libs/email";
import WCButton from "./atom/WCButton";

export default function PasswordResetForm(props) {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const onEmailChange = (event) => {
    setEmail(event.target.value);
    setErrorMessage("");
    setSuccessMessage("");
  };
  const onFormSubmit = (event) => {
    event.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");

    if (email.length === 0) {
      setErrorMessage("Email cannot be empty");
      return;
    }
    if (!ValidateEmail(email)) {
      setErrorMessage("Invalid email address");
      return;
    }

    setSubmitDisabled(true);
    ResetPasswordWithEmail(email)
      .then(() => {
        // Reset email sent
        console.log("Reset email sent successfully");
        setSuccessMessage("Password reset email sent to your inbox.");
        setEmail("");
      })
      .catch((error) => {
        console.log("Reset email unsuccessful");
        console.log(error);
        if (error.code === "auth/user-not-found") {
          setErrorMessage("Invalid email");
        } else {
          setErrorMessage("Server error");
          console.log(error.code);
        }
      })
      .finally(() => {
        setSubmitDisabled(false);
      });
  };

  const onSignInClicked = (event) => {
    event.preventDefault();
    props.signInHandler();
  };

  return (
    <form onSubmit={onFormSubmit}>
      <h3 className="mb-4">Reset your password</h3>
      <div className="mb-3">
        <input
          type="email"
          value={email}
          onChange={onEmailChange}
          disabled={submitDisabled}
          className="form-control"
          id="email-input"
          aria-describedby="emailHelp"
          placeholder="Email"
        />
      </div>
      <div
        style={{
          fontSize: 14,
          color: successMessage ? "black" : "red",
          marginBottom: 2,
          marginTop: 0,
          paddingTop: 0,
          paddingBottom: successMessage || errorMessage ? 0 : 21,
        }}
      >
        {successMessage || errorMessage}
      </div>
      <div className="d-grid gap-2">
        <WCButton
          type="submit"
          disabled={submitDisabled}
          variant="outline-dark"
        >
          Reset password
        </WCButton>
        <WCButton
          onClick={onSignInClicked}
          variant="outline-link"
          size="sm"
          className="g-0 m-0 p-0 gap-0 text-end"
        >
          Back to login instead?
        </WCButton>
      </div>
    </form>
  );
}
