import { Icon } from "@iconify/react";
import MembershipBadge from "components/common/MembershipBadge";
import { getFirestore } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { fbApp } from "libs/firebase";
import { getProfilePictureUrl } from "libs/images";
import { Mixpanel } from "libs/mixpanel";
import moment from "moment/moment";
import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import { CurrentUser } from "../../libs/auth";
import WCImage from "../atom/WCImage";
import ContactButton from "../common/ContactButton";
import PhotoBrowser from "../common/PhotoBrowser";
import ShareButton from "../common/ShareButton";
import CommentDetail from "./CommentDetail";

moment.updateLocale("en", {
  relativeTime: {
    future: "%s",
    past: "%s",
    s: "1s",
    ss: "%ds",
    m: "1m",
    mm: "%dm",
    h: "1h",
    hh: "%dh",
    d: "1d",
    dd: "%dd",
    w: "1w",
    ww: "%dw",
    M: "1mo",
    MM: "%dmo",
    y: "1y",
    yy: "%dy",
  },
});
const iconSize = { fontSize: "24px" };

const createComment = httpsCallable(
  getFunctions(fbApp, "us-west2"),
  "createComment"
);

export default function PostCard({
  loggedInProfile,
  currentUserProfile,
  type,
  listing,
  hideActions = false,
  hideHeader = false,
}) {
  const currentUser = CurrentUser();
  const author = currentUserProfile ?? listing.author;
  const firestore = getFirestore();
  const [text, setText] = useState("");
  const commentRef = useRef(null);
  const [comments, setComments] = useState(
    listing.comments?.length > 0 ? [...listing.comments].reverse() : []
  );
  const timestamp = new Date(listing.created_at);

  // TODO
  const handleOnEnterComment = (text) => {
    if (text.length > 0) {
      let commentData = {
        text: text,
        created_at: Date.now(),
        author_id: currentUser.uid,
        author: loggedInProfile,
      };

      // set comments in the UI
      const allComments = [...comments];
      allComments.push(commentData);
      setComments(allComments);

      // add new comment to the backend
      createComment({
        text,
        feed_id: listing.uid,
      }).then(
        () => {
          console.log("comments saved successfully");
        },
        (err) => console.log(err)
      );
    }
  };

  const onSeeProfileClicked = () => {
    Mixpanel.track("see_profile_button_clicked", {
      profile_username: author.username,
      profile_uid: author.uid,
    });
  };

  return (
    <>
      <div className="card">
        {!hideHeader && (
          <Row
            className="text-start mx-2 mt-2 mb-0"
            style={{ display: "inline" }}
          >
            <div className="p-0 m-0">
              <div style={{ left: "0px", display: "inline" }}>
                <Row>
                  <Col xs="10">
                    <WCImage
                      src={getProfilePictureUrl(author.profile_pic_url)}
                      width={80}
                      height={80}
                      style={{
                        width: "50px",
                        height: "50px",
                        borderStyle: "solid",
                        borderWidth: "0px",
                        borderRadius: "75px",
                        margin: "0 0 0 0",
                        float: "left",
                        display: "inline",
                        objectFit: "cover",
                      }}
                    />
                    <span
                      className="p-1 pb-0 mb-1 text-bold"
                      style={{
                        display: "inline",
                        lineHeight: "20px",
                        verticalAlign: "center",
                      }}
                    >
                      <Link
                        className="text-decoration-none text-reset"
                        to={"/profile/".concat(author.username)}
                      >
                        {author.name}&nbsp;
                      </Link>
                    </span>
                    {author?.membership_tier ? (
                      <MembershipBadge size="sm" />
                    ) : null}
                    <br />
                    <span
                      className="text-muted mb-0"
                      style={{
                        fontSize: "0.9rem",
                        fontWeight: "100",
                        lineHeight: "20px",
                        paddingLeft: "4px",
                      }}
                    >
                      {listing.location} &middot; {moment(timestamp).fromNow()}
                    </span>
                  </Col>
                  <Col xs="2">
                    <div
                      style={{
                        right: "0px",
                        display: "inline",
                        float: "right",
                      }}
                    >
                      <span className="text-right">
                        <ShareButton profileToShare={author} showText={false} />
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Row>
        )}
        <h4 className="p-0 m-2 text-start">{listing.title}</h4>
        <Row className="text-start m-2">
          <p className="p-0 pb-0 mb-1">
            {listing.message}
            {listing.return_favor_message &&
              listing.return_favor_message.length > 0 && (
                <>
                  <br />
                  listing.return_favor_message
                </>
              )}
          </p>
        </Row>
        <Row className="">
          <Col xs="12">
            <div className="card-body p-0 m-0">
              <PhotoBrowser image_urls={listing.image_urls} />
            </div>
          </Col>
        </Row>
        {!hideActions && (
          <Row className="mx-2 pt-2">
            <hr className="pb-0 mb-0" />
            <ButtonGroup
              size="sm"
              aria-label="Post Actions"
              className="m-0 p-0"
            >
              <Link to={"/profile/".concat(author.username)}>
                <Button
                  variant="outline-light"
                  className="action-button"
                  onClick={onSeeProfileClicked}
                >
                  <Icon icon="gg:profile" style={iconSize} />
                  &nbsp;View Profile
                </Button>
              </Link>
              <Button
                variant="outline-light"
                className="action-button"
                onClick={() => {
                  commentRef.current.children[0].children[0].children[0].children[1].focus();
                }}
              >
                <Icon
                  icon="material-symbols:mode-comment-outline-rounded"
                  style={iconSize}
                />
                &nbsp;Comment
              </Button>
              <ContactButton
                profile={author}
                profileUid={listing.author_id}
                currentProfile={loggedInProfile}
                messageModal={false}
                modalMode={true}
                buttonType="postAction"
                replyToMessage={true}
              />
              <Link
                target="_blank"
                to={`https://console.firebase.google.com/project/vacation-dog-swap/firestore/databases/-default-/data/~2Frequests~2F${listing.uid}`}
              >
                <Button
                  variant="outline-light"
                  className="action-button"
                  onClick={onSeeProfileClicked}
                >
                  <Icon icon="ph:database" style={iconSize} />
                  &nbsp;Firebase
                </Button>
              </Link>
            </ButtonGroup>
            <hr className="pb-0 mb-0" />
          </Row>
        )}
        <Row className="mx-2 pt-2">
          <span
            className="text-muted text-start"
            style={{
              fontSize: "14px",
            }}
          >
            <Link to={`/inbox?search=${author.username}`}>
              {listing.number_of_reachouts} messages
            </Link>{" "}
            &middot; {listing.number_of_comments} comments{" "}
            {listing.confirmed_hosts?.length > 0 ? (
              <>
                &middot; Confirmed with{" "}
                <Link to={`/profile/${listing.confirmed_hosts[0].username}`}>
                  {listing.confirmed_hosts[0].name}
                </Link>
              </>
            ) : (
              listing.closed && (
                <>
                  &middot; <strong>Request is closed</strong>
                </>
              )
            )}
          </span>
        </Row>
        <>
          <Row className="mx-2 pt-0">
            <div className="m-0 p-0 p-0 text-start">
              {comments.map((commentData, index) => (
                <CommentDetail key={index} commentData={commentData} />
              ))}
            </div>
          </Row>
          <Row className="mx-2 pt-0">
            <div className="m-0 p-0 pb-2 pt-1" ref={commentRef}>
              <InputGroup className="mb-0">
                <Form.Control
                  as="input"
                  size="sm"
                  value={text}
                  onKeyUp={(e) => {
                    if (e.keyCode === 13) {
                      handleOnEnterComment(text);
                      setText("");
                    }
                  }}
                  onChange={(e) => {
                    setText(e.target.value);
                  }}
                  placeholder="Write a public comment..."
                  aria-label="Write a public comment..."
                  aria-describedby="basic-addon2"
                />
                <Button
                  variant="outline-secondary"
                  id="button-addon2"
                  onClick={() => {
                    handleOnEnterComment(text);
                    setText("");
                  }}
                >
                  <Icon icon="material-symbols:send-outline" style={iconSize} />
                </Button>
              </InputGroup>
            </div>
          </Row>
        </>
      </div>
    </>
  );
}
