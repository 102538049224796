import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL } from "libs/constants";

export default function Footer() {
  return (
    <footer className="py-3 border-top navbar-light shadow-none">
      <Container>
        <Row>
          <Col xs="6" lg="4">
            <a
              className="link-secondary text-decoration-none"
              style={{ fontSize: 14 }}
              href="#"
            >
              © 2023 WoofyClub
            </a>
          </Col>
          <Col xs="6" lg="2">
            <a
              className="link-secondary text-decoration-none"
              href="mailto: support@woofyclub.com"
              style={{ fontSize: 14 }}
            >
              Support
            </a>
          </Col>
          <Col xs="6" lg="2">
            <a
              className="link-secondary text-decoration-none"
              href="/sitemap.xml"
              style={{ fontSize: 14 }}
            >
              Sitemap
            </a>
          </Col>
          <Col xs="6" lg="2">
            <a
              className="link-secondary text-decoration-none"
              style={{ fontSize: 14 }}
              variant="primary"
              target="_blank noopener noreferrer"
              href={TERMS_OF_SERVICE_URL}
            >
              Terms
            </a>
          </Col>
          <Col xs="6" lg="2">
            <a
              className="link-secondary text-decoration-none"
              style={{ fontSize: 14 }}
              variant="primary"
              target="_blank noopener noreferrer"
              href={PRIVACY_POLICY_URL}
            >
              Privacy
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
