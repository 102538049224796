import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import OnboardingControlSection from "./OnboardingControlSection";
import WCButtonGroup from "components/atom/WCButtonGroup";
import { WC_BORDER_COLOR } from "components/atom/WCConsts";

export default function OnboardingAboutYourHomeForm(props) {
  if (!props.onNext) {
    throw new Error("OnboardingAboutYourHomeForm requires onNext prop");
  }

  if (!props.onPrevious) {
    throw new Error("OnboardingAboutYourHomeForm requires onPrevious prop");
  }

  const profile = props.profile;
  if (!profile) {
    throw new Error("OnboardingAboutYourHomeForm requires profile prop");
  }

  const [spaceType, setSpaceType] = useState(profile.space_type || null); // ["apartment", "house", "farm"]
  const [backyardType, setBackyardType] = useState(profile.yard_type || null); // ["fenced", "unfenced", "no_yard"]
  const [childrenAge5, setChildrenAge5] = useState(
    profile.children_0_5 ? true : false,
  );
  const [childrenAge12, setChildrenAge12] = useState(
    profile.children_6_12 ? true : false,
  );
  const [catsInHome, setCatsInHome] = useState(
    profile.cats_in_home ? true : false,
  );
  const [dogsAllowedOnFuniture, setDogsAllowedOnFuniture] = useState(
    profile.dogs_allowed_on_furniture ? true : false,
  );
  const [dogsAllowedOnBed, setDogsAllowedOnBed] = useState(
    profile.dogs_allowed_on_bed ? true : false,
  );
  const [dogsStayInCrate, setDogsStayInCrate] = useState(
    profile.dogs_stay_in_crate ? true : false,
  );
  const [dogsVisitDogPark, setDogsVisitDogPark] = useState(
    profile.dogs_might_visit_dog_park ? true : false,
  );
  const [smokingInsideHome, setSmokingInsideHome] = useState(
    profile.smoking_inside_home ? true : false,
  );
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    setErrorMessage("");

    if (!spaceType) {
      setErrorMessage("Please select a home type.");
      return;
    }

    if (!backyardType) {
      setErrorMessage("Please select a backyard type.");
      return;
    }

    props.onNext({
      space_type: spaceType,
      yard_type: backyardType,
      children_0_5: childrenAge5,
      children_6_12: childrenAge12,
      cats_in_home: catsInHome,
      smoking_inside_home: smokingInsideHome,
      dogs_allowed_on_furniture: dogsAllowedOnFuniture,
      dogs_allowed_on_bed: dogsAllowedOnBed,
      dogs_stay_in_crate: dogsStayInCrate,
      dogs_might_visit_dog_park: dogsVisitDogPark,
    });
  };

  return (
    <Container>
      <Form noValidate onSubmit={onSubmit}>
        <Row className="pt-4">
          <h3>Tell us about your home</h3>
        </Row>
        <Row className="mx-1 mt-4 text-start">
          <label htmlFor="" className="form-label">
            What type of home do you live in?
          </label>
          <br />
          <WCButtonGroup
            options={[
              { value: "apartment", label: "Apartment" },
              { value: "house", label: "House" },
              { value: "farm", label: "Farm" },
            ]}
            value={spaceType}
            onValueChange={(value) => {
              setSpaceType(value);
              setErrorMessage("");
            }}
          />
        </Row>
        <Row className="mx-1 mt-4 text-start">
          <label htmlFor="" className="form-label">
            What type of backyard do you have?
          </label>
          <br />
          <WCButtonGroup
            options={[
              { value: "fenced", label: "Fenced" },
              { value: "unfenced", label: "Unfenced" },
              { value: "no_yard", label: "No yard" },
            ]}
            value={backyardType}
            onValueChange={(value) => {
              setBackyardType(value);
              setErrorMessage("");
            }}
          />
        </Row>
        <Row className="mx-1 mt-4 text-start">
          <label htmlFor="" className="form-label">
            Choose all that apply to your home
          </label>
          <Row className="mt-1">
            <Col className="d-flex justify-content-center">
              <OptionBox
                title="Children aged 0-5"
                isFocused={childrenAge5}
                onPress={() => {
                  setChildrenAge5(!childrenAge5);
                }}
              />
            </Col>
            <Col className="d-flex justify-content-center">
              <OptionBox
                title="Children aged 6-12"
                isFocused={childrenAge12}
                onPress={() => {
                  setChildrenAge12(!childrenAge12);
                }}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="d-flex justify-content-center">
              <OptionBox
                title="Cats in home"
                isFocused={catsInHome}
                onPress={() => {
                  setCatsInHome(!catsInHome);
                }}
              />
            </Col>
            <Col className="d-flex justify-content-center">
              <OptionBox
                title="Smoking inside home"
                isFocused={smokingInsideHome}
                onPress={() => {
                  setSmokingInsideHome(!smokingInsideHome);
                }}
              />
            </Col>
          </Row>
        </Row>
        <Row className="mx-1 mt-4 text-start">
          <label htmlFor="" className="form-label">
            Choose all that apply to your dogs
          </label>
          <Row className="mt-1">
            <Col className="d-flex justify-content-center">
              <OptionBox
                title="Allowed on furniture"
                isFocused={dogsAllowedOnFuniture}
                onPress={() => {
                  setDogsAllowedOnFuniture(!dogsAllowedOnFuniture);
                }}
              />
            </Col>
            <Col className="d-flex justify-content-center">
              <OptionBox
                title="Allowed on bed"
                isFocused={dogsAllowedOnBed}
                onPress={() => {
                  setDogsAllowedOnBed(!dogsAllowedOnBed);
                }}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="d-flex justify-content-center">
              <OptionBox
                title="Might stay in crate"
                isFocused={dogsStayInCrate}
                onPress={() => {
                  setDogsStayInCrate(!dogsStayInCrate);
                }}
              />
            </Col>
            <Col className="d-flex justify-content-center">
              <OptionBox
                title="Might visit a dog park nearby"
                isFocused={dogsVisitDogPark}
                onPress={() => {
                  setDogsVisitDogPark(!dogsVisitDogPark);
                }}
              />
            </Col>
          </Row>
        </Row>
        <Row>
          <div
            className="d-block d-md-none"
            style={{ paddingBottom: "200px" }}
          />
        </Row>
        <OnboardingControlSection
          onNext={onSubmit}
          onPrevious={props.onPrevious}
          errorMessage={errorMessage}
        />
      </Form>
    </Container>
  );
}

function OptionBox({ title, onPress, isFocused }) {
  if (!title || !onPress) {
    throw new Error("OptionBox requires title and onPress props");
  }

  return (
    <Button
      style={{
        backgroundColor: isFocused ? "black" : "#FFFFFF",
        color: isFocused ? "white" : "black",
        border: "1px solid",
        borderColor: WC_BORDER_COLOR,
        borderRadius: "6px",
        height: "120px",
        width: "120px",
      }}
      onClick={onPress}
    >
      {title}
    </Button>
  );
}
