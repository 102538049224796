import { Icon } from "@iconify/react";
import WCButton from "components/atom/WCButton";
import {
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  signInWithRedirect,
} from "firebase/auth";
import { Mixpanel } from "libs/mixpanel";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CurrentUser, LogInWithEmailPassword } from "../../libs/auth";
import { ValidateEmail } from "../../libs/email";
import PasswordResetForm from "../PasswordResetForm";
import { isEmailInAdminList } from "libs/admin";
import { ERROR_MESSAGE_NOT_AUTHORIZED } from "libs/constants";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function LoginForm(props) {
  const auth = getAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const forgotLinkStyle = { fontSize: "0.9rem" };
  const urlQuery = useQuery();
  const [showPasswordResetForm, setShowPasswordResetForm] = useState(
    urlQuery.get("reset_password") === "1" ? true : false
  );
  const redirectURL = urlQuery.get("redirect_url");
  const navigate = useNavigate();

  const onEmailChange = (event) => {
    setEmail(event.target.value);
    setErrorMessage("");
  };

  const onPasswordChange = (event) => {
    setPassword(event.target.value);
    setErrorMessage("");
  };

  const handleSSO = async (event, providerType) => {
    event.preventDefault();

    let provider;
    if (providerType === "google") {
      provider = new GoogleAuthProvider();
    } else if (providerType === "facebook") {
      provider = new FacebookAuthProvider();
    } else {
      return;
    }

    Mixpanel.track("login", {
      method: providerType,
    });
    signInWithRedirect(auth, provider);
  };

  const onFormSubmit = (event) => {
    event.preventDefault();
    setErrorMessage("");

    if (email.length === 0) {
      setErrorMessage("Please fill out your email address.");
      return;
    }
    if (password.length === 0) {
      setErrorMessage("Please fill out your password.");
      return;
    }
    if (!ValidateEmail(email)) {
      setErrorMessage("Invalid email address");
      return;
    }

    if (!isEmailInAdminList(email)) {
      setErrorMessage(ERROR_MESSAGE_NOT_AUTHORIZED);
      return;
    }

    setSubmitDisabled(true);
    LogInWithEmailPassword(email, password)
      .then((userCredential) => {
        // Signed in
        console.log("Logging in successfully");
        const user = userCredential.user;
        console.log(user);
        Mixpanel.track("login", {
          method: "email",
          success: true,
        });
        navigate(redirectURL ? redirectURL : "/");
      })
      .catch((error) => {
        console.log("Logging in unsuccessfully");
        if (error.code === "auth/user-not-found") {
          setErrorMessage("Email not found");
        } else if (error.code === "auth/wrong-password") {
          setErrorMessage("Invalid password");
        } else {
          setErrorMessage("Server error. Please try again");
          console.log(error.code);
          Mixpanel.track("login", {
            method: "email",
            success: false,
          });
        }
      })
      .finally(() => {
        setSubmitDisabled(false);
      });
  };

  useEffect(() => {
    if (CurrentUser()) {
      navigate(redirectURL ? redirectURL : "/");
      return;
    }
  }, []);

  return (
    <div>
      {!showPasswordResetForm && (
        <form onSubmit={onFormSubmit}>
          <h3 className="mb-4">Join The Community</h3>
          <div className="mb-3">
            <input
              type="email"
              value={email}
              onChange={onEmailChange}
              disabled={submitDisabled}
              className="form-control"
              id="email-input"
              aria-describedby="emailHelp"
              placeholder="Email"
            />
          </div>
          <div className="mb-3">
            <input
              type="password"
              value={password}
              onChange={onPasswordChange}
              disabled={submitDisabled}
              className="form-control"
              id="password-input"
              placeholder="Password"
            />
          </div>
          <div
            style={{
              fontSize: 14,
              color: "red",
              marginBottom: 2,
              marginTop: 0,
              paddingTop: 0,
              paddingBottom: errorMessage ? 0 : 21,
            }}
          >
            {errorMessage}
          </div>
          <div className="d-grid gap-2">
            <WCButton type="submit" disabled={submitDisabled} variant="primary">
              Log in
            </WCButton>
            <WCButton
              className="mt-2"
              variant="outline-secondary"
              onClick={(e) => handleSSO(e, "google")}
            >
              <Icon icon="mdi:google" style={{ fontSize: "18px" }} />
              &nbsp; Continue with Google
            </WCButton>
            <WCButton
              onClick={() => setShowPasswordResetForm(true)}
              variant="outline-link"
              style={forgotLinkStyle}
            >
              Forgot your password?
            </WCButton>
          </div>
        </form>
      )}
      {showPasswordResetForm && (
        <PasswordResetForm
          signInHandler={() => {
            setShowPasswordResetForm(false);
          }}
        />
      )}
    </div>
  );
}
