import { createContext, useCallback, useContext, useState } from "react";

export const ModalKey = {
  APP_UPSELL: "APP_UPSELL",
};

export const ModalContext = createContext({
  currentModal: null,
  showModal: (_) => console.warn("Method not implemented"),
  closeModal: (_) => console.warn("Method not implemented"),
});

export const useModal = () => useContext(ModalContext);
export const ModalProvider = (props) => {
  const [currentModal, setCurrentModal] = useState(null);

  const showModal = useCallback((modalProps) => {
    if (!modalProps) {
      setCurrentModal(null);
      return;
    }
    const modalKey = modalProps?.modalKey;
    if (modalKey && ModalKey[modalKey]) {
      setCurrentModal({
        modalKey,
        props: modalProps?.props,
      });
    }
  }, []);

  const closeModal = useCallback(() => {
    if (currentModal?.props?.onModalClose) {
      currentModal.props.onModalClose();
    }
    setCurrentModal(null);
  }, [currentModal?.props]);

  return (
    <ModalContext.Provider
      value={{
        currentModal,
        showModal,
        closeModal,
      }}
    >
      {props.children}
    </ModalContext.Provider>
  );
};
