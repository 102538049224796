// Input: user structure
// Output:
// 1. % Completion
// 2. For each category, how many questions were completed?
// 3. Recommended next step to fill out

export function ProfileCompletion(user) {
  // This function takes in a user json and returns the percent completion (rounded up to the closest 5%)
  // There are 14 profile attributes and 22 dog profile attributes (per dog)
  const user_attr_total = 6;
  const space_attr_total = 3;
  const host_attr_total = 5;
  const dog_attr_total = user.dogs.length * 21;

  let user_attr_filled_out = 1; // email is always filled out hence we start with 1
  let space_attr_filled_out = 0;
  let host_pref_attr_filled_out = 0;
  let dog_attr_filled_out = 0;

  let dog_profile_pic = false;
  let dog_images = false;
  let next_steps = "";

  // 1/4 weight
  if (user.address) {
    user_attr_filled_out += 1;
  }
  if (user.profile_pic_url) {
    user_attr_filled_out += 1;
  }
  if (user.intro_text) {
    user_attr_filled_out += 1;
  }
  if (user.first_name || user.last_name || user.name) {
    user_attr_filled_out += 1;
  }
  if (user.phone_number) {
    user_attr_filled_out += 1;
  }

  // 1/4 weight
  if (user.space_type) {
    space_attr_filled_out += 1;
  }
  if (user.yard_type) {
    space_attr_filled_out += 1;
  }
  if (
    user.smoking_inside_home ||
    user.children_0_5 ||
    user.children_6_12 ||
    user.cats_in_home ||
    user.dogs_allowed_on_bed ||
    user.dogs_allowed_on_furniture ||
    user.dogs_might_visit_dog_park ||
    user.dogs_might_meet_other_dogs_in_building
  ) {
    space_attr_filled_out += 1;
  }

  // 1/4 weight
  if (user.pref_pet_size) {
    host_pref_attr_filled_out += 1;
  }
  if (user.pref_shedding_type) {
    host_pref_attr_filled_out += 1;
  }
  if (user.pref_number_of_dogs) {
    host_pref_attr_filled_out += 1;
  }
  if (user.pref_travel_distance) {
    host_pref_attr_filled_out += 1;
  }
  if (user.pref_pet_schedule) {
    host_pref_attr_filled_out += 1;
  }

  // 1/4 weight
  for (let i = 0; i < user.dogs.length; i++) {
    let dog_profile = user.dogs[i];

    if (dog_profile.profile_pic_url) {
      dog_attr_filled_out += 1;
      dog_profile_pic = true;
    }
    if (dog_profile.name) {
      dog_attr_filled_out += 1;
    }
    if (dog_profile.breed) {
      dog_attr_filled_out += 1;
    }
    if (dog_profile.shedding_type) {
      dog_attr_filled_out += 1;
    }
    if (dog_profile.gender) {
      dog_attr_filled_out += 1;
    }
    if (dog_profile.weight) {
      dog_attr_filled_out += 1;
    }
    if (dog_profile.birthdate) {
      dog_attr_filled_out += 1;
    }
    if (dog_profile.favorite_thing) {
      dog_attr_filled_out += 1;
    }
    if (dog_profile.neutered) {
      dog_attr_filled_out += 1;
    }
    if (dog_profile.microchipped) {
      dog_attr_filled_out += 1;
    }
    if (dog_profile.house_trained) {
      dog_attr_filled_out += 1;
    }
    if (dog_profile.crate_trained) {
      dog_attr_filled_out += 1;
    }
    if (dog_profile.has_stay_away) {
      dog_attr_filled_out += 1;
    }
    if (dog_profile.cat_friendly) {
      dog_attr_filled_out += 1;
    }
    if (dog_profile.children_friendly) {
      dog_attr_filled_out += 1;
    }
    if (dog_profile.energy_level) {
      dog_attr_filled_out += 1;
    }
    if (dog_profile.potty_schedule) {
      dog_attr_filled_out += 1;
    }
    if (
      dog_profile.feeding_schedule_evening ||
      dog_profile.feeding_schedule_morning ||
      dog_profile.feeding_schedule_afternoon ||
      dog_profile.feeding_schedule_custom
    ) {
      dog_attr_filled_out += 1;
    }
    if (dog_profile.left_alone) {
      dog_attr_filled_out += 1;
    }
    if (
      dog_profile.travel_ok_in_small_group ||
      dog_profile.travel_ok_with_car_rides ||
      dog_profile.travel_ok_in_large_group
    ) {
      dog_attr_filled_out += 1;
    }
    if (dog_profile.image_urls || dog_profile.instagram_link) {
      dog_attr_filled_out += 1;
      dog_images = true;
    }
  }

  if (!dog_profile_pic) {
    next_steps = "Add a profile picture of your dog.";
  } else if (!dog_images) {
    next_steps = "Add a photo or two of your dog.";
  } else if (!user.profile_pic_url) {
    next_steps = "Add a profile picture for yourself.";
  } else if (host_pref_attr_filled_out < host_attr_total) {
    next_steps = "Fill out your hosting preferences.";
  } else if (space_attr_filled_out < space_attr_total) {
    next_steps = "Fill out some details about your space.";
  } else {
    next_steps = "Good job! You have a pretty good profile.";
  }

  // calculation starts here
  let profile_completeness_exact =
    (user_attr_filled_out / user_attr_total) * 25 +
    (space_attr_filled_out / space_attr_total) * 25 +
    (host_pref_attr_filled_out / host_attr_total) * 25 +
    (dog_attr_filled_out / dog_attr_total) * 25;
  let profile_completeness_approx =
    Math.round(profile_completeness_exact / 5) * 5;

  let profile_completion = {
    progress: 0,
    next_steps: "",
    category_breakdown: {},
  };
  profile_completion.progress = profile_completeness_approx;
  profile_completion.next_steps = next_steps;
  profile_completion.category_breakdown = {
    "Personal": "" + user_attr_filled_out + "/" + user_attr_total,
    "Your Home": "" + space_attr_filled_out + "/" + space_attr_total,
    "Hosting Preferences":
      "" + host_pref_attr_filled_out + "/" + host_attr_total,
    "About Your Dogs": "" + dog_attr_filled_out + "/" + dog_attr_total,
  };

  return profile_completion;
}
