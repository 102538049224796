import { getFunctions, httpsCallable } from "firebase/functions";
import * as Sentry from "@sentry/react";

const functions = getFunctions();
export const getDogBreeds = async () => {
  return httpsCallable(functions, "getDogBreeds")()
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      Sentry.captureException(err);
      return [];
    });
};

export const getDogBreedTypeFromValue = (value) => {
  return {
    value,
    label: getDogBreedLabelFromValue(value),
  };
};

export const getDogBreedLabelFromValue = (value) => {
  if (!value) return null;
  const words = value.split("_");

  // Capitalize the first letter of each word and join them
  return words
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
};
