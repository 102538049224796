import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import ProgressBar from "react-bootstrap/ProgressBar";
import useStorage from "hooks/useStorage";
import { v4 as uuidv4 } from "uuid";
import { deleteObject } from "firebase/storage";
import { Icon } from "@iconify/react";
import OnboardingControlSection from "./OnboardingControlSection";

export default function OnboardingDogPhotosForm(props) {
  if (!props.onNext) {
    throw new Error("OnboardingDogPhotosForm requires onNext prop");
  }
  if (!props.onPrevious) {
    throw new Error("OnboardingDogPhotosForm requires onPrevious prop");
  }

  const profile = props.profile;
  if (!profile) {
    throw new Error("OnboardingDogPhotosForm requires profile prop");
  }

  const [images, setImages] = useState(profile.images || []);
  const [errorMessage, setErrorMessage] = useState("");

  const onNext = () => {
    setErrorMessage("");
    if (!images || images.length === 0) {
      setErrorMessage("Please upload at least one photo");
      return;
    }

    const imageUrls = images.map((image) => image.uri);
    const dogProfile = { ...profile, image_urls: imageUrls, images: images };
    props.onNext({
      dogs: [dogProfile],
    });
  };

  return (
    <Container style={{ backgroundColor: "white" }}>
      <Row className="pt-4 px-4">
        <h3>Upload photos of {profile.name || "your pup"}</h3>
        <div className="form-text">{getSubtitle(profile, images.length)}</div>
      </Row>
      <Row className="mx-1 mt-4" style={{ marginBottom: "100px" }}>
        <PhotoGallery
          images={images}
          onUpdate={(images) => {
            setErrorMessage("");
            if (images) {
              setImages(images);
            }
          }}
        />
        <div className="d-block d-md-none" style={{ marginBottom: "200px" }} />
      </Row>
      <OnboardingControlSection
        onNext={onNext}
        onPrevious={props.onPrevious}
        errorMessage={errorMessage}
      />
    </Container>
  );
}

function PhotoGallery(props) {
  if (!props.onUpdate) {
    throw new Error("PhotoGallery requires onUpdate prop");
  }

  const [images, setImages] = useState(props.images || []);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(50);
  const uploadFormId = uuidv4();
  const [uploadFormValue, setUploadFormValue] = useState("");

  const onImageCreated = (image) => {
    if (image) {
      const newImages = [...images, image];
      setImages(newImages);
      props.onUpdate(newImages);
      setShowProgressBar(false);
    }
  };

  useStorage(file, onImageCreated, (progress) => setUploadProgress(progress));

  const onChange = (e) => {
    if (e?.target?.files?.length == 0) {
      throw new Error("OnboardingDogPhotosForm: empty file");
    }

    const file = e.target.files[0];
    if (file) {
      setShowProgressBar(true);
      setFile(file);
      setUploadFormValue("");
    }
  };

  const onRemove = async (image) => {
    if (image) {
      const newImages = [...images];
      const index = newImages.indexOf(image);
      if (index > -1) {
        newImages.splice(index, 1);
      }
      setImages(newImages);
      props.onUpdate(newImages);
      try {
        await deleteObject(image.ref);
      } catch (error) {
        console.error(error);
        throw new Error("OnboardingDogPhotosForm: failed to delete image");
      }
    }
  };

  return (
    <Container className="px-3" style={{ backgroundColor: "white" }}>
      <Row className="mb-3">
        <form>
          <label htmlFor={uploadFormId}>
            <a
              className="btn btn-outline-dark align-center"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              Upload photo
            </a>
          </label>
          <input
            id={uploadFormId}
            type="file"
            accept=".HEIC, .jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
            value={uploadFormValue}
            onChange={onChange}
            style={{ display: "none" }}
          />
          {showProgressBar && uploadProgress != null && (
            <ProgressBar
              now={uploadProgress}
              className="mt-2 mx-2"
              variant="dark"
            />
          )}
        </form>
      </Row>
      {images.map((image) => (
        <Row
          key={image.uri}
          className="px-4 mb-3 d-flex justify-content-center"
        >
          <Photo uri={image.uri} onRemove={() => onRemove(image)} />
        </Row>
      ))}
    </Container>
  );
}

function Photo(props) {
  if (!props.uri) {
    throw new Error("OnboardingDogPhotosForm: photo uri is required.");
  }
  if (!props.onRemove) {
    throw new Error("OnboardingDogPhotosForm: photo onRemove is required.");
  }

  return (
    <div style={{ position: "relative", padding: "0px", margin: "0px" }}>
      <img
        src={props.uri}
        width="100%"
        style={{
          borderWidth: "1px",
          borderColor: "black",
          borderStyle: "solid",
          borderRadius: "6px",
          padding: "0px",
        }}
      />
      <div
        style={{
          position: "absolute",
          top: "-10px",
          right: "0px",
        }}
        onClick={() => {
          props.onRemove();
        }}
      >
        <Icon
          icon="carbon:close-outline"
          style={{
            fontSize: "24px",
            marginRight: "-6px",
            color: "black",
            cursor: "pointer",
            backgroundColor: "white",
            borderRadius: "50%",
          }}
          className=""
        />
      </div>
    </div>
  );
}

function getSubtitle(dogProfile, imagesCount) {
  if (!dogProfile || !dogProfile.name) {
    throw new Error("DogPhotoUploadForm.getSubtitle: dogProfile is required.");
  }

  if (imagesCount === 0) {
    return (
      "We know " +
      dogProfile.name +
      " is the cutest, but we need to see it to believe it!"
    );
  } else if (imagesCount === 1) {
    return (
      "Wow, " +
      dogProfile.name +
      " is so adorable! Can you add a few more photos?"
    );
  } else if (imagesCount === 2) {
    return "Three times a charm. Can you add 1 more photo?";
  } else {
    return "What a cutie " + dogProfile.name + " is!";
  }
}
