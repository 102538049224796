import { useEffect, useState } from "react";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { fbStorage } from "../libs/firebase";
import { v4 as uuidv4 } from "uuid";

const useStorage = (file, onImageCreated, onProgressUpdated) => {
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const [url, setUrl] = useState(null);

  useEffect(() => {
    if (!file) {
      return;
    }

    const storageDir =
      process.env.NODE_ENV === "development"
        ? "user_images_dev/"
        : "user_images/";
    const storageRef = ref(fbStorage, storageDir + uuidv4());
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = Math.floor(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
        );
        console.log("Upload is " + progress + "% done");
        setProgress(progress);
        onProgressUpdated && onProgressUpdated(progress);
      },
      (err) => {
        // Handle unsuccessful uploads
        setError(err);
        console.log(err);
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setUrl(downloadURL);
          if (onImageCreated)
            onImageCreated({ uri: downloadURL, ref: uploadTask.snapshot.ref });
          console.log("File available at", downloadURL);
        });
      },
    );
  }, [file]);

  return { progress, url, error };
};

export default useStorage;
