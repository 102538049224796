import { captureException } from "@sentry/browser";
import { DEFAULT_PROFILE_PIC_URL } from "./constants";

export const getCdnUrl = (url, width = 400, height = 400, quality = 85) => {
  const path = _getPathFromUrl(url);
  return _getCdnUrlFromPath(path, width, height, quality);
};

export const getProfilePictureUrl = (picture) => {
  if (!picture) return DEFAULT_PROFILE_PIC_URL;
  return picture;
};

const _getCdnUrlFromPath = (path, width = 500, height = 500, quality = 85) => {
  const params = new URLSearchParams({
    w: width,
    h: height,
    q: quality,
  }).toString();
  return `${process.env.REACT_APP_CDN_URL}${path}?${params}`;
};

const _isValidFirebaseStorageUrl = (urlComponents) => {
  return (
    urlComponents.length >= 7 &&
    urlComponents[5] === "vacation-dog-swap.appspot.com" &&
    urlComponents[6] === "o"
  );
};

/*
  Gets the path from a Firebase storage URL 
  If the url is 
  Example:
    Input: https://firebasestorage.googleapis.com/v0/b/vacation-dog-swap.appspot.com/o/avatars%2F1%2Fprofile_pic.jpg?alt=media&token=3b1d4b3e-2a7f-4e8e-9e2b-9d5d7a3b2e2a
    Output: avatars/1/profile_pic.jpg
*/
const _getPathFromUrl = (url) => {
  if (!url) {
    return null;
  }
  const urlComponents = url.split("/");
  if (!_isValidFirebaseStorageUrl(urlComponents)) {
    captureException("Invalid Firebase storage url", {
      extra: {
        url,
      },
    });
    return null;
  }
  return decodeURIComponent(
    urlComponents[urlComponents.length - 1].split("?")[0],
  );
};
