import { captureException } from "@sentry/react";

export function CreateDisplayName(firstName, lastName) {
  if (!firstName) {
    throw new Error("First name cannot be null");
  }

  if (!lastName) {
    return firstName;
  }

  console.log(firstName, lastName);

  return (
    firstName.charAt(0).toUpperCase() +
    firstName.slice(1) +
    " " +
    lastName.charAt(0).toUpperCase() +
    "."
  );
}

export function getLocationFromAddress(address, useLongName = false) {
  if (!address) {
    captureException(new Error("Address is null"));
    return null;
  }

  const state = address.address_components.administrative_area_level_1;
  if (!state) {
    return null;
  }
  const stateName = useLongName ? state.long_name : state.short_name;
  const city =
    address.address_components?.locality ||
    address.address_components?.neighborhood;
  if (!city) {
    return stateName;
  }
  const cityName = useLongName ? city.long_name : city.short_name;

  return cityName + ", " + stateName;
}
