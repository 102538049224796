import React from "react";
import Modal from "react-bootstrap/Modal";
import WCButton from "components/atom/WCButton";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import * as Sentry from "@sentry/react";

export default function AddressPickerModal(props) {
  const onSuccess = props.onSuccess;
  if (!onSuccess) {
    throw new Error("AddressPickerModal requires onSuccess prop");
  }

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: "AIzaSyCxTq1Gj3uNeEakx_4gFPykgL3VPk06wMo",
  });

  const renderItem = (item, isLast) => {
    let borderBottom;
    if (isLast) {
      borderBottom = "none";
    } else {
      borderBottom = "1px solid #ccc";
    }

    return (
      <div
        key={item.place_id}
        className="px-2 py-3"
        style={{ cursor: "pointer", borderBottom: borderBottom }}
        onClick={() => {
          placesService.getDetails(
            {
              placeId: item.place_id,
              fields: [
                "address_components",
                "formatted_address",
                "geometry",
                "place_id",
              ],
            },
            (place, status) => {
              if (status === "OK") {
                const address = getAddressFromGooglePlace(place);
                onSuccess(address);
              } else {
                Sentry.captureMessage(
                  "usePlacesService getDetails failed: " + status,
                );
              }
            },
          );
        }}
      >
        {item.description}
      </div>
    );
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ display: "inline-flex", alignItems: "center" }}
        >
          Home Address
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-0">
        <input
          className="form-control mb-2"
          placeholder="Search for your home address..."
          autoFocus
          onChange={(evt) => {
            getPlacePredictions({
              input: evt.target.value,
              types: ["address"],
            });
          }}
          loading={isPlacePredictionsLoading}
        />
        {placePredictions.map((item, index) =>
          renderItem(item, index === placePredictions.length - 1),
        )}
      </Modal.Body>
      <Modal.Footer>
        <WCButton
          variant="outline-link"
          onClick={() => {
            onSuccess(null);
          }}
        >
          Clear address
        </WCButton>
        <WCButton variant="outline-dark" onClick={props.onHide}>
          Close
        </WCButton>
      </Modal.Footer>
    </Modal>
  );
}

function getAddressFromGooglePlace(place) {
  if (!place) {
    throw new Error("getAddressFromGooglePlace: place is null");
  }
  if (!place.address_components) {
    throw new Error(
      "getAddressFromGooglePlace: place.address_components is null",
    );
  }
  if (!place.formatted_address) {
    throw new Error(
      "getAddressFromGooglePlace: place.formatted_address is null",
    );
  }
  if (!place.geometry?.location?.lat() || !place.geometry?.location?.lng()) {
    throw new Error("getAddressFromGooglePlace: place.geometry is null");
  }

  let address_components = {};
  place.address_components.forEach((address_component) => {
    if (!address_component.types || address_component.types.length === 0) {
      throw new Error(
        "getAddressFromGooglePlace: address_component.types is null",
      );
    }
    const placeType = address_component.types[0];
    if (!placeType || placeType.length === 0) {
      throw new Error("getAddressFromGooglePlace: placeType is null");
    }
    const shortName = address_component.short_name;
    const longName = address_component.long_name;
    address_components[placeType] = {
      short_name: shortName,
      long_name: longName,
    };
  });

  return {
    address_components: address_components,
    formatted_address: place.formatted_address,
    latitude: place.geometry.location.lat(),
    longitude: place.geometry.location.lng(),
    place_id: place.place_id,
  };
}
