import { Icon } from "@iconify/react";
import MembershipBadge from "components/common/MembershipBadge";
import {
  addDoc,
  collection,
  getDocs,
  getFirestore,
  orderBy,
  query,
} from "firebase/firestore";
import { getProfilePictureUrl } from "libs/images";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import { CurrentUser } from "../../libs/auth";
import { DistanceBetween } from "../../libs/DistanceBetween";
import WCImage from "../atom/WCImage";
import PhotoBrowser from "../common/PhotoBrowser";
import ShareButton from "../common/ShareButton";
import CommentDetail from "./CommentDetail";
import PostCardDogDetail from "./PostCardDogDetail";
import { getLocationFromAddress } from "libs/profile";

const iconSize = { fontSize: "24px" };
const dateOptions = {
  weekday: "long",
  year: "numeric",
  month: "short",
  day: "numeric",
  timeZone: "Europe/London",
};

export default function ListingPostCard({
  loggedInProfile,
  currentUserProfile,
  type,
  listing,
  hideHeader = false,
}) {
  const currentUser = CurrentUser();
  const author = currentUserProfile ?? listing.author;
  const firestore = getFirestore();
  const [dogPhotos, setDogPhotos] = useState(null);
  const [text, setText] = useState("");
  const commentRef = useRef(null);
  const [comments, setComments] = useState([]);
  const [playdateMeetupPref, setPlaydateMeetupPref] = useState("");

  const location = getLocationFromAddress(author.address, false) || "";
  // TODO
  const handleOnEnterComment = (text) => {
    if (text.length > 0) {
      let commentData = {
        text: text,
        created_at: Date.now(),
        author_id: currentUser.uid,
        author: loggedInProfile,
      };

      if (type === "listing" || type === "listing_on_profile") {
        // set comments in the UI
        const allComments = [...comments];
        allComments.push(commentData);
        setComments(allComments);

        // add new comment to the backend
        addDoc(
          collection(firestore, "requests", listing.uid, "comments"),
          commentData
        ).then(
          () => {
            console.log("comments saved successfully");
          },
          (err) => console.log(err)
        );
      }
    }
  };

  useEffect(() => {
    let allDogPhotos = [];
    author.dogs.forEach((dog) => {
      if (dog.profile_pic_url) {
        allDogPhotos.push(dog.profile_pic_url);
      }
      if (dog.image_urls) {
        dog.image_urls.forEach((image_url) => {
          if (image_url) {
            allDogPhotos.push(image_url);
          }
        });
      }
    });
    setDogPhotos(allDogPhotos);

    if (listing?.uid) {
      const commentsRef = collection(
        firestore,
        "requests",
        listing.uid,
        "comments"
      );
      getDocs(query(commentsRef, orderBy("created_at", "asc"))).then(
        (snapshot) => {
          const commentsData = [];
          snapshot.forEach((doc) => {
            commentsData.push(doc.data());
          });
          if (commentsData.length > 0) {
            setComments(commentsData);
          }
        }
      );
    }

    if (listing?.request_type === "playdate") {
      let prefs = [];
      if (listing.meetup_pref?.myPlace) {
        prefs.push("my place");
      }
      if (listing.meetup_pref?.yourPlace) {
        prefs.push("your place");
      }
      if (listing.meetup_pref?.dogPark) {
        prefs.push("dog park");
      }
      setPlaydateMeetupPref(prefs.join(" or "));
    }
  }, []);

  return (
    <>
      <div className="card">
        {!hideHeader && (
          <Row
            className="text-start mx-2 mt-2 mb-0"
            style={{ display: "inline" }}
          >
            <div className="p-0 m-0">
              <div style={{ left: "0px", display: "inline" }}>
                <Row>
                  <Col xs="10">
                    <WCImage
                      src={getProfilePictureUrl(author.profile_pic_url)}
                      width={80}
                      height={80}
                      style={{
                        width: "50px",
                        height: "50px",
                        borderStyle: "solid",
                        borderWidth: "0px",
                        borderRadius: "75px",
                        margin: "0 0 0 0",
                        float: "left",
                        display: "inline",
                        objectFit: "cover",
                      }}
                    />
                    <span
                      className="p-1 pb-0 mb-1 text-bold"
                      style={{
                        display: "inline",
                        lineHeight: "20px",
                        verticalAlign: "center",
                      }}
                    >
                      <Link
                        className="text-decoration-none text-reset"
                        to={"/profile/".concat(author.username)}
                      >
                        {author.name}&nbsp;
                      </Link>
                    </span>
                    {author?.membership_tier ? (
                      <MembershipBadge size="sm" />
                    ) : null}
                    <br />
                    <span
                      className="text-muted mb-0"
                      style={{
                        fontSize: "0.9rem",
                        fontWeight: "100",
                        lineHeight: "20px",
                      }}
                    >
                      {author.address.latitude &&
                        author.address.longitude &&
                        loggedInProfile.address.latitude &&
                        loggedInProfile.address.longitude && (
                          <>
                            &nbsp;
                            {DistanceBetween(
                              author.address.latitude,
                              author.address.longitude,
                              loggedInProfile.address.latitude,
                              loggedInProfile.address.longitude
                            )}
                            mi&nbsp;&#183;&nbsp;
                          </>
                        )}
                      &nbsp;{location}
                    </span>
                  </Col>
                  <Col xs="2">
                    <div
                      style={{
                        right: "0px",
                        display: "inline",
                        float: "right",
                      }}
                    >
                      <span className="text-right">
                        {type === "listing" || type === "listing_on_profile" ? (
                          <ShareButton
                            listingToShare={listing.uid}
                            showText={false}
                          />
                        ) : (
                          <></>
                        )}
                        {type === "profile" ? (
                          <ShareButton
                            profileToShare={author}
                            showText={false}
                          />
                        ) : (
                          <></>
                        )}
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Row>
        )}
        {(type === "listing" || type === "listing_on_profile") && (
          <h4 className="p-0 m-2 text-start">{_getRequestTitle(listing)}</h4>
        )}
        <Row className="mx-2 align-items-center">
          <Col>
            <span className="text-muted">
              Created on:{" "}
              {moment(listing?.created_at.toDate()).format(
                "dddd, MMMM Do YYYY"
              )}
            </span>
            <span className="mx-2">&middot;</span>
            <Link
              target="_blank"
              to={`https://console.firebase.google.com/project/vacation-dog-swap/firestore/databases/-default-/data/~2Frequests~2F${listing.uid}`}
            >
              Firebase
            </Link>
          </Col>
        </Row>
        <Row className="text-start m-2">
          <p className="p-0 pb-0 mb-1">
            {type === "listing" || type === "listing_on_profile" ? (
              listing.request_type === "playdate" &&
              (listing.meetup_pref?.myPlace ||
                listing.meetup_pref?.yourPlace ||
                listing.meetup_pref?.dogPark) ? (
                <>
                  {listing.message}
                  <br />
                  <b>{" I prefer to meet: ".concat(playdateMeetupPref)}</b>
                </>
              ) : (
                listing.message
              )
            ) : type === "profile" ? (
              author.intro_text
            ) : (
              ""
            )}
          </p>
        </Row>

        {type !== "listing_on_profile" ? (
          <Row className="text-start m-2">
            {author.dogs.map((dog, index) => (
              <PostCardDogDetail dog={dog} key={index} />
            ))}
          </Row>
        ) : (
          <></>
        )}

        {type !== "listing_on_profile" && dogPhotos ? (
          <Row className="">
            <Col xs="12">
              <div className="card-body p-0 m-0">
                <PhotoBrowser image_urls={dogPhotos} />
              </div>
            </Col>
          </Row>
        ) : (
          <></>
        )}
        <Row className="mx-2 pt-2">
          <hr className="pb-0 mb-0" />
          <span
            className="text-muted text-start"
            style={{
              fontSize: "14px",
            }}
          >
            <Link to={`/inbox?search=${author.username}`}>
              {listing.number_of_reached_out_users - 1 || 0} messages
            </Link>{" "}
            &middot; {comments.length} comments{" "}
            {listing.confirmed_hosts?.length > 0 ? (
              <>
                &middot; Confirmed with{" "}
                <Link to={`/profile/${listing.confirmed_hosts[0].username}`}>
                  {listing.confirmed_hosts[0].name}
                </Link>
              </>
            ) : listing.closed ? (
              <>&middot; Request is closed</>
            ) : (
              <>&middot; Request is open</>
            )}
          </span>
        </Row>
        {(type === "listing" || type === "listing_on_profile") && (
          <>
            <Row className="mx-2 pt-0">
              {listing.confirmed_hosts?.length > 0 && (
                <span class="px-0 mb-2">
                  Confirmed with{" "}
                  <Link to={`/profile/${listing.confirmed_hosts[0].username}`}>
                    {listing.confirmed_hosts[0].name}
                  </Link>
                </span>
              )}

              <div className="m-0 p-0 p-0 text-start">
                {comments.map((commentData, index) => (
                  <CommentDetail key={index} commentData={commentData} />
                ))}
              </div>
            </Row>
            <Row className="mx-2 pt-0">
              <div className="m-0 p-0 pb-2 pt-1" ref={commentRef}>
                <InputGroup className="mb-0">
                  <Form.Control
                    as="input"
                    size="sm"
                    value={text}
                    onKeyUp={(e) => {
                      if (e.keyCode === 13) {
                        handleOnEnterComment(text);
                        setText("");
                      }
                    }}
                    onChange={(e) => {
                      setText(e.target.value);
                    }}
                    placeholder="Write a public comment..."
                    aria-label="Write a public comment..."
                    aria-describedby="basic-addon2"
                  />
                  <Button
                    variant="outline-secondary"
                    id="button-addon2"
                    onClick={() => {
                      handleOnEnterComment(text);
                      setText("");
                    }}
                  >
                    <Icon
                      icon="material-symbols:send-outline"
                      style={iconSize}
                    />
                  </Button>
                </InputGroup>
              </div>
            </Row>
          </>
        )}
      </div>
    </>
  );
}

function _getRequestTitle(request) {
  if (request.request_type === "playdate") {
    return "Looking for a playdate!";
  }
  const dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
    timeZone: request.timezone ?? "America/Los_Angeles",
  };

  return (
    new Date(request.start_date.toMillis()).toLocaleDateString(
      "en-us",
      dateOptions
    ) +
    " to " +
    new Date(request.end_date.toMillis()).toLocaleDateString(
      "en-us",
      dateOptions
    )
  );
}
