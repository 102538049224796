export const DEFAULT_PROFILE_PIC_URL =
  "https://firebasestorage.googleapis.com/v0/b/vacation-dog-swap.appspot.com/o/default_pics%2Fperson-profile.png?alt=media&token=29035ca5-724d-46b3-831f-058807e1382b";
export const DEFAULT_DOG_PROFILE_PIC_URL =
  "https://firebasestorage.googleapis.com/v0/b/vacation-dog-swap.appspot.com/o/default_pics%2Fdog-profile.png?alt=media&token=65e11472-c591-4e2b-87be-440efe360190";
export const ONBOARDING_PROGRESS_PIC_URL =
  "https://firebasestorage.googleapis.com/v0/b/vacation-dog-swap.appspot.com/o/default_pics%2Fonboarding_progress.png?alt=media&token=b29ca8cd-05c8-4c76-8268-887966d25262";
export const secretPass = "zjxi8x8wj390";
export const Platform = {
  IOS: "ios",
  ANDROID: "android",
  WEB: "web",
};
export const APP_STORE_URL =
  "https://apps.apple.com/us/app/woofyclub/id6449718000";
export const GOOGLE_PLAY_URL =
  "https://play.google.com/store/apps/details?id=com.woofyclub";
export const TERMS_OF_SERVICE_URL =
  "https://www.woofyclub.com/terms-of-service";
export const PRIVACY_POLICY_URL = "https://www.woofyclub.com/privacy-policy";

export const ERROR_MESSAGE_NOT_AUTHORIZED =
  "You are not authorized to access this page.";
