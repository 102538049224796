import {
  GoogleMap,
  InfoBox,
  Marker,
  MarkerClusterer,
} from "@react-google-maps/api";
import { getFunctions, httpsCallable } from "firebase/functions";
import { fbApp } from "libs/firebase";
import { Mixpanel } from "libs/mixpanel";
import React, { useEffect, useState } from "react";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { CurrentUser } from "../../libs/auth";
import MainNavColumn from "../MainNavColumn";
import ProfileCard from "../common/ProfileCard";

const containerStyle = {
  width: "100%",
  height: "600px",
};
const center = {
  lat: 39.8283,
  lng: -98.5794797,
};
const options = {
  imagePath:
    "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m", // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
};
const map_options = {
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: true,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: true,
  gestureHandling: "greedy",
};

const functions = getFunctions();
const functionsWest2 = getFunctions(fbApp, "us-west2");
const getProfilesCoordinate = httpsCallable(
  functionsWest2,
  "getProfilesCoordinate"
);
const getTopMetroUsers = httpsCallable(functions, "getTopMetroUsers");

export const ListProfilePage = (props) => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeMarker, setActiveMarker] = useState(center);
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);
  const [currentMarkerProfile, setCurrentMarkerProfile] = useState(
    CurrentUser()
  );
  const [listOrMap, setListOrMap] = useState("list");
  const [topMetro, setTopMetro] = useState([]);

  useEffect(() => {
    Mixpanel.track("visit_search_page");
    getTopMetroUsers().then((result) => {
      setTopMetro(result.data);
      setIsLoading(false);
    });
    getProfilesCoordinate().then((result) => {
      setUsers(result.data);
    });
  }, []);

  function onMarkerClick(marker, userData) {
    setCurrentMarkerProfile(userData);
    setActiveMarker({ lat: marker.latLng.lat(), lng: marker.latLng.lng() });
    setShowingInfoWindow(true);
  }

  function onInfoWindowClose() {
    setShowingInfoWindow(false);
  }

  // Infinite Scroll List function

  return (
    // HTML + JSX goes here
    <div>
      {!isLoading ? (
        <Container className="d-md-block my-2">
          <Row className="justify-content-md-center">
            <Col md="3" className="order-md-0 order-lg-0">
              <MainNavColumn profile={props.currentProfile} />
            </Col>
            <Col md="3" className="order-md-1 order-lg-2">
              <div className="sticky-top my-1"></div>
            </Col>
            <Col md="6" className="order-md-2 order-lg-1">
              <div className="d-grid gap-2">
                <ButtonGroup className="mt-2">
                  <ToggleButton
                    key="list"
                    id="list-toggle"
                    type="radio"
                    variant="outline-secondary"
                    name="radio"
                    value="list"
                    checked={listOrMap === "list"}
                    onChange={(e) => setListOrMap(e.currentTarget.value)}
                  >
                    List
                  </ToggleButton>
                  <ToggleButton
                    key="map"
                    id="map-toggle"
                    type="radio"
                    variant="outline-secondary"
                    name="radio"
                    value="map"
                    checked={listOrMap === "map"}
                    onChange={(e) => setListOrMap(e.currentTarget.value)}
                  >
                    Map
                  </ToggleButton>
                </ButtonGroup>
              </div>
              {listOrMap === "list" && (
                <div className="mt-3">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Rank</th>
                        <th>Metro Name</th>
                        <th>Total Users</th>
                      </tr>
                    </thead>
                    <tbody>
                      {topMetro
                        .filter(
                          (metro) => metro.metro_name && metro.total_users > 0
                        )
                        .map((metro, index) => (
                          <tr key={metro.metro_name}>
                            <td>{index + 1}</td>
                            <td>{metro.metro_name}</td>
                            <td>{metro.total_users}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
              {listOrMap === "map" && (
                <div className="mt-3">
                  <GoogleMap
                    id="marker-example"
                    mapContainerStyle={containerStyle}
                    zoom={4}
                    center={center}
                    options={map_options}
                  >
                    <MarkerClusterer options={options}>
                      {(clusterer) =>
                        users.map((userData) => (
                          <Marker
                            key={userData.document_id}
                            position={{
                              lat: parseFloat(userData.latitude),
                              lng: parseFloat(userData.longitude),
                            }}
                            clusterer={clusterer}
                            clickable={true}
                            onClick={(marker) =>
                              onMarkerClick(marker, userData)
                            }
                          />
                        ))
                      }
                    </MarkerClusterer>
                    {showingInfoWindow && (
                      <InfoBox
                        position={activeMarker}
                        options={{
                          enableEventPropagation: false,
                          disableAutoPan: false,
                        }}
                        style={{ background: "white" }}
                      >
                        <div
                          style={{
                            opacity: 0.95,
                            fontSize: "14px",
                            margin: "0",
                            background: "white",
                            borderRadius: "5px",
                            padding: "3px",
                            maxWidth: "600px",
                            width: "90%",
                          }}
                        >
                          <Button
                            variant="danger"
                            onClick={onInfoWindowClose}
                            style={{
                              display: "block",
                              position: "absolute",
                              right: "12%",
                              top: "8px",
                              zIndex: "100",
                            }}
                          >
                            Close X
                          </Button>
                          <ProfileCard
                            user={currentMarkerProfile}
                            isOwnProfile={false}
                            profileUid={props.currentProfile.uid}
                          />
                        </div>
                      </InfoBox>
                    )}
                  </GoogleMap>
                  <div>
                    <p className="text-end" style={{ fontSize: "10px" }}>
                      Location markers shown are not exact for privacy reasons
                    </p>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      ) : (
        <Spinner animation="border" role="status" size="lg">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
    </div>
  );
};

export default ListProfilePage;
