import React from "react";
import Row from "react-bootstrap/Row";
import OnboardingControlBar from "./OnboardingControlBar";

export default function OnboardingControlSection(props) {
  return (
    <>
      <Row
        className="d-block d-md-none py-3 fixed-bottom"
        style={{
          background: "white",
          paddingLeft: "40px",
          paddingRight: "40px",
          borderTop: "1px solid #e6e6e6",
        }}
      >
        <OnboardingControlBar
          onNext={props.onNext}
          onFinish={props.onFinish}
          onPrevious={props.onPrevious}
          errorMessage={props.errorMessage}
          loading={props.loading}
        />
      </Row>
      <Row
        className="d-none d-md-block mx-3 mb-4"
        style={{ marginTop: "50px" }}
      >
        <OnboardingControlBar
          onNext={props.onNext}
          onFinish={props.onFinish}
          onPrevious={props.onPrevious}
          errorMessage={props.errorMessage}
          loading={props.loading}
        />
      </Row>
    </>
  );
}
