import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProfilePic from "./common/ProfilePic";
import { DEFAULT_PROFILE_PIC_URL } from "../libs/constants";
import { StarFill } from "react-bootstrap-icons";

export default function ReviewSnippet(props) {
  const review = props.review;
  const review_date = new Date(review.created_at.toDate());

  return (
    <div className="card m-1">
      <div className="card-body text-start">
        <Row>
          <Col md="2">
            <ProfilePic
              url={review.reviewer.profile_pic_url ?? DEFAULT_PROFILE_PIC_URL}
              size="60"
            />
          </Col>
          <Col md="10">
            <p>
              <a
                href={"/profile/" + review.reviewer.username}
                target="_blank"
                className="link-dark"
                rel="noreferrer"
              >
                {review.reviewer.name}
              </a>
              <br />
              {review_date.toDateString()}
            </p>
          </Col>
        </Row>
        <Row className="py-2">
          {review.photo_urls &&
            review.photo_urls.map((imageUrl) => (
              <Col md="3">
                <img className="img-thumbnail m-1" src={imageUrl} />
              </Col>
            ))}
        </Row>
        <Row>
          <Col md="12" className="pt-m-4">
            {/* Owner's Profile Pic */}
            <Row className="d-flex justify-content-center">
              <p className="pt-0 pb-1 m-0">{review.text}</p>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
}
