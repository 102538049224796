import { Icon } from "@iconify/react";
import { Circle, GoogleMap, Marker } from "@react-google-maps/api";
import MembershipBadge from "components/common/MembershipBadge";
import CryptoJS from "crypto-js";
import {
  collection,
  getDocs,
  getFirestore,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Mixpanel } from "libs/mixpanel";
import moment from "moment-timezone";
import React, { Fragment, useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { isMobile } from "react-device-detect";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { CurrentUser, Logout } from "../../libs/auth";
import { DEFAULT_PROFILE_PIC_URL, secretPass } from "../../libs/constants";
import { DistanceBetween } from "../../libs/DistanceBetween";
import WCButton from "../atom/WCButton";
import ContactButton from "../common/ContactButton";
import ListingPostCard from "../common/ListingPostCard";
import PhotoBrowser from "../common/PhotoBrowser";
import ProfilePic from "../common/ProfilePic";
import ReviewButton from "../common/ReviewButton";
import ShareButton from "../common/ShareButton";
import MainNavColumn from "../MainNavColumn";
import ReviewSnippet from "../ReviewSnippet";
import GuestDogProfile from "./GuestDogProfile";
import { getLocationFromAddress } from "libs/profile";

const buttonStyle = {
  borderStyle: "solid",
  borderWidth: "1px",
  borderRadius: "5px",
  margin: "10px 5px 5px 0px",
};

const nameStyle = {
  marginBottom: "0px",
  paddingBottom: "0px",
};
let containerStyle = {
  width: "100%",
  height: "240px",
  borderRadius: "8px",
};
const center = {
  lat: 37.691232053811156,
  lng: -122.15420534982788,
};
const map_options = {
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: true,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false,
  gestureHandling: "greedy",
};

const circle_options = {
  strokeColor: "#FF0000",
  strokeOpacity: 0.8,
  strokeWeight: 0,
  fillColor: "#FF0000",
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 2000,
  zIndex: 1,
};

const introTextStyle = {
  whiteSpace: "pre-wrap",
};

const iconSize = { fontSize: "24px" };

function canMessage(senderMembershipTier, recipientMembershipTier) {
  return (senderMembershipTier ? 1 : 0) + (recipientMembershipTier ? 1 : 0) > 0;
}

export default function GuestProfilePage(props) {
  const [searchParams, _] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [profile, setProfile] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [dogPhotos, setDogPhotos] = useState([]);
  const [mapCenter, setMapCenter] = useState(center);
  const [listings, setListings] = useState([]);
  const [isReviewOpen, setIsReviewOpen] = useState(false);
  const [isMessageOpen, setIsMessageOpen] = useState(false);
  const [messageDecrypted, setMessageDecrypted] = useState("");
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [canMsg, setCanMsg] = useState(false);
  const [replyToMessage, setReplyToMessage] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteCountdown, setDeleteCountdown] = useState(0);
  const [deleteForReal, setDeleteForReal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const currentUser = CurrentUser();
  let params = useParams();
  const isSelfProfile = currentUser && currentUser?.uid === profile?.uid;
  const navigate = useNavigate();

  useEffect(() => {
    let timer;
    if (deleteCountdown > 0) {
      timer = setTimeout(() => setDeleteCountdown(deleteCountdown - 1), 1000);
    } else if (deleteCountdown === 0 && deleteForReal) {
      setDeleteForReal(true);
    }
    return () => clearTimeout(timer);
  }, [deleteCountdown, deleteForReal]);

  const handleDeleteClick = () => {
    if (!deleteForReal) {
      setDeleteCountdown(5);
      setDeleteForReal(true);
    } else {
      setIsDeleting(true);
      handleDelete();
    }
  };

  const resetDeleteState = () => {
    setDeleteCountdown(0);
    setDeleteForReal(false);
  };

  const onLogout = (event) => {
    event.preventDefault();
    Logout();
  };

  useEffect(() => {
    if (params == null || params.username == null) {
      return;
    }

    if (searchParams) {
      searchParams.forEach((value, key) => {
        if (key === "review_modal" && value === "true") {
          setIsReviewOpen(true);
        }
        if (key === "message_modal" && value === "true") {
          setIsMessageOpen(true);
        }
      });
    }

    const username = params.username.toLowerCase();
    const firestore = getFirestore();
    const q = query(
      collection(firestore, "users"),
      where("username", "==", username)
    );
    getDocs(q)
      .then((querySnapshot) => {
        let profileDoc = null;
        let allDogPhotos = [];
        querySnapshot.forEach((doc) => {
          profileDoc = doc.data();
          profileDoc.uid = doc.id;
          Mixpanel.track("view_profile", {
            viewer_uid: currentUser?.uid,
            viewer_username: props.currentUserProfile?.username,
            profile_uid: profileDoc.uid,
            profile_username: username,
          });
          setProfile(profileDoc);
          profileDoc.dogs.forEach((dog) => {
            if (dog.profile_pic_url) {
              allDogPhotos.push(dog.profile_pic_url);
            }
            if (dog.image_urls) {
              dog.image_urls.forEach((image_url) => {
                allDogPhotos.push(image_url);
              });
            }
          });
          console.log(profileDoc);
          setDogPhotos(allDogPhotos);
          return;
        });
        if (profileDoc?.address?.latitude && profileDoc?.address?.longitude) {
          setMapCenter({
            lat: profileDoc.address.latitude + Math.random() * 0.02,
            lng: profileDoc.address.longitude + Math.random() * 0.02,
          });
        }
        setIsLoading(false);
        setCanMsg(
          canMessage(
            profileDoc.membership_tier,
            props.currentUserProfile.membership_tier
          )
        );
        return profileDoc;
      })
      .then((profileDoc) => {
        if (profileDoc) {
          const q3 = query(
            collection(firestore, "reviews"),
            where("reviewee_id", "==", profileDoc.uid),
            orderBy("created_at", "desc")
          );
          getDocs(q3).then((querySnapshot) => {
            const reviews = [];
            querySnapshot.forEach((doc) => {
              console.log(doc);
              let reviewData = doc.data();
              reviewData.uid = doc.id;
              if (reviewData) {
                reviews.push(reviewData);
              }
            });
            setReviews(reviews);
          });

          // Get listings and add it to the top of the page
          getDocs(
            query(
              collection(firestore, "requests"),
              where("author_id", "==", profileDoc.uid),
              orderBy("start_date", "desc")
            )
          ).then((querySnapshot) => {
            const queryListings = [];
            querySnapshot.forEach((doc) => {
              let listingData = doc.data();
              listingData.uid = doc.id;
              queryListings.push(listingData);
            });
            setListings(queryListings);
            if (queryListings.length > 0) {
              setReplyToMessage(true);
            }

            if (params["*"] != null) {
              const bytes = CryptoJS.AES.decrypt(
                decodeURI(params["*"]),
                secretPass
              );
              const data = bytes.toString(CryptoJS.enc.Utf8);
              setMessageDecrypted(data);
              setShowMessageModal(true);
            }
          });
        }
      });
  }, [params]);

  if (isLoading) {
    return (
      <Container className="py-3">
        <Spinner animation="border" role="status" size="lg">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  if (!profile) {
    return (
      <Container className="py-3">
        <Alert variant="danger">
          The profile you&apos;re looking for doesn&apos;t exist. If this is
          your own profile you&apos;re trying to view, please login and fill out
          all the required profile form fields to enable your public profile
          link.
        </Alert>
      </Container>
    );
  }

  const countryLongName =
    profile.address?.address_components?.country?.long_name;
  const profilePicUrl = profile.profile_pic_url || DEFAULT_PROFILE_PIC_URL;
  const nightsHosted = profile.nightsHosted || 0;
  const nightsBoarded = profile.nightsBoarded || 0;

  let spaceDescription = "Lives in ";
  if (profile.space_type === "house") {
    spaceDescription += "a house";
  } else if (profile.space_type === "apartment") {
    spaceDescription += "an apartment";
  } else if (profile.space_type === "farm") {
    spaceDescription += "a farm";
  }
  if (profile.yard_type === "fenced") {
    spaceDescription += " with a fenced yard";
  } else if (profile.yard_type === "unfenced") {
    spaceDescription += " with an unfenced yard";
  }

  let sheddingDesc;
  if (profile.pref_shedding_type === "none") {
    sheddingDesc = "Prefer hypoallergenic dogs";
  } else if (profile.pref_shedding_type === "minimal") {
    sheddingDesc = "Prefer minimal shedding dogs";
  } else if (profile.pref_shedding_type === "medium") {
    sheddingDesc = "Okay with medium shedding dogs";
  } else if (profile.pref_shedding_type === "heavy") {
    sheddingDesc = "Okay with heavy shedding dogs";
  }

  const showHomeSection =
    profile.space_type ||
    profile.smoking_inside_home ||
    profile.children_0_5 ||
    profile.children_6_12 ||
    profile.cats_in_home ||
    profile.dogs_allowed_on_bed ||
    profile.dogs_allowed_on_furniture ||
    profile.dogs_might_visit_dog_park ||
    profile.dogs_might_meet_other_dogs_in_building;

  const showHostingPrefSection =
    profile.pref_pet_schedule ||
    profile.pref_pet_size ||
    sheddingDesc ||
    profile.pref_number_of_dogs;

  let profilePicSize;
  const windowWidth = window.innerWidth;
  if (windowWidth < 576) {
    profilePicSize = 100;
  } else if (windowWidth >= 576 && windowWidth < 1000) {
    profilePicSize = 130;
  } else {
    profilePicSize = 150;
    containerStyle = {
      ...containerStyle,
      ...{ height: "480px" },
    };
  }

  const handleDelete = async () => {
    try {
      const functions = getFunctions();
      const deleteProfile = httpsCallable(functions, "deleteProfile");

      console.log("Deleting profile:", profile.uid);
      const result = await deleteProfile({ uid: profile.uid });

      if (result.data.success) {
        setShowDeleteModal(false);
      } else {
        throw new Error(result.data.error || "Failed to delete profile");
      }
    } catch (error) {
      console.error("Error deleting profile:", error);
      // You might want to show an error message to the user here
    } finally {
      setIsDeleting(false);
    }
  };

  const actionBar = (
    <Row className="mt-2">
      <Col sm="12">
        {currentUser?.uid === profile?.uid ? (
          <>
            <Button
              size="sm"
              variant="outline-secondary"
              className="me-2"
              href="/profile/"
            >
              Edit Profile
            </Button>
            <Button size="sm" variant="outline-secondary" onClick={onLogout}>
              Logout
            </Button>
          </>
        ) : null}
        <Button
          size="sm"
          variant="outline-secondary"
          className="me-2"
          href={`/inbox?search=${profile?.username}`}
        >
          View inbox
        </Button>
        <Button
          size="sm"
          variant="outline-secondary"
          className="me-2"
          onClick={() => {
            window.open(
              `https://console.firebase.google.com/project/vacation-dog-swap/firestore/databases/-default-/data/~2Fusers~2F${profile?.uid}`
            );
          }}
        >
          Firebase
        </Button>
        <Button
          size="sm"
          variant="outline-secondary"
          className="me-2"
          onClick={() => {
            window.open(
              `https://app.revenuecat.com/customers/95ce82fa/${profile?.uid}`
            );
          }}
        >
          RevenueCat
        </Button>
        <Button
          size="sm"
          variant="danger"
          onClick={() => setShowDeleteModal(true)}
        >
          Delete Profile
        </Button>
      </Col>
    </Row>
  );

  const mainContentDiv = (
    <div>
      <Row className="pull-right">
        <Col className="col-auto">
          {/* Owner's Profile Pic */}
          <ProfilePic url={profilePicUrl} size={profilePicSize} />
        </Col>
        <Col className="text-start">
          {isMobile ? <div className="mt-1" /> : <div className="mt-3" />}
          <div
            className="d-flex flex-row align-items-center"
            style={{ columnGap: 4 }}
          >
            <h2 className="abril" style={nameStyle}>
              {profile.name}&nbsp;{" "}
              {profile.banned && (
                <span className="badge bg-danger">Banned</span>
              )}
            </h2>
            <ShareButton profileToShare={profile} />
            {profile?.membership_tier ? <MembershipBadge size="lg" /> : null}
          </div>
          {getLocationFromAddress(profile.address) && (
            <div className="">
              <p className="text-muted mb-0">
                {props.currentUserProfile?.address?.latitude &&
                  props.currentUserProfile?.address?.longitude &&
                  profile?.address?.latitude &&
                  profile?.address?.longitude && (
                    <>
                      {DistanceBetween(
                        props.currentUserProfile.address.latitude,
                        props.currentUserProfile.address.longitude,
                        profile.address.latitude,
                        profile.address.longitude
                      )}
                      mi&nbsp;&#183;&nbsp;
                    </>
                  )}
                {getLocationFromAddress(profile.address)}
              </p>
            </div>
          )}
          <div>
            <p className="text-muted mb-0">{profile?.uid}</p>
            <p className="text-muted mb-0">
              Created on:{" "}
              {moment(profile?.created_at)
                .tz("America/Los_Angeles")
                .format("dddd, MMMM Do YYYY")}
            </p>
          </div>
          <div>
            {nightsHosted > 0 ? (
              <button type="button" style={buttonStyle}>
                <b>
                  {nightsHosted} {nightsHosted > 1 ? "nights" : "night"} hosted
                </b>
              </button>
            ) : null}
            {nightsBoarded > 0 ? (
              <button type="button" style={buttonStyle}>
                <b>
                  {nightsBoarded} {nightsBoarded > 1 ? "nights" : "night"}{" "}
                  boarded
                </b>
              </button>
            ) : null}
          </div>
        </Col>
      </Row>
      {actionBar}
      <Row className="rounded mt-2 pb-4" style={{ backgroundColor: "white" }}>
        <Col md="12" className="g-0">
          <PhotoBrowser image_urls={dogPhotos} />
        </Col>
        <Col md="12" className="justify-content-start text-start mt-0">
          {profile.intro_text && (
            <>
              <h4 className="mt-4 mb-3">Introduction</h4>
              <div className="mb-4" style={introTextStyle}>
                {profile.intro_text}
              </div>
              <hr />
            </>
          )}
          {/* About your dog */}
          <ul className="p-0 list-unstyled mt-4">
            {profile.dogs.map((dog, index) => {
              return (
                <li key={index}>
                  <GuestDogProfile dog={dog} />
                </li>
              );
            })}
          </ul>
          {showHomeSection && (
            <div>
              <h4 className="mb-3">Home</h4>
              <div className="mb-3 text-start">
                {spaceDescription && (
                  <p>
                    <b>
                      <Icon icon="bi:house" style={iconSize} />
                      &nbsp;
                    </b>
                    {spaceDescription}
                  </p>
                )}
                {profile.smoking_inside_home && (
                  <p>
                    <b>
                      <Icon icon="cil:smoke" style={iconSize} />
                      &nbsp;
                    </b>
                    Smoking inside home
                  </p>
                )}
                {profile.children_0_5 && (
                  <p>
                    <b>
                      <Icon icon="cil:baby" style={iconSize} />
                      &nbsp;
                    </b>
                    Children age 0-5
                  </p>
                )}
                {profile.children_6_12 && (
                  <p>
                    <b>
                      <Icon icon="ic:outline-child-care" style={iconSize} />
                      &nbsp;
                    </b>
                    Children age 6-12
                  </p>
                )}
                {profile.cats_in_home && (
                  <p>
                    <b>
                      <Icon
                        icon="fluent:animal-cat-16-regular"
                        style={iconSize}
                      />
                      &nbsp;
                    </b>
                    Cats in home
                  </p>
                )}
                {profile.dogs_allowed_on_bed && (
                  <p>
                    <b>
                      <Icon icon="bx:bed" style={iconSize} />
                      &nbsp;
                    </b>
                    Dogs are allowed on bed
                  </p>
                )}
                {profile.dogs_allowed_on_furniture && (
                  <p>
                    <b>
                      <Icon icon="cil:sofa" style={iconSize} />
                      &nbsp;
                    </b>
                    Dogs are allowed on furniture
                  </p>
                )}
                {profile.dogs_might_visit_dog_park && (
                  <p>
                    <b>
                      <Icon icon="ic:outline-park" style={iconSize} />
                      &nbsp;
                    </b>
                    Dogs might visit a dog park close by
                  </p>
                )}
                {profile.dogs_might_meet_other_dogs_in_building && (
                  <p>
                    <b>
                      <Icon icon="cil:building" style={iconSize} />
                      &nbsp;
                    </b>
                    Dogs might meet other dogs in the building
                  </p>
                )}
              </div>
              <hr className="mt-4 mb-4" />
            </div>
          )}

          {showHostingPrefSection && (
            <div>
              <h4 className="mb-3">Hosting Preferences</h4>
              <div className="mb-3 text-start">
                {profile.pref_pet_size && (
                  <p>
                    <b>
                      <Icon icon="ph:scales-light" style={iconSize} />
                      &nbsp;
                    </b>
                    Comfortable with pets smaller than {
                      profile.pref_pet_size
                    }{" "}
                    lbs
                  </p>
                )}
                {sheddingDesc && (
                  <p>
                    <b>
                      <Icon icon="icon-park:hair-brush" style={iconSize} />
                      &nbsp;
                    </b>
                    {sheddingDesc}
                  </p>
                )}
                {profile.pref_number_of_dogs && (
                  <p>
                    <b>
                      <Icon icon="bi:dice-2" style={iconSize} />
                      &nbsp;
                    </b>
                    Comfortable hosting up to {profile.pref_number_of_dogs} dogs
                    at a time
                  </p>
                )}
                {profile.pref_pet_schedule && (
                  <p>
                    <b>
                      <Icon icon="akar-icons:schedule" style={iconSize} />
                      &nbsp;
                    </b>
                    {profile.pref_pet_schedule}{" "}
                  </p>
                )}
              </div>
              <hr className="my-4" />
            </div>
          )}

          {profile.pref_additional && (
            <div>
              <h4 className="mb-3">Additional Information</h4>
              <p>
                <b>
                  <Icon icon="akar-icons:chat-add" style={iconSize} />
                  &nbsp;
                </b>
                {profile.pref_additional}{" "}
              </p>
              <hr className="my-4" />
            </div>
          )}

          <h4 className="mb-3">Reviews</h4>
          {reviews
            .sort((a, b) => b.created_at.toMillis() - a.created_at.toMillis())
            .map((review) => {
              return <ReviewSnippet review={review} />;
            })}
          <hr className="my-4" />

          <h4 className="mb-3">
            {profile.first_name || profile.name}&apos;s Neighborhood
          </h4>
          <GoogleMap
            id="marker-example"
            mapContainerStyle={containerStyle}
            zoom={10}
            center={mapCenter}
            options={map_options}
          >
            <Marker position={mapCenter} />
            <Circle center={mapCenter} options={circle_options} />
          </GoogleMap>
          <p className="text-end" style={{ fontSize: "10px" }}>
            Location markers shown are not exact for privacy reasons
          </p>
          <b style={{ fontSize: "16px" }}>
            {props.currentUserProfile?.address?.latitude &&
              props.currentUserProfile?.address?.longitude &&
              profile?.address?.latitude &&
              profile?.address?.longitude && (
                <>
                  {DistanceBetween(
                    props.currentUserProfile.address.latitude,
                    props.currentUserProfile.address.longitude,
                    profile.address.latitude,
                    profile.address.longitude
                  )}
                  mi&nbsp;&#183;&nbsp;
                </>
              )}
            {getLocationFromAddress(profile.address, true) +
              ", " +
              countryLongName}
          </b>
        </Col>
      </Row>

      {listings.length ? (
        <Row className="text-start mt-2">
          <Col md="12" className="g-0">
            <h4>All Requests</h4>
            {listings.length && (
              <>
                {listings.map((listingData, index) => (
                  <Fragment key={index}>
                    <ListingPostCard
                      type="listing_on_profile"
                      listing={listingData}
                      loggedInProfile={props.currentUserProfile}
                      hideHeader={true}
                    />
                    <div className="mb-1" />
                  </Fragment>
                ))}
              </>
            )}
          </Col>
        </Row>
      ) : (
        <></>
      )}

      <Modal
        show={showMessageModal}
        fullscreen="sm-down"
        onHide={() => setShowMessageModal(false)}
        style={{ zIndex: "11000" }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Message from {profile.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {canMsg ? (
            <div className="text-start">{messageDecrypted}</div>
          ) : (
            <div className="text-start">
              <p className="blurry-text">{messageDecrypted}</p>
              <p className="">
                Unfortunately, you can only receive messages once you&apos;ve
                upgraded to be a premium member. Start your 2-week free trial
                today to view this message.
              </p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={() => setShowMessageModal(false)}
            className="mx-2"
          >
            Close
          </Button>
          {canMsg ? (
            <></>
          ) : (
            <WCButton
              variant="primary"
              className="red-background"
              href="/premium?source=message"
            >
              Start Trial
            </WCButton>
          )}
        </Modal.Footer>
      </Modal>

      <Modal
        show={showDeleteModal}
        onHide={() => {
          setShowDeleteModal(false);
          resetDeleteState();
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="w-100 text-center">
            Confirm Delete
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          Are you sure you want to delete this profile? This action cannot be
          undone.
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="secondary"
            onClick={() => {
              setShowDeleteModal(false);
              resetDeleteState();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={handleDeleteClick}
            disabled={deleteCountdown > 0 || isDeleting}
          >
            {deleteForReal
              ? deleteCountdown > 0
                ? `Delete in ${deleteCountdown}`
                : isDeleting
                ? "Deleting..."
                : "Delete FOR REAL"
              : "Delete"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );

  const contactDiv =
    currentUser?.uid !== profile?.uid ? (
      <div>
        <div className="d-xs-block d-sm-block d-md-none">
          <div className="fixed-bottom" style={{ marginBottom: "49px" }}>
            <nav className="navbar navbar-light bg-none border-top border-1">
              <div className="container">
                <div className="text-start">
                  <b>{profile.name}</b>
                  <br />
                  {getLocationFromAddress(profile.address)}
                </div>
                <ContactButton
                  profile={profile}
                  profileUid={profile.uid}
                  currentProfile={props.currentUserProfile}
                  messageModal={isMessageOpen}
                  modalMode={true}
                  replyToMessage={replyToMessage}
                />
              </div>
            </nav>
          </div>
        </div>
        <div className="d-none d-md-block">
          <ContactButton
            profile={profile}
            profileUid={profile.uid}
            currentProfile={props.currentUserProfile}
            modalMode={false}
            replyToMessage={replyToMessage}
          />
        </div>
      </div>
    ) : null;

  return (
    // HTML + JSX goes here
    <div>
      <Container className="py-3 d-none d-md-block">
        <Row className="justify-content-md-start">
          <Col lg="3">
            <MainNavColumn profile={props.currentUserProfile} />
          </Col>
          <Col md="6">
            <div className="">{mainContentDiv}</div>
          </Col>
          <Col md="3">
            {currentUser?.uid !== profile?.uid && (
              <div className="sticky-top pt-4">
                <div className="card shadow-sm">
                  <div className="card-body my-2">{contactDiv}</div>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
      <Container className="p-4 d-md-none">
        <Row className="justify-content-md-center">
          <Col md="12">
            <MainNavColumn profile={props.currentUserProfile} />
            <div className="">{mainContentDiv}</div>
            <Container className="">{contactDiv}</Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
