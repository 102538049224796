import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LoginForm from "./LoginForm";
import SignupForm from "./SignupForm";
import { Mixpanel } from "libs/mixpanel";

export default function LoginPage(props) {
  useEffect(() => {
    Mixpanel.track("visit_login_page");
  }, []);

  const [showSignupForm, setShowSignupForm] = useState(props.signup || false);

  return (
    <div className="h-100" style={{ backgroundColor: "white" }}>
      <Container>
        <Row className="justify-content-center">
          <Col xs="9" md="6" lg="4" className="my-5">
            {!showSignupForm && (
              <LoginForm
                createAccountHandler={() => {
                  setShowSignupForm(true);
                }}
              />
            )}
            {showSignupForm && (
              <SignupForm
                signInHandler={() => {
                  setShowSignupForm(false);
                }}
              />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
