import React, { useState } from "react";
import useStorage from "../hooks/useStorage";
import { v4 as uuidv4 } from "uuid";

export default function ImageUploadForm(props) {
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(null);
  useStorage(
    file,
    (image) => {
      if (image?.uri && props.onImageUrlCreated) {
        props.onImageUrlCreated(image.uri);
      }
    },
    (progress) => setUploadProgress(progress),
  );
  const uploadFormId = uuidv4();

  const onChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
    }
  };

  return (
    <form>
      <label htmlFor={uploadFormId}>
        <a className="btn btn-outline-dark">
          {props.text ? props.text : "Upload Image"}
        </a>
      </label>
      <input
        id={uploadFormId}
        type="file"
        accept=".HEIC, .jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
        onChange={onChange}
        style={{ display: "none" }}
      />
      {uploadProgress != null && uploadProgress < 100 && (
        <span> Uploading {uploadProgress}%</span>
      )}
    </form>
  );
}
