import MembershipBadge from "components/common/MembershipBadge";
import { getProfilePictureUrl } from "libs/images";
import { Mixpanel } from "libs/mixpanel";
import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import { DEFAULT_DOG_PROFILE_PIC_URL } from "../../libs/constants";
import { getDogBreedLabelFromValue } from "../../libs/dogBreeds";
import { ProfileCompletion } from "../../libs/profileCompletion";
import WCButton from "../atom/WCButton";
import ProfileCompletionCard from "./ProfileCompletionCard";
import ProfilePic from "./ProfilePic";
import TextExpand from "./TextExpand";
import { getLocationFromAddress } from "libs/profile";

export default function ProfileCard({
  user,
  listingData,
  profileUid,
  isOwnProfile,
}) {
  let cardClass = "card";
  if (!user) {
    return null;
  }
  if (isOwnProfile) {
    cardClass = "card m-1 bg-light";
  }
  let profileProgress = "";

  if (isOwnProfile) {
    profileProgress = "" + ProfileCompletion(user) + "%";
  }

  const location = getLocationFromAddress(user.address, false) || "";

  const onSeeProfileClicked = () => {
    Mixpanel.track("see_profile_button_clicked", {
      profile_username: user.username,
      profile_uid: profileUid,
    });
  };
  return (
    <div className={cardClass}>
      <div className="card-body">
        {isOwnProfile && <h3 className="p-2">Your Profile Preview</h3>}
        <Row>
          <Col md="4" className="pt-m-4">
            {/* Owner's Profile Pic */}
            <Row>
              <ProfilePic url={getProfilePictureUrl(user.profile_pic_url)} />
              <div
                className="d-flex flex-row justify-content-center align-items-center"
                style={{ columnGap: 4 }}
              >
                <h2 className="p-0 m-0">
                  <span>{user.name}</span>
                </h2>
                {user?.membership_tier ? <MembershipBadge size="lg" /> : null}
              </div>
              <p className="pt-0 pb-1 m-0">{location}</p>
            </Row>
            <Row className="d-flex justify-content-center">
              <div className="text-center">
                {isOwnProfile ? (
                  <WCButton variant="primary" className="my-1" href="/profile">
                    Edit Profile
                  </WCButton>
                ) : (
                  <></>
                )}
                &nbsp;
                <Link to={"/profile/".concat(user.username)}>
                  <WCButton
                    variant={isOwnProfile ? "outline-secondary" : "primary"}
                    className="my-1"
                    target="_blank"
                    onClick={onSeeProfileClicked}
                  >
                    View Profile
                  </WCButton>
                </Link>
              </div>
            </Row>
            {isOwnProfile && <ProfileCompletionCard user={user} />}
          </Col>
          <Col md="8">
            {listingData ? (
              <Row className="text-start mb-2">
                <Col md="12">
                  <ul className="list-group">
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">
                          {_getRequestTitle(listingData)}
                        </div>
                        {listingData.message}
                      </div>
                    </li>
                  </ul>
                </Col>
              </Row>
            ) : null}
            <Row>
              {user.dogs.map((dog) => {
                let breedDesc;
                if (dog.breed) {
                  if (dog.breed === "other" && dog.breed_custom) {
                    breedDesc = dog.breed_custom;
                  } else {
                    breedDesc = getDogBreedLabelFromValue(dog.breed);
                  }
                }

                let dogProfilePic = DEFAULT_DOG_PROFILE_PIC_URL;
                if (dog.profile_pic_url) {
                  dogProfilePic = dog.profile_pic_url;
                } else if (dog.image_urls && dog.image_urls.length > 0) {
                  dogProfilePic = dog.image_urls[0];
                }

                return (
                  <Col md="12">
                    <div className={cardClass}>
                      <div className="card-body">
                        <Row>
                          <Col md="4">
                            <ProfilePic url={dogProfilePic} size={100} />
                          </Col>
                          <Col md="8">
                            <h3 className="">{dog.name}</h3>
                            {breedDesc && <p>&#128054; &nbsp;{breedDesc}</p>}
                            <p>&#128054; &nbsp;{dog.weight}lbs</p>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
            <Row>
              <div className="text-start">
                <TextExpand text={user.intro_text} />
              </div>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
}

function _getRequestTitle(request) {
  if (request.request_type === "playdate") {
    return "Looking for a playdate!";
  }
  const dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
    timeZone: request.timezone ?? "America/Los_Angeles",
  };

  return (
    new Date(request.start_date.toMillis()).toLocaleDateString(
      "en-us",
      dateOptions
    ) +
    " to " +
    new Date(request.end_date.toMillis()).toLocaleDateString(
      "en-us",
      dateOptions
    )
  );
}
