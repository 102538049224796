import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";
import WCButton from "components/atom/WCButton";
import * as Sentry from "@sentry/react";
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  Timestamp,
  where,
} from "firebase/firestore";
import { Mixpanel } from "libs/mixpanel";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CreateDisplayName } from "libs/profile";
import OnboardingAboutYouForm from "./OnboardingAboutYouForm";
import OnboardingProfilePicForm from "./OnboardingProfilePicForm";
import OnboardingAboutYourDogForm from "./OnboardingAboutYourDogForm";
import OnboardingDogPhotosForm from "./OnboardingDogPhotosForm";
import OnboardingIntroForm from "./OnboardingIntroForm";
import OnboardingAboutYourHomeForm from "./OnboardingAboutYourHome";

export default function OnboardingPage(props) {
  const currentUser = props.user;
  if (!currentUser?.uid) {
    throw new Error("OnboardingPage: User is not logged in");
  }

  const firestore = getFirestore();
  const navigate = useNavigate();

  const [profile, setProfile] = useState({
    dogs: [{}],
  });
  const [searchParams] = useSearchParams();

  const referralCode = searchParams.get("ac");
  const gclid = searchParams.get("gclid");
  const fbclid = searchParams.get("fbclid");
  const utmSource = searchParams.get("utm_source");
  const utmMedium = searchParams.get("utm_medium");
  const utmCampaign = searchParams.get("utm_campaign");
  const referralSource = getReferralSource(referralCode, gclid, utmSource);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const onNext = (data) => {
    window.scrollTo(0, 0);

    const newProfile = { ...profile, ...data };
    setProfile(newProfile);

    if (formIndex < forms.length - 1) {
      setFormIndex(formIndex + 1);
    } else if (formIndex === forms.length - 1) {
      submit(newProfile);
    } else {
      throw new Error("OnboardingPage: Invalid form index");
    }
  };

  const onPrevious = () => {
    window.scrollTo(0, 0);

    if (formIndex > 0) {
      setFormIndex(formIndex - 1);
    }
  };

  const [formIndex, setFormIndex] = useState(0);
  const forms = [
    <OnboardingAboutYouForm profile={profile} onNext={onNext} />,
    <OnboardingProfilePicForm
      profile={profile}
      onNext={onNext}
      onPrevious={onPrevious}
    />,
    <OnboardingAboutYourHomeForm
      profile={profile}
      onNext={onNext}
      onPrevious={onPrevious}
    />,
    <OnboardingAboutYourDogForm
      profile={profile}
      onNext={onNext}
      onPrevious={onPrevious}
    />,
    <OnboardingDogPhotosForm
      profile={profile.dogs?.length > 0 ? profile.dogs[0] : null}
      onNext={onNext}
      onPrevious={onPrevious}
    />,
    <OnboardingIntroForm
      profile={profile}
      referrer={searchParams.get("referrer")}
      onNext={onNext}
      onPrevious={onPrevious}
    />,
  ];

  useEffect(() => {
    Mixpanel.track("visit_onboarding_flow");

    window.onpopstate = (e) => {
      // On back button click, stay on the same page
      navigate("#");
    };
  }, []);

  const submit = async (profile) => {
    try {
      const name = CreateDisplayName(profile.first_name, profile.last_name);
      const username = await generateUsername(name);
      const currentDate = new Date();
      const finalProfile = {
        ...profile,
        name: name,
        username: username,
        created_at: Date.now(),
        created_at_ts: Timestamp.fromDate(currentDate),
        updated_at: Date.now(),
        updated_at_ts: Timestamp.fromDate(currentDate),
        referral_source: referralSource,
        created_from: "web",
      };
      delete finalProfile.dogs[0].images;
      console.log("Submitting profile: ", finalProfile);

      const userRef = doc(firestore, "users", currentUser.uid);
      await setDoc(userRef, finalProfile);
      Mixpanel.track("profile_created", {
        referral_source: referralSource,
        referral_code: referralCode,
        gclid: gclid,
        fbclid: fbclid,
        utm_source: utmSource,
        utm_medium: utmMedium,
        utm_campaign: utmCampaign,
        referral_reason: finalProfile.referral_reason,
      });
      window?.fbq("track", "CompleteRegistration", {
        value: "5",
        currency: "USD",
      });
      window.gtag_report_conversion_complete_profile &&
        window.gtag_report_conversion_complete_profile();
      navigate("/");
    } catch (err) {
      setShowErrorModal(true);
      Sentry.captureMessage("Error when submitting onboarding form");
      Sentry.captureException(err);
      console.log(err);
    }
  };

  if (formIndex < 0 || formIndex >= forms.length) {
    throw new Error("OnboardingPage: Invalid form index");
  }

  return (
    <div
      className="d-flex justify-content-center"
      style={{ backgroundColor: "white", minHeight: "100%" }}
    >
      <Col xs={12} md={7} lg={5}>
        {forms[formIndex]}
      </Col>
      <Modal
        show={showErrorModal}
        onHide={() => setShowErrorModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Unexpected error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Woof, I'm sorry this happened. Please try again!
        </Modal.Body>
        <Modal.Footer>
          <WCButton
            variant="outline-dark"
            onClick={() => setShowErrorModal(false)}
          >
            Close
          </WCButton>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

async function isUsernameValid(username) {
  if (!username || username.length === 0) {
    return false;
  }

  const firestore = getFirestore();
  const q = query(
    collection(firestore, "users"),
    where("username", "==", username),
  );
  const querySnapshot = await getDocs(q);
  let isUsernameTaken = false;
  if (querySnapshot) {
    querySnapshot.forEach((doc) => {
      if (doc.exists()) {
        isUsernameTaken = true;
      }
    });
  }

  return !isUsernameTaken;
}

async function generateUsername(name) {
  if (!name) {
    throw new Error("generateUsername: name is required");
  }

  // Try 5 times to generate a valid username
  for (let i = 0; i < 5; i++) {
    const username = name
      .toLowerCase()
      .replace(/[^a-z0-9]/gi, "")
      .concat(Math.round(Math.random() * 1000000));
    if (await isUsernameValid(username)) {
      return username;
    }
  }

  throw new Error("generateUsername: Unable to generate a valid username");
}

function getReferralSource(referralCode, gclid, utmSource) {
  if (referralCode) return referralCode;
  if (gclid) return "google_ads";
  if (utmSource === "fb_ads") return "fb_ads";
  return "unknown";
}
