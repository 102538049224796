import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import CloseButton from "react-bootstrap/CloseButton";
import ImageUploadForm from "../ImageUploadForm";
import ProfilePic from "../common/ProfilePic";
import { BsFillTrashFill } from "react-icons/bs";
import { DEFAULT_DOG_PROFILE_PIC_URL } from "../../libs/constants";
import Select from "react-select";
import { getDogBreedLabelFromValue, getDogBreeds } from "../../libs/dogBreeds";
import WCImage from "components/atom/WCImage";
import { getProfilePictureUrl } from "libs/images";

export default function DogProfile(props) {
  const [dogBreeds, setDogBreeds] = useState([]);
  useEffect(() => {
    async function fetchDogBreeds() {
      const data = await getDogBreeds();
      setDogBreeds(data);
    }
    fetchDogBreeds();
  }, []);

  if (!props.profile || !props.onProfileChange) {
    return null;
  }

  const title = props.profile.name
    ? "About " + props.profile.name
    : "About Your Dog";

  const onInfoChange = (newInfo) => {
    const newProfile = { ...props.profile, ...newInfo };
    console.log(newProfile);
    props.onProfileChange(newProfile);
  };

  const onImageUrlCreated = (imageUrl) => {
    let imageUrls = [];
    if (props.profile.image_urls && props.profile.image_urls.length > 0) {
      imageUrls = props.profile.image_urls;
    }
    imageUrls = [...imageUrls, imageUrl];
    console.log(imageUrls);
    onInfoChange({ image_urls: imageUrls });
  };

  const removeImage = (index) => {
    const imageUrls = [...props.profile.image_urls];
    if (index >= imageUrls.length || index < 0) {
      return;
    }
    imageUrls.splice(index, 1);
    onInfoChange({ image_urls: imageUrls });
  };

  const breed = {
    value: props.profile.breed,
    label: getDogBreedLabelFromValue(props.profile.breed),
  };

  const breedNotListed = props.profile?.breed === "other";

  return (
    <div className="card">
      <div className="card-body">
        <h3 className="">{title}</h3>
        {props.canRemoveProfile && (
          <Button
            variant="outline-secondary"
            onClick={(e) => {
              e.preventDefault();
              props.removeProfile();
            }}
          >
            <BsFillTrashFill />
          </Button>
        )}
        <hr />
        <Row className="my-3">
          <Col md="3">
            <ProfilePic
              url={getProfilePictureUrl(props.profile.profile_pic_url)}
              canChange={true}
              onChange={(url) => onInfoChange({ profile_pic_url: url })}
              size="100"
            />
          </Col>
          <Col md="9">
            <Row>
              <h4 className="p-2">Photos</h4>
              {props.profile.image_urls &&
                props.profile.image_urls.map((imageUrl, index) => (
                  <Col md="6">
                    <div style={{ position: "relative" }}>
                      <WCImage
                        src={imageUrl}
                        className="img-thumbnail p-1"
                        width={500}
                        height={500}
                      />
                      <div
                        className="bg-white"
                        style={{
                          position: "absolute",
                          right: 0,
                          top: 0,
                          borderRadius: "10%",
                        }}
                      >
                        <CloseButton onClick={() => removeImage(index)} />
                      </div>
                    </div>
                  </Col>
                ))}
              <Col
                md="6"
                className="text-center"
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                <ImageUploadForm
                  onImageUrlCreated={onImageUrlCreated}
                  text="Upload photo"
                />
              </Col>
            </Row>
            <Row>
              <h4 className="p-2">Basic Info</h4>
              <Col md="12">
                <div className="mb-3 text-start">
                  <label htmlFor="" className="form-label">
                    Dog's Name
                  </label>
                  <input
                    type="name"
                    className="form-control"
                    id="name-input"
                    aria-describedby="nameHelp"
                    value={props.profile.name}
                    onChange={(e) => {
                      onInfoChange({ name: e.target.value });
                    }}
                    placeholder="Name"
                    required
                  />
                </div>
                <div className="mb-3 text-start">
                  <label htmlFor="" className="form-label">
                    Breed
                  </label>
                  <Select
                    value={breed}
                    options={dogBreeds}
                    onChange={(selectedBreed) => {
                      onInfoChange({
                        breed: selectedBreed.value.toLowerCase(),
                        breed_custom: null,
                      });
                    }}
                  />
                  {breedNotListed && (
                    <input
                      type="custom-breed"
                      className="mt-2 form-control"
                      id="custom-breed"
                      aria-describedby="breedHelp"
                      value={props.profile.breed_custom}
                      onChange={(e) => {
                        onInfoChange({ breed_custom: e.target.value });
                      }}
                      placeholder="What is the breed?"
                      required
                    />
                  )}
                  <div className="form-text">
                    Select "Other" if your dog's breed is not listed. Do not
                    include size specification like "mini", "toy", etc.
                  </div>
                </div>
                <div className="mb-3 text-start">
                  <label htmlFor="" className="form-label">
                    Shedding Type
                  </label>
                  <br />
                  <ButtonGroup required>
                    <ToggleButton
                      type="radio"
                      variant="outline-secondary"
                      id={"tbg-btn-shed-1-".concat(props.profile.name)}
                      value="none"
                      checked={props.profile.shedding_type === "none"}
                      onChange={(e) =>
                        onInfoChange({ shedding_type: e.target.value })
                      }
                    >
                      None
                    </ToggleButton>
                    <ToggleButton
                      type="radio"
                      variant="outline-secondary"
                      id={"tbg-btn-shed-2-".concat(props.profile.name)}
                      value="minimal"
                      checked={props.profile.shedding_type === "minimal"}
                      onChange={(e) =>
                        onInfoChange({ shedding_type: e.target.value })
                      }
                    >
                      Minimal
                    </ToggleButton>
                    <ToggleButton
                      type="radio"
                      variant="outline-secondary"
                      id={"tbg-btn-shed-3-".concat(props.profile.name)}
                      value="medium"
                      checked={props.profile.shedding_type === "medium"}
                      onChange={(e) =>
                        onInfoChange({ shedding_type: e.target.value })
                      }
                    >
                      Medium
                    </ToggleButton>
                    <ToggleButton
                      type="radio"
                      variant="outline-secondary"
                      id={"tbg-btn-shed-4-".concat(props.profile.name)}
                      value="heavy"
                      checked={props.profile.shedding_type === "heavy"}
                      onChange={(e) =>
                        onInfoChange({ shedding_type: e.target.value })
                      }
                    >
                      Heavy
                    </ToggleButton>
                  </ButtonGroup>
                </div>
                <div className="mb-3 text-start">
                  <label htmlFor="" className="form-label">
                    Sex
                  </label>
                  <br />
                  <ButtonGroup required>
                    <ToggleButton
                      type="radio"
                      variant="outline-secondary"
                      id={"tbg-btn-sex-1-".concat(props.profile.name)}
                      value="male"
                      checked={props.profile.gender === "male"}
                      onChange={(e) => onInfoChange({ gender: e.target.value })}
                    >
                      Male
                    </ToggleButton>
                    <ToggleButton
                      type="radio"
                      variant="outline-secondary"
                      id={"tbg-btn-sex-2-".concat(props.profile.name)}
                      value="female"
                      checked={props.profile.gender === "female"}
                      onChange={(e) => onInfoChange({ gender: e.target.value })}
                    >
                      Female
                    </ToggleButton>
                  </ButtonGroup>
                </div>

                <div className="mb-3 text-start">
                  <label htmlFor="" className="form-label">
                    Weight
                  </label>
                  <select
                    className="form-select"
                    aria-label="Floating label select example"
                    value={props.profile.weight}
                    onChange={(e) => {
                      onInfoChange({ weight: e.target.value });
                    }}
                    required
                  >
                    <option value=""> -- Select an option -- </option>
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                    <option value="30">30</option>
                    <option value="35">35</option>
                    <option value="40">40</option>
                    <option value="45">45</option>
                    <option value="50">50</option>
                    <option value="55">55</option>
                    <option value="60">60</option>
                    <option value="70">70</option>
                    <option value="80">80</option>
                    <option value="90">90</option>
                    <option value="100">100</option>
                    <option value="110">110</option>
                    <option value="120">120</option>
                    <option value="130">130</option>
                    <option value="140">140</option>
                    <option value="150">150</option>
                    <option value="160">160</option>
                    <option value="170">170</option>
                    <option value="180">180</option>
                    <option value="190">190</option>
                    <option value="200">200</option>
                    <option value="210">210</option>
                    <option value="220">220</option>
                    <option value="230">230</option>
                    <option value="240">240</option>
                    <option value="250">250</option>
                  </select>
                </div>
                <div className="mb-3 text-start">
                  <label htmlFor="" className="form-label">
                    Birthdate (to the best of your knowledge)
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="birthdate-input"
                    aria-describedby="birthdateHelp"
                    placeholder="Birthdate"
                    value={props.profile.birthdate}
                    onChange={(e) => {
                      onInfoChange({ birthdate: e.target.value });
                    }}
                  />
                </div>
                <div className="mb-3 text-start">
                  <label htmlFor="" className="form-label">
                    Has your dog been spayed or neutered?
                  </label>
                  <br />
                  <ButtonGroup required>
                    <ToggleButton
                      type="radio"
                      variant="outline-secondary"
                      id={"tbg-btn-spay-1-".concat(props.profile.name)}
                      value="yes"
                      checked={props.profile.neutered === "yes"}
                      onChange={(e) =>
                        onInfoChange({ neutered: e.target.value })
                      }
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      type="radio"
                      variant="outline-secondary"
                      id={"tbg-btn-spay-2-".concat(props.profile.name)}
                      value="no"
                      checked={props.profile.neutered === "no"}
                      onChange={(e) =>
                        onInfoChange({ neutered: e.target.value })
                      }
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      type="radio"
                      variant="outline-secondary"
                      id={"tbg-btn-spay-3-".concat(props.profile.name)}
                      value="unsure"
                      checked={props.profile.neutered === "unsure"}
                      onChange={(e) =>
                        onInfoChange({ neutered: e.target.value })
                      }
                    >
                      Unsure
                    </ToggleButton>
                  </ButtonGroup>
                </div>
                <div className="mb-3 text-start">
                  <label htmlFor="" className="form-label">
                    Is your dog microchipped?
                  </label>
                  <br />
                  <ButtonGroup required>
                    <ToggleButton
                      type="radio"
                      variant="outline-secondary"
                      id={"tbg-btn-microchipped-1-".concat(props.profile.name)}
                      value="yes"
                      checked={props.profile.microchipped === "yes"}
                      onChange={(e) =>
                        onInfoChange({ microchipped: e.target.value })
                      }
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      type="radio"
                      variant="outline-secondary"
                      id={"tbg-btn-microchipped-2-".concat(props.profile.name)}
                      value="no"
                      checked={props.profile.microchipped === "no"}
                      onChange={(e) =>
                        onInfoChange({ microchipped: e.target.value })
                      }
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      type="radio"
                      variant="outline-secondary"
                      id={"tbg-btn-microchipped-3-".concat(props.profile.name)}
                      value="unsure"
                      checked={props.profile.microchipped === "unsure"}
                      onChange={(e) =>
                        onInfoChange({ microchipped: e.target.value })
                      }
                    >
                      Unsure
                    </ToggleButton>
                  </ButtonGroup>
                </div>
                <div className="mb-3 text-start">
                  <label htmlFor="" className="form-label">
                    Is your dog house-trained? i.e. do they poop & pee outside
                    the house?
                  </label>
                  <br />
                  <ButtonGroup required>
                    <ToggleButton
                      type="radio"
                      variant="outline-secondary"
                      id={"tbg-btn-house-trained-1-".concat(props.profile.name)}
                      value="yes"
                      checked={props.profile.house_trained === "yes"}
                      onChange={(e) =>
                        onInfoChange({ house_trained: e.target.value })
                      }
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      type="radio"
                      variant="outline-secondary"
                      id={"tbg-btn-house-trained-2-".concat(props.profile.name)}
                      value="no"
                      checked={props.profile.house_trained === "no"}
                      onChange={(e) =>
                        onInfoChange({ house_trained: e.target.value })
                      }
                    >
                      No
                    </ToggleButton>
                  </ButtonGroup>
                </div>
                <div className="mb-3 text-start">
                  <label htmlFor="" className="form-label">
                    Is your dog crate-trained?
                  </label>
                  <br />
                  <ButtonGroup required>
                    <ToggleButton
                      type="radio"
                      variant="outline-secondary"
                      id={"tbg-btn-crate-trained-1-".concat(props.profile.name)}
                      value="yes"
                      checked={props.profile.crate_trained === "yes"}
                      onChange={(e) =>
                        onInfoChange({ crate_trained: e.target.value })
                      }
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      type="radio"
                      variant="outline-secondary"
                      id={"tbg-btn-crate-trained-2-".concat(props.profile.name)}
                      value="no"
                      checked={props.profile.crate_trained === "no"}
                      onChange={(e) =>
                        onInfoChange({ crate_trained: e.target.value })
                      }
                    >
                      No
                    </ToggleButton>
                  </ButtonGroup>
                </div>
                <div className="mb-3 text-start">
                  <label htmlFor="" className="form-label">
                    Has your dog stayed with others before? (friend's
                    place/Rover/Boarding/Daycare/etc)
                  </label>
                  <br />
                  <ButtonGroup required>
                    <ToggleButton
                      type="radio"
                      variant="outline-secondary"
                      id={"tbg-btn-has-stay-away-1-".concat(props.profile.name)}
                      value="yes"
                      checked={props.profile.has_stay_away === "yes"}
                      onChange={(e) =>
                        onInfoChange({ has_stay_away: e.target.value })
                      }
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      type="radio"
                      variant="outline-secondary"
                      id={"tbg-btn-has-stay-away-2-".concat(props.profile.name)}
                      value="no"
                      checked={props.profile.has_stay_away === "no"}
                      onChange={(e) =>
                        onInfoChange({ has_stay_away: e.target.value })
                      }
                    >
                      No
                    </ToggleButton>
                  </ButtonGroup>
                </div>
                <div className="mb-3 text-start">
                  <label htmlFor="" className="form-label">
                    Is your dog friendly with cats?
                  </label>
                  <br />
                  <ButtonGroup required>
                    <ToggleButton
                      type="radio"
                      variant="outline-secondary"
                      id={"tbg-btn-cat-friendly-1-".concat(props.profile.name)}
                      value="yes"
                      checked={props.profile.cat_friendly === "yes"}
                      onChange={(e) =>
                        onInfoChange({ cat_friendly: e.target.value })
                      }
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      type="radio"
                      variant="outline-secondary"
                      id={"tbg-btn-cat-friendly-2-".concat(props.profile.name)}
                      value="no"
                      checked={props.profile.cat_friendly === "no"}
                      onChange={(e) =>
                        onInfoChange({ cat_friendly: e.target.value })
                      }
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      type="radio"
                      variant="outline-secondary"
                      id={"tbg-btn-cat-friendly-3-".concat(props.profile.name)}
                      value="unsure"
                      checked={props.profile.cat_friendly === "unsure"}
                      onChange={(e) =>
                        onInfoChange({ cat_friendly: e.target.value })
                      }
                    >
                      Unsure
                    </ToggleButton>
                  </ButtonGroup>
                </div>
                <div className="mb-3 text-start">
                  <label htmlFor="" className="form-label">
                    Is your dog friendly with children?
                  </label>
                  <br />
                  <ButtonGroup required>
                    <ToggleButton
                      type="radio"
                      variant="outline-secondary"
                      id={"tbg-btn-child-friendly-1-".concat(
                        props.profile.name,
                      )}
                      value="yes"
                      checked={props.profile.children_friendly === "yes"}
                      onChange={(e) =>
                        onInfoChange({ children_friendly: e.target.value })
                      }
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      type="radio"
                      variant="outline-secondary"
                      id={"tbg-btn-child-friendly-2-".concat(
                        props.profile.name,
                      )}
                      value="no"
                      checked={props.profile.children_friendly === "no"}
                      onChange={(e) =>
                        onInfoChange({ children_friendly: e.target.value })
                      }
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      type="radio"
                      variant="outline-secondary"
                      id={"tbg-btn-child-friendly-3-".concat(
                        props.profile.name,
                      )}
                      value="unsure"
                      checked={props.profile.children_friendly === "unsure"}
                      onChange={(e) =>
                        onInfoChange({ children_friendly: e.target.value })
                      }
                    >
                      Unsure
                    </ToggleButton>
                  </ButtonGroup>
                </div>
                <div className="mb-3 text-start">
                  <label htmlFor="" className="form-label">
                    Energy Level
                  </label>
                  <br />
                  <ButtonGroup required>
                    <ToggleButton
                      type="radio"
                      variant="outline-secondary"
                      id={"tbg-btn-energy-1-".concat(props.profile.name)}
                      value="low"
                      checked={props.profile.energy_level === "low"}
                      onChange={(e) =>
                        onInfoChange({ energy_level: e.target.value })
                      }
                    >
                      Chill & Relaxed
                    </ToggleButton>
                    <ToggleButton
                      type="radio"
                      variant="outline-secondary"
                      id={"tbg-btn-energy-2-".concat(props.profile.name)}
                      value="medium"
                      checked={props.profile.energy_level === "medium"}
                      onChange={(e) =>
                        onInfoChange({ energy_level: e.target.value })
                      }
                    >
                      Medium
                    </ToggleButton>
                    <ToggleButton
                      type="radio"
                      variant="outline-secondary"
                      id={"tbg-btn-energy-3-".concat(props.profile.name)}
                      value="high"
                      checked={props.profile.energy_level === "high"}
                      onChange={(e) =>
                        onInfoChange({ energy_level: e.target.value })
                      }
                    >
                      High
                    </ToggleButton>
                  </ButtonGroup>
                </div>
                <div className="mb-3 text-start">
                  <label htmlFor="" className="form-label">
                    How often do they need to be taken out for potty & pee?
                    Taken out every:
                  </label>
                  <br />
                  <ButtonGroup required>
                    <ToggleButton
                      type="radio"
                      variant="outline-secondary"
                      id={"tbg-btn-potty-schedule-1-".concat(
                        props.profile.name,
                      )}
                      value="every_1_hour"
                      checked={props.profile.potty_schedule === "every_1_hour"}
                      onChange={(e) =>
                        onInfoChange({ potty_schedule: e.target.value })
                      }
                    >
                      1 hour
                    </ToggleButton>
                    <ToggleButton
                      type="radio"
                      variant="outline-secondary"
                      id={"tbg-btn-potty-schedule-2-".concat(
                        props.profile.name,
                      )}
                      value="every_2_hour"
                      checked={props.profile.potty_schedule === "every_2_hour"}
                      onChange={(e) =>
                        onInfoChange({ potty_schedule: e.target.value })
                      }
                    >
                      2 hour
                    </ToggleButton>
                    <ToggleButton
                      type="radio"
                      variant="outline-secondary"
                      id={"tbg-btn-potty-schedule-3-".concat(
                        props.profile.name,
                      )}
                      value="every_4_hour"
                      checked={props.profile.potty_schedule === "every_4_hour"}
                      onChange={(e) =>
                        onInfoChange({ potty_schedule: e.target.value })
                      }
                    >
                      4 hour
                    </ToggleButton>
                    <ToggleButton
                      type="radio"
                      variant="outline-secondary"
                      id={"tbg-btn-potty-schedule-4-".concat(
                        props.profile.name,
                      )}
                      value="every_6_hour"
                      checked={props.profile.potty_schedule === "every_6_hour"}
                      onChange={(e) =>
                        onInfoChange({ potty_schedule: e.target.value })
                      }
                    >
                      6 hour
                    </ToggleButton>
                    <ToggleButton
                      type="radio"
                      variant="outline-secondary"
                      id={"tbg-btn-potty-schedule-5-".concat(
                        props.profile.name,
                      )}
                      value="every_8_hour"
                      checked={props.profile.potty_schedule === "every_8_hour"}
                      onChange={(e) =>
                        onInfoChange({ potty_schedule: e.target.value })
                      }
                    >
                      8+ hour
                    </ToggleButton>
                  </ButtonGroup>
                </div>
                <div className="mb-3 text-start">
                  <label htmlFor="" className="form-label">
                    What's your dog's feeding schedule?
                  </label>
                  <br />
                  <ButtonGroup required>
                    <ToggleButton
                      type="checkbox"
                      checked={props.profile.feeding_schedule_morning}
                      variant="outline-secondary"
                      id={"tbg-btn-feeding-schedule-morning-".concat(
                        props.profile.name,
                      )}
                      value=""
                      onChange={(e) =>
                        onInfoChange({
                          feeding_schedule_morning: e.target.checked,
                        })
                      }
                    >
                      Morning
                    </ToggleButton>
                    <ToggleButton
                      type="checkbox"
                      checked={props.profile.feeding_schedule_afternoon}
                      variant="outline-secondary"
                      id={"tbg-btn-feeding-schedule-afternoon-".concat(
                        props.profile.name,
                      )}
                      value=""
                      onChange={(e) =>
                        onInfoChange({
                          feeding_schedule_afternoon: e.target.checked,
                        })
                      }
                    >
                      Afternoon
                    </ToggleButton>
                    <ToggleButton
                      type="checkbox"
                      checked={props.profile.feeding_schedule_evening}
                      variant="outline-secondary"
                      id={"tbg-btn-feeding-schedule-evening-".concat(
                        props.profile.name,
                      )}
                      value=""
                      onChange={(e) =>
                        onInfoChange({
                          feeding_schedule_evening: e.target.checked,
                        })
                      }
                    >
                      Evening
                    </ToggleButton>
                  </ButtonGroup>
                  <br />
                  <br />
                  <input
                    type="text"
                    className="form-control"
                    id="feeding-schedule-custom-input"
                    value={props.profile.feeding_schedule_custom}
                    onChange={(e) =>
                      onInfoChange({ feeding_schedule_custom: e.target.value })
                    }
                    placeholder="Additional feeding instructions"
                  />
                </div>
                <div className="mb-3 text-start">
                  <label htmlFor="" className="form-label">
                    If your dog needs any medication, be fed a special diet or
                    has allergies, please describe them.
                  </label>
                  <textarea
                    className="form-control"
                    id="description-input"
                    aria-describedby="descriptionHelp"
                    rows="5"
                    value={props.profile.medication_or_allergies}
                    onChange={(e) =>
                      onInfoChange({ medication_or_allergies: e.target.value })
                    }
                    placeholder="Most hosts are pretty accomodating so please describe this in detail!"
                  />
                </div>
                <div className="mb-3 text-start">
                  <label htmlFor="" className="form-label">
                    How long can your dog be left alone?
                  </label>
                  <br />
                  <ButtonGroup required>
                    <ToggleButton
                      type="radio"
                      variant="outline-secondary"
                      id={"tbg-btn-leftalone-".concat(props.profile.name)}
                      value="1_hour"
                      checked={props.profile.left_alone === "1_hour"}
                      onChange={(e) =>
                        onInfoChange({ left_alone: e.target.value })
                      }
                    >
                      Less than 1 hour
                    </ToggleButton>
                    <ToggleButton
                      type="radio"
                      variant="outline-secondary"
                      id={"tbg-btn-leftalone-2-".concat(props.profile.name)}
                      value="1_4_hour"
                      checked={props.profile.left_alone === "1_4_hour"}
                      onChange={(e) =>
                        onInfoChange({ left_alone: e.target.value })
                      }
                    >
                      1 - 4 hours
                    </ToggleButton>
                    <ToggleButton
                      type="radio"
                      variant="outline-secondary"
                      id={"tbg-btn-leftalone-3-".concat(props.profile.name)}
                      value="4_8_hour"
                      checked={props.profile.left_alone === "4_8_hour"}
                      onChange={(e) =>
                        onInfoChange({ left_alone: e.target.value })
                      }
                    >
                      4 - 8 hours
                    </ToggleButton>
                    <ToggleButton
                      type="radio"
                      variant="outline-secondary"
                      id={"tbg-btn-leftalone-4-".concat(props.profile.name)}
                      value="8+_hour"
                      checked={props.profile.left_alone === "8+_hour"}
                      onChange={(e) =>
                        onInfoChange({ left_alone: e.target.value })
                      }
                    >
                      8+ hours
                    </ToggleButton>
                  </ButtonGroup>
                </div>
                <div className="mb-3 text-start">
                  <label htmlFor="" className="form-label">
                    Can your dog travel locally with the host?
                  </label>
                  <br />
                  <ButtonGroup required vertical="true">
                    <ToggleButton
                      type="checkbox"
                      variant="outline-secondary"
                      id={"tgb-btn-travel_ok_with_car_rides".concat(
                        props.profile.name,
                      )}
                      value=""
                      checked={props.profile.travel_ok_with_car_rides}
                      onChange={(e) =>
                        onInfoChange({
                          travel_ok_with_car_rides: e.target.checked,
                        })
                      }
                    >
                      OK with car rides
                    </ToggleButton>
                    <ToggleButton
                      type="checkbox"
                      variant="outline-secondary"
                      id={"tgb-btn-travel_ok_in_small_group".concat(
                        props.profile.name,
                      )}
                      value=""
                      checked={props.profile.travel_ok_in_small_group}
                      onChange={(e) =>
                        onInfoChange({
                          travel_ok_in_small_group: e.target.checked,
                        })
                      }
                    >
                      OK in small groups (max 4 people)
                    </ToggleButton>
                    <ToggleButton
                      type="checkbox"
                      variant="outline-secondary"
                      id={"tgb-travel_ok_in_large_group".concat(
                        props.profile.name,
                      )}
                      value=""
                      checked={props.profile.travel_ok_in_large_group}
                      onChange={(e) =>
                        onInfoChange({
                          travel_ok_in_large_group: e.target.checked,
                        })
                      }
                    >
                      OK in large groups (4+ people)
                    </ToggleButton>
                  </ButtonGroup>
                </div>

                <div className="my-3 text-start">
                  <label htmlFor="" className="form-label">
                    Instagram Account
                  </label>
                  <input
                    className="form-control"
                    id="description-input"
                    aria-describedby="descriptionHelp"
                    rows="1"
                    value={props.profile.instagram_link}
                    onChange={(e) =>
                      onInfoChange({ instagram_link: e.target.value })
                    }
                    placeholder="What's your dog's instagram account?"
                  />
                </div>

                <div className="mb-3 text-start">
                  <label htmlFor="" className="form-label">
                    Anything else we need to know about your dog?
                  </label>
                  <textarea
                    className="form-control"
                    id="description-input"
                    aria-describedby="descriptionHelp"
                    rows="5"
                    value={props.profile.anything_else}
                    onChange={(e) =>
                      onInfoChange({ anything_else: e.target.value })
                    }
                    placeholder="Be as transparent as you can to prepare your host :)"
                  />
                </div>
                <h4 className="p-2">Emergency Info</h4>
                <div className="mb-3 text-start">
                  <label htmlFor="" className="form-label">
                    Veterinary Information
                  </label>
                  <textarea
                    className="form-control"
                    id="description-input"
                    aria-describedby="descriptionHelp"
                    rows="5"
                    value={props.profile.vet_info}
                    onChange={(e) => onInfoChange({ vet_info: e.target.value })}
                    placeholder="Add your vet's name, address and phone number. Include any other detail as needed in case of an emergency for your pet"
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
}
