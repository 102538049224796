import React from "react";
import { Modal } from "react-bootstrap";

export default function WCModal(props) {
  const {
    header,
    headerText,
    body,
    footer,
    children,
    closable,
    onHide,
    size = "lg",
    showHeader = true,
    showFooter = true,
    ...rest
  } = props;

  return (
    <Modal
      {...rest}
      show={true}
      size={size}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {children ? (
        children
      ) : (
        <>
          {showHeader ? (
            <Modal.Header closeButton={closable}>
              {header ? (
                header
              ) : (
                <Modal.Title
                  id="contained-modal-title-vcenter"
                  style={{ display: "inline-flex", alignItems: "center" }}
                >
                  {headerText}
                </Modal.Title>
              )}
            </Modal.Header>
          ) : null}
          <Modal.Body>{body}</Modal.Body>
          {showFooter ? <Modal.Footer>{footer}</Modal.Footer> : null}
        </>
      )}
    </Modal>
  );
}
